<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "HList",
    props: {
        value: {

        },
        items: {
            type: Array,
            default: () => [],
        },
    },
    data: function () {
        return {
            headers: [
                {text: 'Name', value: 'name', width: 0, sortable: false},
                {text: 'Adresse', value: 'adresse'},
                {text: '', value: 'actions', width: 0, sortable: false},
            ],

            show: false,
            search: null,
        };
    },
    methods: {
        select(item) {
            this.show = false;
            this.$emit('select', item);
        },
    },
})
</script>

<template>
    <div>
        <v-navigation-drawer
            app
            temporary
            width="400"
            v-model="show"
        >
            <template v-slot:prepend>
                <v-toolbar
                    flat
                >
                    <v-text-field
                        dense
                        outlined
                        hide-details
                        clearable
                        append-icon="mdi-magnify"
                        label="Suchen"
                        v-model="search"
                    />
                </v-toolbar>
            </template>

            <v-data-table
                :headers="headers"
                :items="items"
                disable-sort
                disable-pagination
                :hide-default-header="$vuetify.breakpoint.lgAndUp"
                hide-default-footer
                no-results-text="Keine Ergebnisse"
                :search="search"
            >
                <template v-slot:item.actions="{ item }">
                    <v-btn
                        icon
                        @click="select(item)"
                    >
                        <v-icon>
                            mdi-select-marker
                        </v-icon>
                    </v-btn>
                </template>
            </v-data-table>

            <template v-slot:append>
                <v-toolbar
                    dark
                    dense
                    flat
                    v-show="!$vuetify.breakpoint.lgAndUp"
                >
                    <v-layout
                        d-flex
                        justify-center
                    >
                        <v-btn
                            icon
                            @click="show = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-layout>
                </v-toolbar>
            </template>
        </v-navigation-drawer>

        <v-btn
            fab
            fixed
            bottom
            left
            color="primary"
            :elevation="2"
            @click="show = true"
        >
            <v-icon>mdi-menu</v-icon>
        </v-btn>
    </div>
</template>

<style scoped>

</style>