var utmObj = require('utm-latlng');
var utm = new utmObj();

export const HYDRANTEN = [
    {
        "Hydranten Nr": "",
        "UTM Koord. Rechts": "571132.374",
        "UTM Koord. Hoch": "5931681.952",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "46"
    },
    {
        "Hydranten Nr": "",
        "UTM Koord. Rechts": "570512.592",
        "UTM Koord. Hoch": "5929212.73",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Rothenburgsort",
        "Straßenname": "Moorfleeter Hauptdeich",
        "HsNr.": "81"
    },
    {
        "Hydranten Nr": "",
        "UTM Koord. Rechts": "571143.8",
        "UTM Koord. Hoch": "5934785.665",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "156"
    },
    {
        "Hydranten Nr": "H5917",
        "UTM Koord. Rechts": "572660.578",
        "UTM Koord. Hoch": "5934745.141",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "KLGV.134 Hamm und Horn",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H5919",
        "UTM Koord. Rechts": "572572.699",
        "UTM Koord. Hoch": "5935009.26",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Stoltenstraße",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H914",
        "UTM Koord. Rechts": "572837.992",
        "UTM Koord. Hoch": "5935004.495",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Heinrich-Kaufmann-Ring",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H17767",
        "UTM Koord. Rechts": "570836.148",
        "UTM Koord. Hoch": "5934580.195",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Weg",
        "HsNr.": "162"
    },
    {
        "Hydranten Nr": "H16846",
        "UTM Koord. Rechts": "572438.89",
        "UTM Koord. Hoch": "5934597.134",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Speckenreye",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H17237",
        "UTM Koord. Rechts": "572566.413",
        "UTM Koord. Hoch": "5934668.228",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "66"
    },
    {
        "Hydranten Nr": "H11023",
        "UTM Koord. Rechts": "571277.448",
        "UTM Koord. Hoch": "5929498.147",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "181"
    },
    {
        "Hydranten Nr": "H11003",
        "UTM Koord. Rechts": "571417.047",
        "UTM Koord. Hoch": "5929190.054",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "210"
    },
    {
        "Hydranten Nr": "H14211",
        "UTM Koord. Rechts": "574601.674",
        "UTM Koord. Hoch": "5932323.639",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Borchardsheide",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H14216",
        "UTM Koord. Rechts": "574586.622",
        "UTM Koord. Hoch": "5932397.817",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Borchardsheide",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H13018",
        "UTM Koord. Rechts": "572791.734",
        "UTM Koord. Hoch": "5930262.07",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Alter Landweg",
        "HsNr.": "48"
    },
    {
        "Hydranten Nr": "H10975",
        "UTM Koord. Rechts": "571682.069",
        "UTM Koord. Hoch": "5928904.753",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Brennerhof",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H10971",
        "UTM Koord. Rechts": "571668.532",
        "UTM Koord. Hoch": "5928677.388",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Brennerhof",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H17095",
        "UTM Koord. Rechts": "571982.133",
        "UTM Koord. Hoch": "5934238.355",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H11815",
        "UTM Koord. Rechts": "575467.276",
        "UTM Koord. Hoch": "5932414.779",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Erhard-Dressel-Bogen",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H10955",
        "UTM Koord. Rechts": "571792.918",
        "UTM Koord. Hoch": "5928413.863",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "272"
    },
    {
        "Hydranten Nr": "H13061",
        "UTM Koord. Rechts": "571392.311",
        "UTM Koord. Hoch": "5931150.564",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "32"
    },
    {
        "Hydranten Nr": "H13077",
        "UTM Koord. Rechts": "571817.17",
        "UTM Koord. Hoch": "5931170.769",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H12284",
        "UTM Koord. Rechts": "570906.678",
        "UTM Koord. Hoch": "5931068.456",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H15749",
        "UTM Koord. Rechts": "573959.879",
        "UTM Koord. Hoch": "5935477.469",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sultanstraße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H13079",
        "UTM Koord. Rechts": "571769.685",
        "UTM Koord. Hoch": "5931191.863",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H13109",
        "UTM Koord. Rechts": "571794.884",
        "UTM Koord. Hoch": "5931481.672",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H14208",
        "UTM Koord. Rechts": "574589.409",
        "UTM Koord. Hoch": "5932193.46",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Brockhausweg",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H14232",
        "UTM Koord. Rechts": "574502.39",
        "UTM Koord. Hoch": "5932230.434",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kapellenstraße",
        "HsNr.": "73"
    },
    {
        "Hydranten Nr": "H13104",
        "UTM Koord. Rechts": "571679.028",
        "UTM Koord. Hoch": "5931474.565",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H14217",
        "UTM Koord. Rechts": "574569.012",
        "UTM Koord. Hoch": "5932486.989",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Druckerstraße",
        "HsNr.": "61"
    },
    {
        "Hydranten Nr": "H19563",
        "UTM Koord. Rechts": "571787.854",
        "UTM Koord. Hoch": "5932524.895",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Wöhlerstraße",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H13063",
        "UTM Koord. Rechts": "571316.03",
        "UTM Koord. Hoch": "5931229.211",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H11804",
        "UTM Koord. Rechts": "574930.807",
        "UTM Koord. Hoch": "5932697.602",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Amrumer Knick",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H12994",
        "UTM Koord. Rechts": "572676.79",
        "UTM Koord. Hoch": "5930305.304",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Alter Landweg",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H589",
        "UTM Koord. Rechts": "573089.676",
        "UTM Koord. Hoch": "5930030.321",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "KLGV.601 Billbrook Dorf",
        "HsNr.": "49"
    },
    {
        "Hydranten Nr": "H4025",
        "UTM Koord. Rechts": "577448.043",
        "UTM Koord. Hoch": "5932529.181",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Stormarnstraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H10989",
        "UTM Koord. Rechts": "571989.087",
        "UTM Koord. Hoch": "5929572.206",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Brennerhof",
        "HsNr.": "102"
    },
    {
        "Hydranten Nr": "H16609",
        "UTM Koord. Rechts": "573424.95",
        "UTM Koord. Hoch": "5934113.872",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinadlerweg",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H5836",
        "UTM Koord. Rechts": "572615.44",
        "UTM Koord. Hoch": "5934873.906",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermann-Heberlein-Ring",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H14967",
        "UTM Koord. Rechts": "571192.744",
        "UTM Koord. Hoch": "5932430.443",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H14243",
        "UTM Koord. Rechts": "574221.901",
        "UTM Koord. Hoch": "5932295.453",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Arndesstieg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H14246",
        "UTM Koord. Rechts": "574127.24",
        "UTM Koord. Hoch": "5932213.247",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Korverweg",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H13022",
        "UTM Koord. Rechts": "572795.513",
        "UTM Koord. Hoch": "5930262.62",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Alter Landweg",
        "HsNr.": "48"
    },
    {
        "Hydranten Nr": "H19500",
        "UTM Koord. Rechts": "572313.499",
        "UTM Koord. Hoch": "5932608.704",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "107"
    },
    {
        "Hydranten Nr": "H15679",
        "UTM Koord. Rechts": "573925.858",
        "UTM Koord. Hoch": "5935335.451",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schneewittchenweg",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H13008",
        "UTM Koord. Rechts": "572748.718",
        "UTM Koord. Hoch": "5930564.974",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H11033",
        "UTM Koord. Rechts": "571249.742",
        "UTM Koord. Hoch": "5929874.246",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "135"
    },
    {
        "Hydranten Nr": "H11787",
        "UTM Koord. Rechts": "575029.128",
        "UTM Koord. Hoch": "5932892.086",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "181"
    },
    {
        "Hydranten Nr": "H12443",
        "UTM Koord. Rechts": "572105.905",
        "UTM Koord. Hoch": "5930492.101",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "56"
    },
    {
        "Hydranten Nr": "H12434",
        "UTM Koord. Rechts": "572338.395",
        "UTM Koord. Hoch": "5930136.935",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H12441",
        "UTM Koord. Rechts": "572164.976",
        "UTM Koord. Hoch": "5930411.177",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "60"
    },
    {
        "Hydranten Nr": "H11778",
        "UTM Koord. Rechts": "575248.489",
        "UTM Koord. Hoch": "5932993.683",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "197"
    },
    {
        "Hydranten Nr": "H11779",
        "UTM Koord. Rechts": "575155.702",
        "UTM Koord. Hoch": "5932949.228",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "187"
    },
    {
        "Hydranten Nr": "H12999",
        "UTM Koord. Rechts": "572834.594",
        "UTM Koord. Hoch": "5930506.855",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Alter Landweg",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H13066",
        "UTM Koord. Rechts": "571238.877",
        "UTM Koord. Hoch": "5931292.502",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H10739",
        "UTM Koord. Rechts": "571314.15",
        "UTM Koord. Hoch": "5928491.273",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Hauptdeich",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H10738",
        "UTM Koord. Rechts": "571418.507",
        "UTM Koord. Hoch": "5928484.714",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Hauptdeich",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H13141",
        "UTM Koord. Rechts": "571032.619",
        "UTM Koord. Hoch": "5931471.539",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "54"
    },
    {
        "Hydranten Nr": "H16918",
        "UTM Koord. Rechts": "573123.389",
        "UTM Koord. Hoch": "5933588.274",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Ihlestraße",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H18435",
        "UTM Koord. Rechts": "572147.33",
        "UTM Koord. Hoch": "5932718.725",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "100"
    },
    {
        "Hydranten Nr": "H19499",
        "UTM Koord. Rechts": "572232.158",
        "UTM Koord. Hoch": "5932672.762",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "100"
    },
    {
        "Hydranten Nr": "H19496",
        "UTM Koord. Rechts": "572015.087",
        "UTM Koord. Hoch": "5932767.55",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "74"
    },
    {
        "Hydranten Nr": "H14201",
        "UTM Koord. Rechts": "574759.441",
        "UTM Koord. Hoch": "5932089.952",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kapellenstraße",
        "HsNr.": "101"
    },
    {
        "Hydranten Nr": "H12286",
        "UTM Koord. Rechts": "572902.772",
        "UTM Koord. Hoch": "5932937.863",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H14251",
        "UTM Koord. Rechts": "574164.562",
        "UTM Koord. Hoch": "5932460.684",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kapellenstraße",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H14281",
        "UTM Koord. Rechts": "574089.001",
        "UTM Koord. Hoch": "5932508.029",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kapellenstraße",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H14245",
        "UTM Koord. Rechts": "574286.553",
        "UTM Koord. Hoch": "5932334.988",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kapellenstraße",
        "HsNr.": "53"
    },
    {
        "Hydranten Nr": "H6466",
        "UTM Koord. Rechts": "573952.107",
        "UTM Koord. Hoch": "5935259.271",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rosenrotweg",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H11814",
        "UTM Koord. Rechts": "575555.024",
        "UTM Koord. Hoch": "5932454.05",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Glinder Au",
        "HsNr.": "65"
    },
    {
        "Hydranten Nr": "H10726",
        "UTM Koord. Rechts": "571480.02",
        "UTM Koord. Hoch": "5929066.455",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "223"
    },
    {
        "Hydranten Nr": "H16121",
        "UTM Koord. Rechts": "573392.262",
        "UTM Koord. Hoch": "5933328.811",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Gothaer Weg",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H13069",
        "UTM Koord. Rechts": "571117.741",
        "UTM Koord. Hoch": "5931375.554",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H13089",
        "UTM Koord. Rechts": "571596.231",
        "UTM Koord. Hoch": "5931377.246",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H11035",
        "UTM Koord. Rechts": "571267.796",
        "UTM Koord. Hoch": "5929748.94",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "149"
    },
    {
        "Hydranten Nr": "H18082",
        "UTM Koord. Rechts": "571648.606",
        "UTM Koord. Hoch": "5933912.122",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "207"
    },
    {
        "Hydranten Nr": "H18300",
        "UTM Koord. Rechts": "571764.714",
        "UTM Koord. Hoch": "5933373.795",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Heckenpfad",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H10932",
        "UTM Koord. Rechts": "572565.59",
        "UTM Koord. Hoch": "5927404.789",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Allermöhe",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "395"
    },
    {
        "Hydranten Nr": "H11031",
        "UTM Koord. Rechts": "571283.758",
        "UTM Koord. Hoch": "5929649.182",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "163"
    },
    {
        "Hydranten Nr": "H11341",
        "UTM Koord. Rechts": "571846.872",
        "UTM Koord. Hoch": "5933357.13",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Jürsweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H6427",
        "UTM Koord. Rechts": "573900.438",
        "UTM Koord. Hoch": "5935458.425",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Geißleinweg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H15754",
        "UTM Koord. Rechts": "574086.059",
        "UTM Koord. Hoch": "5935554.245",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Bärenhäuterweg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H18303",
        "UTM Koord. Rechts": "571782.246",
        "UTM Koord. Hoch": "5933211.207",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Rampe",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H18306",
        "UTM Koord. Rechts": "571785.873",
        "UTM Koord. Hoch": "5933161.688",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Rampe",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H18317",
        "UTM Koord. Rechts": "571748.386",
        "UTM Koord. Hoch": "5933094.744",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Eekholtesweg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H18301",
        "UTM Koord. Rechts": "571775.58",
        "UTM Koord. Hoch": "5933281.13",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Rampe",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H18315",
        "UTM Koord. Rechts": "571658.266",
        "UTM Koord. Hoch": "5933252.152",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Daniel-Bartels-Weg",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H18313",
        "UTM Koord. Rechts": "571670.46",
        "UTM Koord. Hoch": "5933125.891",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Daniel-Bartels-Weg",
        "HsNr.": "37"
    },
    {
        "Hydranten Nr": "H2912",
        "UTM Koord. Rechts": "574488.94",
        "UTM Koord. Hoch": "5930438.092",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Billwerder Billdeich",
        "HsNr.": "102"
    },
    {
        "Hydranten Nr": "H18382",
        "UTM Koord. Rechts": "572156.259",
        "UTM Koord. Hoch": "5933492.819",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "369"
    },
    {
        "Hydranten Nr": "H11342",
        "UTM Koord. Rechts": "571855.487",
        "UTM Koord. Hoch": "5933267.475",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Jürsweg",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H16615",
        "UTM Koord. Rechts": "573543.413",
        "UTM Koord. Hoch": "5934378.075",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Seeadlerstieg",
        "HsNr.": "38"
    },
    {
        "Hydranten Nr": "H15751",
        "UTM Koord. Rechts": "574030.498",
        "UTM Koord. Hoch": "5935395.281",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rotkäppchenweg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H10934",
        "UTM Koord. Rechts": "572556.366",
        "UTM Koord. Hoch": "5927530.817",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Allermöhe",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "385"
    },
    {
        "Hydranten Nr": "H14228",
        "UTM Koord. Rechts": "574896.416",
        "UTM Koord. Hoch": "5932437.733",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Marktstraße",
        "HsNr.": "51"
    },
    {
        "Hydranten Nr": "H2908",
        "UTM Koord. Rechts": "574790.987",
        "UTM Koord. Hoch": "5930071.578",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Billwerder Billdeich",
        "HsNr.": "140"
    },
    {
        "Hydranten Nr": "H17564",
        "UTM Koord. Rechts": "571730.797",
        "UTM Koord. Hoch": "5933253.281",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Grüner Weg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H15687",
        "UTM Koord. Rechts": "571694.577",
        "UTM Koord. Hoch": "5934616.566",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "209"
    },
    {
        "Hydranten Nr": "H6340",
        "UTM Koord. Rechts": "571586.809",
        "UTM Koord. Hoch": "5935082.538",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Tribünenweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H5154",
        "UTM Koord. Rechts": "571077.121",
        "UTM Koord. Hoch": "5935284.047",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Marienthal",
        "Straßenname": "Zitzewitzstraße",
        "HsNr.": "53"
    },
    {
        "Hydranten Nr": "H12243",
        "UTM Koord. Rechts": "571845.569",
        "UTM Koord. Hoch": "5930104.94",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "43"
    },
    {
        "Hydranten Nr": "H18013",
        "UTM Koord. Rechts": "571396.384",
        "UTM Koord. Hoch": "5934204.552",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hertogestraße",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H13414",
        "UTM Koord. Rechts": "571275.573",
        "UTM Koord. Hoch": "5932426.366",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H11054",
        "UTM Koord. Rechts": "570778.857",
        "UTM Koord. Hoch": "5930943.705",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H10940",
        "UTM Koord. Rechts": "572384.293",
        "UTM Koord. Hoch": "5928006.03",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "344"
    },
    {
        "Hydranten Nr": "H10941",
        "UTM Koord. Rechts": "572306.871",
        "UTM Koord. Hoch": "5928091.413",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "339"
    },
    {
        "Hydranten Nr": "H10942",
        "UTM Koord. Rechts": "572216.873",
        "UTM Koord. Hoch": "5928158.423",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "316"
    },
    {
        "Hydranten Nr": "H10947",
        "UTM Koord. Rechts": "572123.839",
        "UTM Koord. Hoch": "5928230.901",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "317"
    },
    {
        "Hydranten Nr": "H6524",
        "UTM Koord. Rechts": "572226.606",
        "UTM Koord. Hoch": "5935444.727",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "KLGV.131 Ernst-Albers-Straße",
        "HsNr.": "77"
    },
    {
        "Hydranten Nr": "H11087",
        "UTM Koord. Rechts": "571542.408",
        "UTM Koord. Hoch": "5928472.446",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Tatenberger Weg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H11084",
        "UTM Koord. Rechts": "571612.182",
        "UTM Koord. Hoch": "5928572.762",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Tatenberger Weg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H11085",
        "UTM Koord. Rechts": "571558.412",
        "UTM Koord. Hoch": "5928484.071",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Tatenberger Weg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H11092",
        "UTM Koord. Rechts": "571523.122",
        "UTM Koord. Hoch": "5928351.178",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Tatenberger Weg",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H11015",
        "UTM Koord. Rechts": "571639.67",
        "UTM Koord. Hoch": "5929619.351",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Kirchenweg",
        "HsNr.": "52"
    },
    {
        "Hydranten Nr": "H11017",
        "UTM Koord. Rechts": "571727.438",
        "UTM Koord. Hoch": "5929710.82",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Kirchenweg",
        "HsNr.": "61"
    },
    {
        "Hydranten Nr": "H13598",
        "UTM Koord. Rechts": "572531.473",
        "UTM Koord. Hoch": "5928404.981",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Amandus-Stubbe-Straße",
        "HsNr.": "158"
    },
    {
        "Hydranten Nr": "H6339",
        "UTM Koord. Rechts": "571603.783",
        "UTM Koord. Hoch": "5935104.52",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "96"
    },
    {
        "Hydranten Nr": "H11080",
        "UTM Koord. Rechts": "571153.914",
        "UTM Koord. Hoch": "5929001.584",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Vorlandring",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H17063",
        "UTM Koord. Rechts": "572149.081",
        "UTM Koord. Hoch": "5934419.961",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sandkamp",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H11037",
        "UTM Koord. Rechts": "571241.52",
        "UTM Koord. Hoch": "5930114.347",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Sandwisch",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H5844",
        "UTM Koord. Rechts": "572799.751",
        "UTM Koord. Hoch": "5935149.476",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Grüningweg",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H18085",
        "UTM Koord. Rechts": "571713.373",
        "UTM Koord. Hoch": "5933938.821",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Pagenfelder Straße",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H17070",
        "UTM Koord. Rechts": "572522.789",
        "UTM Koord. Hoch": "5933307.925",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Weddestraße",
        "HsNr.": "112"
    },
    {
        "Hydranten Nr": "H15785",
        "UTM Koord. Rechts": "571382.912",
        "UTM Koord. Hoch": "5931725.7",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "53"
    },
    {
        "Hydranten Nr": "H6529",
        "UTM Koord. Rechts": "572020.282",
        "UTM Koord. Hoch": "5935402.755",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "KLGV.131 Ernst-Albers-Straße",
        "HsNr.": "66"
    },
    {
        "Hydranten Nr": "H5936",
        "UTM Koord. Rechts": "572535.337",
        "UTM Koord. Hoch": "5935383.272",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Stoltenstraße",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H5933",
        "UTM Koord. Rechts": "572467.593",
        "UTM Koord. Hoch": "5935222.461",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Stoltenstraße",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H17796",
        "UTM Koord. Rechts": "571100.464",
        "UTM Koord. Hoch": "5934687.494",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Snitgerreihe",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H17850",
        "UTM Koord. Rechts": "571549.298",
        "UTM Koord. Hoch": "5934708.574",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "185"
    },
    {
        "Hydranten Nr": "H17857",
        "UTM Koord. Rechts": "571624.426",
        "UTM Koord. Hoch": "5934661.661",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "201"
    },
    {
        "Hydranten Nr": "H5931",
        "UTM Koord. Rechts": "572553.047",
        "UTM Koord. Hoch": "5935229.14",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Stoltenstraße",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H12203",
        "UTM Koord. Rechts": "571764.705",
        "UTM Koord. Hoch": "5930027.419",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Hein-Baxmann-Stieg",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H11028",
        "UTM Koord. Rechts": "571169.9",
        "UTM Koord. Hoch": "5929593.657",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Holzhafenufer",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H11060",
        "UTM Koord. Rechts": "571330.05",
        "UTM Koord. Hoch": "5930023.932",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Sandwisch",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H11063",
        "UTM Koord. Rechts": "571493.889",
        "UTM Koord. Hoch": "5929945.334",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Sandwisch",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "H11067",
        "UTM Koord. Rechts": "571614.042",
        "UTM Koord. Hoch": "5929932.778",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Sandwisch",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H11069",
        "UTM Koord. Rechts": "571827.252",
        "UTM Koord. Hoch": "5929883.118",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Sandwisch",
        "HsNr.": "51"
    },
    {
        "Hydranten Nr": "H14258",
        "UTM Koord. Rechts": "573965.346",
        "UTM Koord. Hoch": "5932286.872",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Mühlenweg",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H5922",
        "UTM Koord. Rechts": "572557.973",
        "UTM Koord. Hoch": "5935155.433",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Stoltenstraße",
        "HsNr.": "54"
    },
    {
        "Hydranten Nr": "H11012",
        "UTM Koord. Rechts": "571480.549",
        "UTM Koord. Hoch": "5929480.663",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Kirchenweg",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H6367",
        "UTM Koord. Rechts": "571229.957",
        "UTM Koord. Hoch": "5934971.022",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rhiemsweg",
        "HsNr.": "61"
    },
    {
        "Hydranten Nr": "H6344",
        "UTM Koord. Rechts": "571621.201",
        "UTM Koord. Hoch": "5934980.722",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "77"
    },
    {
        "Hydranten Nr": "H6351",
        "UTM Koord. Rechts": "571848.224",
        "UTM Koord. Hoch": "5934218.115",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H6354",
        "UTM Koord. Rechts": "571841.802",
        "UTM Koord. Hoch": "5934294.806",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H20726",
        "UTM Koord. Rechts": "570925.339",
        "UTM Koord. Hoch": "5934553.833",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Weg",
        "HsNr.": "168"
    },
    {
        "Hydranten Nr": "H17777",
        "UTM Koord. Rechts": "571187.195",
        "UTM Koord. Hoch": "5934530.079",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rhiemsweg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H10951",
        "UTM Koord. Rechts": "571886.384",
        "UTM Koord. Hoch": "5928460.063",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "282"
    },
    {
        "Hydranten Nr": "H11041",
        "UTM Koord. Rechts": "571187.348",
        "UTM Koord. Hoch": "5930152.982",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "110"
    },
    {
        "Hydranten Nr": "H11049",
        "UTM Koord. Rechts": "570880.371",
        "UTM Koord. Hoch": "5930603.568",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "59"
    },
    {
        "Hydranten Nr": "H18264",
        "UTM Koord. Rechts": "571235.256",
        "UTM Koord. Hoch": "5933681.124",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bauerbergweg",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H18343",
        "UTM Koord. Rechts": "571983.689",
        "UTM Koord. Hoch": "5933426.16",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Brückenweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H19490",
        "UTM Koord. Rechts": "571658.741",
        "UTM Koord. Hoch": "5932675.436",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H17196",
        "UTM Koord. Rechts": "571876.283",
        "UTM Koord. Hoch": "5934078.715",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Pagenfelder Platz",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H17790",
        "UTM Koord. Rechts": "571214.596",
        "UTM Koord. Hoch": "5934551.93",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rhiemsweg",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H18053",
        "UTM Koord. Rechts": "571779.219",
        "UTM Koord. Hoch": "5933858.519",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Nedderndorfer Weg",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H10723",
        "UTM Koord. Rechts": "571529.369",
        "UTM Koord. Hoch": "5928885.532",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "239"
    },
    {
        "Hydranten Nr": "H10721",
        "UTM Koord. Rechts": "571589.903",
        "UTM Koord. Hoch": "5928685.805",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "252"
    },
    {
        "Hydranten Nr": "H13166",
        "UTM Koord. Rechts": "570853.121",
        "UTM Koord. Hoch": "5931555.618",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "KLGV.117 Tiefstack-Süd",
        "HsNr.": "94"
    },
    {
        "Hydranten Nr": "H13156",
        "UTM Koord. Rechts": "570889.83",
        "UTM Koord. Hoch": "5931281.043",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H11044",
        "UTM Koord. Rechts": "571016.336",
        "UTM Koord. Hoch": "5930219.67",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "91"
    },
    {
        "Hydranten Nr": "H11045",
        "UTM Koord. Rechts": "570956.757",
        "UTM Koord. Hoch": "5930305.36",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "83"
    },
    {
        "Hydranten Nr": "H11048",
        "UTM Koord. Rechts": "570911.076",
        "UTM Koord. Hoch": "5930502.652",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "63"
    },
    {
        "Hydranten Nr": "H10731",
        "UTM Koord. Rechts": "571340.241",
        "UTM Koord. Hoch": "5928600.855",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Kneidenweg",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H10732",
        "UTM Koord. Rechts": "571251.796",
        "UTM Koord. Hoch": "5928607.154",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Kneidenweg",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H10733",
        "UTM Koord. Rechts": "571164.15",
        "UTM Koord. Hoch": "5928619.729",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Kneidenweg",
        "HsNr.": "43"
    },
    {
        "Hydranten Nr": "H18762",
        "UTM Koord. Rechts": "572595.483",
        "UTM Koord. Hoch": "5934126.199",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "111"
    },
    {
        "Hydranten Nr": "H13164",
        "UTM Koord. Rechts": "570857.599",
        "UTM Koord. Hoch": "5931549.6",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "KLGV.117 Tiefstack-Süd",
        "HsNr.": "94"
    },
    {
        "Hydranten Nr": "H13168",
        "UTM Koord. Rechts": "570889.933",
        "UTM Koord. Hoch": "5931395.803",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "KLGV.117 Tiefstack-Süd",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H10965",
        "UTM Koord. Rechts": "571671.919",
        "UTM Koord. Hoch": "5928590.073",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "261"
    },
    {
        "Hydranten Nr": "H5949",
        "UTM Koord. Rechts": "572109.804",
        "UTM Koord. Hoch": "5935323.785",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bei den Tennisplätzen",
        "HsNr.": "77"
    },
    {
        "Hydranten Nr": "H10734",
        "UTM Koord. Rechts": "571139.529",
        "UTM Koord. Hoch": "5928521.362",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Hauptdeich",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H15700",
        "UTM Koord. Rechts": "571724.753",
        "UTM Koord. Hoch": "5934554.67",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "218"
    },
    {
        "Hydranten Nr": "H13600",
        "UTM Koord. Rechts": "572373.376",
        "UTM Koord. Hoch": "5928694.428",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Amandus-Stubbe-Straße",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H13646",
        "UTM Koord. Rechts": "572328.158",
        "UTM Koord. Hoch": "5928970.803",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Amandus-Stubbe-Straße",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H13647",
        "UTM Koord. Rechts": "572313.572",
        "UTM Koord. Hoch": "5928859.43",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Amandus-Stubbe-Straße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H11050",
        "UTM Koord. Rechts": "570816.49",
        "UTM Koord. Hoch": "5930698.157",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "47"
    },
    {
        "Hydranten Nr": "H13597",
        "UTM Koord. Rechts": "572588.011",
        "UTM Koord. Hoch": "5928311.632",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Amandus-Stubbe-Straße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H11052",
        "UTM Koord. Rechts": "570778.389",
        "UTM Koord. Hoch": "5930751.367",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "41"
    },
    {
        "Hydranten Nr": "H13648",
        "UTM Koord. Rechts": "572126.391",
        "UTM Koord. Hoch": "5928805.029",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Amandus-Stubbe-Straße",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H11036",
        "UTM Koord. Rechts": "571218.599",
        "UTM Koord. Hoch": "5930081.45",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "121"
    },
    {
        "Hydranten Nr": "H6525",
        "UTM Koord. Rechts": "572135.71",
        "UTM Koord. Hoch": "5935402.328",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "KLGV.131 Ernst-Albers-Straße",
        "HsNr.": "71"
    },
    {
        "Hydranten Nr": "H6527",
        "UTM Koord. Rechts": "572045.321",
        "UTM Koord. Hoch": "5935374.356",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "KLGV.131 Ernst-Albers-Straße",
        "HsNr.": "66"
    },
    {
        "Hydranten Nr": "H18434",
        "UTM Koord. Rechts": "572569.796",
        "UTM Koord. Hoch": "5932853.998",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kolumbusstraße",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H18405",
        "UTM Koord. Rechts": "572378.79",
        "UTM Koord. Hoch": "5933307.46",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "427"
    },
    {
        "Hydranten Nr": "H15904",
        "UTM Koord. Rechts": "572207.448",
        "UTM Koord. Hoch": "5934446.371",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sandkamp",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H16842",
        "UTM Koord. Rechts": "572218.325",
        "UTM Koord. Hoch": "5934462.435",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H5843",
        "UTM Koord. Rechts": "572714.477",
        "UTM Koord. Hoch": "5935116.727",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Grüningweg",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H7585",
        "UTM Koord. Rechts": "572521.168",
        "UTM Koord. Hoch": "5931033.759",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Porgesring",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H18054",
        "UTM Koord. Rechts": "571864.808",
        "UTM Koord. Hoch": "5933939.757",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Boberger Straße",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H20812",
        "UTM Koord. Rechts": "574802.939",
        "UTM Koord. Hoch": "5935634.011",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schwertlilienweg",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H20809",
        "UTM Koord. Rechts": "574761.898",
        "UTM Koord. Hoch": "5935711.566",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hans-Rubbert-Straße",
        "HsNr.": "68"
    },
    {
        "Hydranten Nr": "H20820",
        "UTM Koord. Rechts": "574689.807",
        "UTM Koord. Hoch": "5935580.256",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hans-Rubbert-Straße",
        "HsNr.": "77"
    },
    {
        "Hydranten Nr": "H16845",
        "UTM Koord. Rechts": "572315.727",
        "UTM Koord. Hoch": "5934528.08",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H6348",
        "UTM Koord. Rechts": "571812.208",
        "UTM Koord. Hoch": "5934353.914",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H17087",
        "UTM Koord. Rechts": "571988.294",
        "UTM Koord. Hoch": "5934278.409",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H20814",
        "UTM Koord. Rechts": "574640.918",
        "UTM Koord. Hoch": "5935661.071",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schwertlilienweg",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H20902",
        "UTM Koord. Rechts": "574864.069",
        "UTM Koord. Hoch": "5935694.516",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Wiesenknopfstieg",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H20911",
        "UTM Koord. Rechts": "574766.921",
        "UTM Koord. Hoch": "5935825.942",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schwanenblumenplatz",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H20818",
        "UTM Koord. Rechts": "574668.404",
        "UTM Koord. Hoch": "5935569.561",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hainveilchenplatz",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H20905",
        "UTM Koord. Rechts": "574678.67",
        "UTM Koord. Hoch": "5935748.678",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Storchenschnabelstieg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H12763",
        "UTM Koord. Rechts": "574043.463",
        "UTM Koord. Hoch": "5933995.791",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Meriandamm",
        "HsNr.": "32"
    },
    {
        "Hydranten Nr": "H22104",
        "UTM Koord. Rechts": "571502.599",
        "UTM Koord. Hoch": "5931815.556",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H13557",
        "UTM Koord. Rechts": "574015.71",
        "UTM Koord. Hoch": "5933803.626",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Behaimweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H13558",
        "UTM Koord. Rechts": "573993.764",
        "UTM Koord. Hoch": "5933904.598",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Behaimweg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H13559",
        "UTM Koord. Rechts": "573958.022",
        "UTM Koord. Hoch": "5934055.703",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Behaimweg",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H12754",
        "UTM Koord. Rechts": "573945.4863",
        "UTM Koord. Hoch": "5933981.362",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Höfnageleck",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H12765",
        "UTM Koord. Rechts": "574084.473",
        "UTM Koord. Hoch": "5933997.029",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Meriandamm",
        "HsNr.": "38"
    },
    {
        "Hydranten Nr": "H5824",
        "UTM Koord. Rechts": "572941.961",
        "UTM Koord. Hoch": "5935335.645",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Georg-Blume-Straße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H5827",
        "UTM Koord. Rechts": "572988.222",
        "UTM Koord. Hoch": "5935185.352",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Laufkötterweg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H5830",
        "UTM Koord. Rechts": "573129.239",
        "UTM Koord. Hoch": "5935175.51",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Laufkötterweg",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H6509",
        "UTM Koord. Rechts": "573525.338",
        "UTM Koord. Hoch": "5935175.279",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dietzweg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H6473",
        "UTM Koord. Rechts": "573882.379",
        "UTM Koord. Hoch": "5935266.156",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rosenrotweg",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H5807",
        "UTM Koord. Rechts": "573179.743",
        "UTM Koord. Hoch": "5935345.31",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Georg-Blume-Straße",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H5809",
        "UTM Koord. Rechts": "573275.03",
        "UTM Koord. Hoch": "5935351.654",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Georg-Blume-Straße",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H14297",
        "UTM Koord. Rechts": "574919.965",
        "UTM Koord. Hoch": "5932980.421",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dudenweg",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H16272",
        "UTM Koord. Rechts": "573155.397",
        "UTM Koord. Hoch": "5932858.81",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16262",
        "UTM Koord. Rechts": "573100.37",
        "UTM Koord. Hoch": "5932860.249",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "54"
    },
    {
        "Hydranten Nr": "H16305",
        "UTM Koord. Rechts": "573323.09",
        "UTM Koord. Hoch": "5932709.591",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "75"
    },
    {
        "Hydranten Nr": "H16318",
        "UTM Koord. Rechts": "573338.103",
        "UTM Koord. Hoch": "5932677.152",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "86"
    },
    {
        "Hydranten Nr": "H16302",
        "UTM Koord. Rechts": "573394.498",
        "UTM Koord. Hoch": "5932663.467",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "83"
    },
    {
        "Hydranten Nr": "H17068",
        "UTM Koord. Rechts": "572903.433",
        "UTM Koord. Hoch": "5932989.345",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H16207",
        "UTM Koord. Rechts": "573223.4",
        "UTM Koord. Hoch": "5933223.271",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Nathstieg",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H16214",
        "UTM Koord. Rechts": "573387.858",
        "UTM Koord. Hoch": "5933119.477",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Reclamstraße",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H16161",
        "UTM Koord. Rechts": "573632.823",
        "UTM Koord. Hoch": "5933258.615",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Weg",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H16315",
        "UTM Koord. Rechts": "573411.05",
        "UTM Koord. Hoch": "5932621.362",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "98"
    },
    {
        "Hydranten Nr": "H16352",
        "UTM Koord. Rechts": "573183.156",
        "UTM Koord. Hoch": "5932872.825",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H14223",
        "UTM Koord. Rechts": "574693.121",
        "UTM Koord. Hoch": "5932488.81",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Brockhausweg",
        "HsNr.": "59"
    },
    {
        "Hydranten Nr": "H14291",
        "UTM Koord. Rechts": "574467.917",
        "UTM Koord. Hoch": "5932464.248",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Druckerstraße",
        "HsNr.": "47"
    },
    {
        "Hydranten Nr": "H16482",
        "UTM Koord. Rechts": "574722.86",
        "UTM Koord. Hoch": "5933096.234",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Merkenstraße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16362",
        "UTM Koord. Rechts": "573410.777",
        "UTM Koord. Hoch": "5932802.649",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H16364",
        "UTM Koord. Rechts": "573505.665",
        "UTM Koord. Hoch": "5932775.759",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "44"
    },
    {
        "Hydranten Nr": "H16348",
        "UTM Koord. Rechts": "573475.029",
        "UTM Koord. Hoch": "5932795.302",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "45"
    },
    {
        "Hydranten Nr": "H8978",
        "UTM Koord. Rechts": "575244.86",
        "UTM Koord. Hoch": "5931579.357",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "123"
    },
    {
        "Hydranten Nr": "H6555",
        "UTM Koord. Rechts": "573483.304",
        "UTM Koord. Hoch": "5935341.09",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Spliedtring",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H6512",
        "UTM Koord. Rechts": "573424.907",
        "UTM Koord. Hoch": "5935223.802",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Dannerallee",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H17203",
        "UTM Koord. Rechts": "571920.003",
        "UTM Koord. Hoch": "5934171.13",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sandkamp",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H16056",
        "UTM Koord. Rechts": "573886.307",
        "UTM Koord. Hoch": "5933723.174",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Leysahtbogen",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H16382",
        "UTM Koord. Rechts": "573537.397",
        "UTM Koord. Hoch": "5932658.97",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Frobeniusweg",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H16335",
        "UTM Koord. Rechts": "573013.52",
        "UTM Koord. Hoch": "5933046.774",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H16383",
        "UTM Koord. Rechts": "573587.709",
        "UTM Koord. Hoch": "5932790.655",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "55"
    },
    {
        "Hydranten Nr": "H16385",
        "UTM Koord. Rechts": "573598.232",
        "UTM Koord. Hoch": "5932950.249",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Reclamstraße",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H16386",
        "UTM Koord. Rechts": "573581.433",
        "UTM Koord. Hoch": "5933035.937",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Reclamstraße",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H7995",
        "UTM Koord. Rechts": "573906.56",
        "UTM Koord. Hoch": "5934871.401",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "152"
    },
    {
        "Hydranten Nr": "H7990",
        "UTM Koord. Rechts": "573819.508",
        "UTM Koord. Hoch": "5934997.166",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Glasbergstraße",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H1001",
        "UTM Koord. Rechts": "573741.779",
        "UTM Koord. Hoch": "5933101.734",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hollestraße",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H9522",
        "UTM Koord. Rechts": "576233.877",
        "UTM Koord. Hoch": "5933117.94",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "126"
    },
    {
        "Hydranten Nr": "H9445",
        "UTM Koord. Rechts": "576162.992",
        "UTM Koord. Hoch": "5933107.186",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "122"
    },
    {
        "Hydranten Nr": "H9526",
        "UTM Koord. Rechts": "576353.368",
        "UTM Koord. Hoch": "5933255.492",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Glinder Straße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H16407",
        "UTM Koord. Rechts": "573270.681",
        "UTM Koord. Hoch": "5932929.945",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Platz",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16159",
        "UTM Koord. Rechts": "573579.651",
        "UTM Koord. Hoch": "5933197.187",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Weg",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H16133",
        "UTM Koord. Rechts": "573673.878",
        "UTM Koord. Hoch": "5933290.803",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Weg",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H16240",
        "UTM Koord. Rechts": "573827.817",
        "UTM Koord. Hoch": "5933437.775",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Gundermannstraße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16246",
        "UTM Koord. Rechts": "573817.896",
        "UTM Koord. Hoch": "5933304.047",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Liebezeitstraße",
        "HsNr.": "37"
    },
    {
        "Hydranten Nr": "H16181",
        "UTM Koord. Rechts": "573208.842",
        "UTM Koord. Hoch": "5933418.845",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "46"
    },
    {
        "Hydranten Nr": "H17062",
        "UTM Koord. Rechts": "572042.753",
        "UTM Koord. Hoch": "5934297.021",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sandkamp",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H16909",
        "UTM Koord. Rechts": "573116.243",
        "UTM Koord. Hoch": "5933291.008",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H16120",
        "UTM Koord. Rechts": "573300.532",
        "UTM Koord. Hoch": "5933374.067",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Gothaer Weg",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H14237",
        "UTM Koord. Rechts": "574329.325",
        "UTM Koord. Hoch": "5932219.983",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mehrenskamp",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H14296",
        "UTM Koord. Rechts": "574465.657",
        "UTM Koord. Hoch": "5932309.812",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Teubnerweg",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H16117",
        "UTM Koord. Rechts": "573912.193",
        "UTM Koord. Hoch": "5933575.769",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Weg",
        "HsNr.": "71"
    },
    {
        "Hydranten Nr": "H16519",
        "UTM Koord. Rechts": "574656.175",
        "UTM Koord. Hoch": "5933717.431",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mattkamp",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H16561",
        "UTM Koord. Rechts": "574833.638",
        "UTM Koord. Hoch": "5933187.988",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Höhe",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H16558",
        "UTM Koord. Rechts": "574728.536",
        "UTM Koord. Hoch": "5933276.463",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Baxmannweg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16556",
        "UTM Koord. Rechts": "574715.006",
        "UTM Koord. Hoch": "5933364.879",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Merkenstraße",
        "HsNr.": "44"
    },
    {
        "Hydranten Nr": "H16575",
        "UTM Koord. Rechts": "574829.906",
        "UTM Koord. Hoch": "5933561.179",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Reinskamp",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H9534",
        "UTM Koord. Rechts": "576099.651",
        "UTM Koord. Hoch": "5933395.56",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Glinder Straße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H11448",
        "UTM Koord. Rechts": "575982.044",
        "UTM Koord. Hoch": "5934228.27",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Barsbütteler Weg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H14292",
        "UTM Koord. Rechts": "574457.371",
        "UTM Koord. Hoch": "5932395.037",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Teubnerweg",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H14293",
        "UTM Koord. Rechts": "574474.053",
        "UTM Koord. Hoch": "5932340.509",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Teubnerweg",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H16162",
        "UTM Koord. Rechts": "573589.209",
        "UTM Koord. Hoch": "5933230.355",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Gothaer Weg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H15850",
        "UTM Koord. Rechts": "574166.568",
        "UTM Koord. Hoch": "5932713.892",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "102"
    },
    {
        "Hydranten Nr": "H16713",
        "UTM Koord. Rechts": "572942.502",
        "UTM Koord. Hoch": "5934032.807",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Legienstraße",
        "HsNr.": "130"
    },
    {
        "Hydranten Nr": "H15947",
        "UTM Koord. Rechts": "574124.154",
        "UTM Koord. Hoch": "5935024.144",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Geerzkamp",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H7513",
        "UTM Koord. Rechts": "572684.854",
        "UTM Koord. Hoch": "5932089.153",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Moorfleeter Straße",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H7418",
        "UTM Koord. Rechts": "572848.974",
        "UTM Koord. Hoch": "5932225.621",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "59"
    },
    {
        "Hydranten Nr": "H15847",
        "UTM Koord. Rechts": "574256.449",
        "UTM Koord. Hoch": "5932729.093",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Letternkamp",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16266",
        "UTM Koord. Rechts": "573002.643",
        "UTM Koord. Hoch": "5932937.672",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "45"
    },
    {
        "Hydranten Nr": "H16299",
        "UTM Koord. Rechts": "573543.33",
        "UTM Koord. Hoch": "5932542.591",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Spökelbargring",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H7416",
        "UTM Koord. Rechts": "572844.876",
        "UTM Koord. Hoch": "5932208.958",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "59"
    },
    {
        "Hydranten Nr": "H11446",
        "UTM Koord. Rechts": "575933.611",
        "UTM Koord. Hoch": "5933967.921",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Barsbütteler Weg",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H11451",
        "UTM Koord. Rechts": "576036.342",
        "UTM Koord. Hoch": "5934518.98",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "KLGV.119  Aufbau",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H19531",
        "UTM Koord. Rechts": "573104.432",
        "UTM Koord. Hoch": "5932153.947",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "85"
    },
    {
        "Hydranten Nr": "H16970",
        "UTM Koord. Rechts": "573880.569",
        "UTM Koord. Hoch": "5932490.936",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Schleemer Mühle",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H16966",
        "UTM Koord. Rechts": "573865.997",
        "UTM Koord. Hoch": "5932401.288",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Klinkstraße",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H16232",
        "UTM Koord. Rechts": "573858.032",
        "UTM Koord. Hoch": "5933400.958",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Michael-Hering-Weg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H14194",
        "UTM Koord. Rechts": "574809.367",
        "UTM Koord. Hoch": "5932171.173",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Marktstraße",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H14198",
        "UTM Koord. Rechts": "574770.237",
        "UTM Koord. Hoch": "5932069.899",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Marktstraße",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H14282",
        "UTM Koord. Rechts": "574093.209",
        "UTM Koord. Hoch": "5932518.285",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oberschleems",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H14254",
        "UTM Koord. Rechts": "574093.662",
        "UTM Koord. Hoch": "5932352.116",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Klinkstraße",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H14278",
        "UTM Koord. Rechts": "574039.293",
        "UTM Koord. Hoch": "5932365.373",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oberschleems",
        "HsNr.": "38"
    },
    {
        "Hydranten Nr": "H14256",
        "UTM Koord. Rechts": "574067.054",
        "UTM Koord. Hoch": "5932263.659",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Barckhusendamm",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H14270",
        "UTM Koord. Rechts": "573925.361",
        "UTM Koord. Hoch": "5932394.022",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Klinkstraße",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H14253",
        "UTM Koord. Rechts": "574121.749",
        "UTM Koord. Hoch": "5932444.371",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Barckhusendamm",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16964",
        "UTM Koord. Rechts": "573866.052",
        "UTM Koord. Hoch": "5932105.368",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Bahnstieg",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H6278",
        "UTM Koord. Rechts": "573854.521",
        "UTM Koord. Hoch": "5933152.395",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schleemer Weg",
        "HsNr.": "50"
    },
    {
        "Hydranten Nr": "H16370",
        "UTM Koord. Rechts": "573642.982",
        "UTM Koord. Hoch": "5932734.166",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H14193",
        "UTM Koord. Rechts": "574846.269",
        "UTM Koord. Hoch": "5932255.193",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Marktstraße",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H17089",
        "UTM Koord. Rechts": "571886.928",
        "UTM Koord. Hoch": "5934342.906",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "H16225",
        "UTM Koord. Rechts": "574108.926",
        "UTM Koord. Hoch": "5933269.412",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Liebezeitstraße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H11007",
        "UTM Koord. Rechts": "574897.84",
        "UTM Koord. Hoch": "5933649.502",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Reinskamp",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H15964",
        "UTM Koord. Rechts": "574381.195",
        "UTM Koord. Hoch": "5934922.95",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "200"
    },
    {
        "Hydranten Nr": "H15965",
        "UTM Koord. Rechts": "574326.058",
        "UTM Koord. Hoch": "5934882.679",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "200"
    },
    {
        "Hydranten Nr": "H15969",
        "UTM Koord. Rechts": "574167.005",
        "UTM Koord. Hoch": "5934804.267",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "153"
    },
    {
        "Hydranten Nr": "H16457",
        "UTM Koord. Rechts": "574148.716",
        "UTM Koord. Hoch": "5933262.273",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archenholzstraße",
        "HsNr.": "54"
    },
    {
        "Hydranten Nr": "H16229",
        "UTM Koord. Rechts": "574195.155",
        "UTM Koord. Hoch": "5933336.59",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archenholzstraße",
        "HsNr.": "55"
    },
    {
        "Hydranten Nr": "H15979",
        "UTM Koord. Rechts": "574108.714",
        "UTM Koord. Hoch": "5934303.503",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rehwiesen",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H15978",
        "UTM Koord. Rechts": "574042.125",
        "UTM Koord. Hoch": "5934359.045",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hasenbanckweg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H15991",
        "UTM Koord. Rechts": "573948.156",
        "UTM Koord. Hoch": "5934428.863",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Heinrich-Stück-Gang",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H15894",
        "UTM Koord. Rechts": "573428.7175",
        "UTM Koord. Hoch": "5935025.854",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "108"
    },
    {
        "Hydranten Nr": "H5806",
        "UTM Koord. Rechts": "573085.067",
        "UTM Koord. Hoch": "5935360.75",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Georg-Blume-Straße",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H21273",
        "UTM Koord. Rechts": "573293.624",
        "UTM Koord. Hoch": "5934179.049",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinadlerweg",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H16060",
        "UTM Koord. Rechts": "573971.567",
        "UTM Koord. Hoch": "5933680.196",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Weg",
        "HsNr.": "81"
    },
    {
        "Hydranten Nr": "H6483",
        "UTM Koord. Rechts": "573979.442",
        "UTM Koord. Hoch": "5935010.295",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "195"
    },
    {
        "Hydranten Nr": "H13562",
        "UTM Koord. Rechts": "573981.639",
        "UTM Koord. Hoch": "5934163.211",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Behaimweg",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H16065",
        "UTM Koord. Rechts": "574058.064",
        "UTM Koord. Hoch": "5933629.351",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Jenkelweg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H9036",
        "UTM Koord. Rechts": "575844.863",
        "UTM Koord. Hoch": "5932075.313",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Edvard-Munch-Straße",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H16698",
        "UTM Koord. Rechts": "572986.472",
        "UTM Koord. Hoch": "5934123.719",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Legienstraße",
        "HsNr.": "165"
    },
    {
        "Hydranten Nr": "H6487",
        "UTM Koord. Rechts": "573929.941",
        "UTM Koord. Hoch": "5934913.235",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "183"
    },
    {
        "Hydranten Nr": "H15937",
        "UTM Koord. Rechts": "573970.254",
        "UTM Koord. Hoch": "5934850.301",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rehkoppel",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H15942",
        "UTM Koord. Rechts": "573990.806",
        "UTM Koord. Hoch": "5934906.777",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rehkoppel",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H15958",
        "UTM Koord. Rechts": "574050.823",
        "UTM Koord. Hoch": "5935026.425",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rehkoppel",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H16475",
        "UTM Koord. Rechts": "574479.09",
        "UTM Koord. Hoch": "5933124.099",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Luisenhofstieg",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H16071",
        "UTM Koord. Rechts": "574074.353",
        "UTM Koord. Hoch": "5933539.945",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kaeriusweg",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H6566",
        "UTM Koord. Rechts": "573403.529",
        "UTM Koord. Hoch": "5935533.686",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dannerallee",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H6560",
        "UTM Koord. Rechts": "573581.612",
        "UTM Koord. Hoch": "5935498.612",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Spliedtring",
        "HsNr.": "45"
    },
    {
        "Hydranten Nr": "H6557",
        "UTM Koord. Rechts": "573651.835",
        "UTM Koord. Hoch": "5935361.037",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Spliedtring",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H16236",
        "UTM Koord. Rechts": "573934.297",
        "UTM Koord. Hoch": "5933388.992",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Gundermannstraße",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H16239",
        "UTM Koord. Rechts": "574038.695",
        "UTM Koord. Hoch": "5933356.001",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Felginerweg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H6511",
        "UTM Koord. Rechts": "573525.732",
        "UTM Koord. Hoch": "5935091.803",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dietzweg",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H7982",
        "UTM Koord. Rechts": "573779.21",
        "UTM Koord. Hoch": "5935126.66",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Silberberg",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H5803",
        "UTM Koord. Rechts": "572986.492",
        "UTM Koord. Hoch": "5935359.375",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Georg-Blume-Straße",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H5845",
        "UTM Koord. Rechts": "572914.433",
        "UTM Koord. Hoch": "5935150.616",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Grüningweg",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H16622",
        "UTM Koord. Rechts": "573279.718",
        "UTM Koord. Hoch": "5934427.019",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fischadlerstieg",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H15972",
        "UTM Koord. Rechts": "574140.654",
        "UTM Koord. Hoch": "5934658.883",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hasenbanckweg",
        "HsNr.": "38"
    },
    {
        "Hydranten Nr": "H15971",
        "UTM Koord. Rechts": "574179.079",
        "UTM Koord. Hoch": "5934752.307",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hasenbanckweg",
        "HsNr.": "52"
    },
    {
        "Hydranten Nr": "H15951",
        "UTM Koord. Rechts": "574224.504",
        "UTM Koord. Hoch": "5934967.791",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hasenbanckweg",
        "HsNr.": "70"
    },
    {
        "Hydranten Nr": "H16550",
        "UTM Koord. Rechts": "574677.348",
        "UTM Koord. Hoch": "5933461.812",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archenholzstraße",
        "HsNr.": "87"
    },
    {
        "Hydranten Nr": "H16545",
        "UTM Koord. Rechts": "574558.369",
        "UTM Koord. Hoch": "5933447.439",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archenholzstraße",
        "HsNr.": "79"
    },
    {
        "Hydranten Nr": "H16230",
        "UTM Koord. Rechts": "574268.94",
        "UTM Koord. Hoch": "5933377.689",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archenholzstraße",
        "HsNr.": "58"
    },
    {
        "Hydranten Nr": "H16479",
        "UTM Koord. Rechts": "574610.291",
        "UTM Koord. Hoch": "5933127.154",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Cottaweg",
        "HsNr.": "52"
    },
    {
        "Hydranten Nr": "H6423",
        "UTM Koord. Rechts": "573695.051",
        "UTM Koord. Hoch": "5935561.377",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Geißleinweg",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H5831",
        "UTM Koord. Rechts": "573097.18",
        "UTM Koord. Hoch": "5935121.182",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Ernst-Scherling-Weg",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H16064",
        "UTM Koord. Rechts": "574152.865",
        "UTM Koord. Hoch": "5933597.208",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Jenkelweg",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H16059",
        "UTM Koord. Rechts": "573994.168",
        "UTM Koord. Hoch": "5933758.734",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Weg",
        "HsNr.": "87"
    },
    {
        "Hydranten Nr": "H16696",
        "UTM Koord. Rechts": "573078.913",
        "UTM Koord. Hoch": "5934311.569",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Legienstraße",
        "HsNr.": "158"
    },
    {
        "Hydranten Nr": "H16697",
        "UTM Koord. Rechts": "573030.202",
        "UTM Koord. Hoch": "5934216.909",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Legienstraße",
        "HsNr.": "175"
    },
    {
        "Hydranten Nr": "H16701",
        "UTM Koord. Rechts": "572968.618",
        "UTM Koord. Hoch": "5934215.691",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Speckenreye",
        "HsNr.": "66"
    },
    {
        "Hydranten Nr": "H11452",
        "UTM Koord. Rechts": "576069.397",
        "UTM Koord. Hoch": "5934692.053",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Barsbütteler Weg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H16903",
        "UTM Koord. Rechts": "573062.532",
        "UTM Koord. Hoch": "5933191.113",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H5800",
        "UTM Koord. Rechts": "573007.966",
        "UTM Koord. Hoch": "5935433.28",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Böcklerstraße",
        "HsNr.": "32"
    },
    {
        "Hydranten Nr": "H6562",
        "UTM Koord. Rechts": "573494.544",
        "UTM Koord. Hoch": "5935497.795",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Spliedtring",
        "HsNr.": "55"
    },
    {
        "Hydranten Nr": "H5793",
        "UTM Koord. Rechts": "573326.153",
        "UTM Koord. Hoch": "5935507.149",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Böcklerstraße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H6559",
        "UTM Koord. Rechts": "573650.128",
        "UTM Koord. Hoch": "5935467.369",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Spliedtring",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H6556",
        "UTM Koord. Rechts": "573583.606",
        "UTM Koord. Hoch": "5935342.476",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Spliedtring",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H16522",
        "UTM Koord. Rechts": "574772.65",
        "UTM Koord. Hoch": "5933546.447",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Reinskamp",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H11347",
        "UTM Koord. Rechts": "573613.359",
        "UTM Koord. Hoch": "5933214.361",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archenholzstraße",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H16138",
        "UTM Koord. Rechts": "573457.081",
        "UTM Koord. Hoch": "5933194.708",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Maukestieg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H16139",
        "UTM Koord. Rechts": "573525.441",
        "UTM Koord. Hoch": "5933144.826",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Maukestieg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16295",
        "UTM Koord. Rechts": "573575.717",
        "UTM Koord. Hoch": "5932576.83",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Spökelbargring",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H16377",
        "UTM Koord. Rechts": "573793.693",
        "UTM Koord. Hoch": "5932684.097",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "75"
    },
    {
        "Hydranten Nr": "H19516",
        "UTM Koord. Rechts": "573256.765",
        "UTM Koord. Hoch": "5932313.911",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "210"
    },
    {
        "Hydranten Nr": "H19521",
        "UTM Koord. Rechts": "573520.359",
        "UTM Koord. Hoch": "5932230.749",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "229"
    },
    {
        "Hydranten Nr": "H14288",
        "UTM Koord. Rechts": "574270.469",
        "UTM Koord. Hoch": "5932478.154",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Druckerstraße",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H15962",
        "UTM Koord. Rechts": "574159.739",
        "UTM Koord. Hoch": "5934945.823",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hondiusweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H15984",
        "UTM Koord. Rechts": "574053.789",
        "UTM Koord. Hoch": "5934594",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Lindenbergkoppel",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H15981",
        "UTM Koord. Rechts": "574111.316",
        "UTM Koord. Hoch": "5934716.514",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Triftkoppel",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H14287",
        "UTM Koord. Rechts": "574192.99",
        "UTM Koord. Hoch": "5932527.828",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Druckerstraße",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H14224",
        "UTM Koord. Rechts": "574707.083",
        "UTM Koord. Hoch": "5932429.276",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Brockhausweg",
        "HsNr.": "53"
    },
    {
        "Hydranten Nr": "H16926",
        "UTM Koord. Rechts": "572942.538",
        "UTM Koord. Hoch": "5933092.638",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kirchlinden",
        "HsNr.": "38"
    },
    {
        "Hydranten Nr": "H16935",
        "UTM Koord. Rechts": "572917.737",
        "UTM Koord. Hoch": "5933259.198",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kuriergang",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H16454",
        "UTM Koord. Rechts": "574094.789",
        "UTM Koord. Hoch": "5933177.51",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archenholzstraße",
        "HsNr.": "48"
    },
    {
        "Hydranten Nr": "H16375",
        "UTM Koord. Rechts": "573720.806",
        "UTM Koord. Hoch": "5932708.647",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "60"
    },
    {
        "Hydranten Nr": "H16341",
        "UTM Koord. Rechts": "573742.837",
        "UTM Koord. Hoch": "5932716.354",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "71"
    },
    {
        "Hydranten Nr": "H16339",
        "UTM Koord. Rechts": "573845.369",
        "UTM Koord. Hoch": "5932686.956",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "81"
    },
    {
        "Hydranten Nr": "H6792",
        "UTM Koord. Rechts": "573806.509",
        "UTM Koord. Hoch": "5932929.069",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schleemer Weg",
        "HsNr.": "49"
    },
    {
        "Hydranten Nr": "H6780",
        "UTM Koord. Rechts": "573769.796",
        "UTM Koord. Hoch": "5932751.592",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schleemer Weg",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H7918",
        "UTM Koord. Rechts": "572903.559",
        "UTM Koord. Hoch": "5932829.8",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Moorfleeter Straße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H11306",
        "UTM Koord. Rechts": "573821.699",
        "UTM Koord. Hoch": "5933046.095",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schleemer Weg",
        "HsNr.": "38"
    },
    {
        "Hydranten Nr": "H16462",
        "UTM Koord. Rechts": "574633.73",
        "UTM Koord. Hoch": "5933550.967",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mattkamp",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H16292",
        "UTM Koord. Rechts": "573656.593",
        "UTM Koord. Hoch": "5932633.972",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Spökelbarg",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H16265",
        "UTM Koord. Rechts": "573179.554",
        "UTM Koord. Hoch": "5932793.902",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "66"
    },
    {
        "Hydranten Nr": "H16907",
        "UTM Koord. Rechts": "572966.189",
        "UTM Koord. Hoch": "5933023.663",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H18731",
        "UTM Koord. Rechts": "572664.752",
        "UTM Koord. Hoch": "5932108.646",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Moorfleeter Straße",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H11747",
        "UTM Koord. Rechts": "575422.7",
        "UTM Koord. Hoch": "5932700.795",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H11735",
        "UTM Koord. Rechts": "575633.401",
        "UTM Koord. Hoch": "5932816.203",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archsumer Weg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H11731",
        "UTM Koord. Rechts": "575719.429",
        "UTM Koord. Hoch": "5932863.597",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "66"
    },
    {
        "Hydranten Nr": "H15819",
        "UTM Koord. Rechts": "574626.628",
        "UTM Koord. Hoch": "5932633.64",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Brockhausweg",
        "HsNr.": "76"
    },
    {
        "Hydranten Nr": "H15815",
        "UTM Koord. Rechts": "574464.826",
        "UTM Koord. Hoch": "5932571.825",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Letternkamp",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H15813",
        "UTM Koord. Rechts": "574446.811",
        "UTM Koord. Hoch": "5932624.266",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Letternkamp",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H16085",
        "UTM Koord. Rechts": "573811.529",
        "UTM Koord. Hoch": "5933625.925",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Höhe",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H16083",
        "UTM Koord. Rechts": "573533.054",
        "UTM Koord. Hoch": "5933686.898",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Höhe",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H16084",
        "UTM Koord. Rechts": "573428.258",
        "UTM Koord. Hoch": "5933739.56",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Höhe",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16913",
        "UTM Koord. Rechts": "572977.754",
        "UTM Koord. Hoch": "5933629.589",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinfeldtstraße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16900",
        "UTM Koord. Rechts": "573307.588",
        "UTM Koord. Hoch": "5933678.018",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "81"
    },
    {
        "Hydranten Nr": "H17733",
        "UTM Koord. Rechts": "574549.28",
        "UTM Koord. Hoch": "5931911.247",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "69"
    },
    {
        "Hydranten Nr": "H17734",
        "UTM Koord. Rechts": "574455.89",
        "UTM Koord. Hoch": "5931944.636",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "59"
    },
    {
        "Hydranten Nr": "H17271",
        "UTM Koord. Rechts": "574276.007",
        "UTM Koord. Hoch": "5932022.518",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Weg",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H17963",
        "UTM Koord. Rechts": "574186.556",
        "UTM Koord. Hoch": "5932050.959",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H17973",
        "UTM Koord. Rechts": "574505.188",
        "UTM Koord. Hoch": "5932044.212",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Lohkamp",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H17972",
        "UTM Koord. Rechts": "574583.184",
        "UTM Koord. Hoch": "5931944.807",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Brockhausweg",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H17360",
        "UTM Koord. Rechts": "573966.792",
        "UTM Koord. Hoch": "5931906.886",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rotenbrückenweg",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H17362",
        "UTM Koord. Rechts": "574026.128",
        "UTM Koord. Hoch": "5931833.744",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rotenbrückenweg",
        "HsNr.": "32"
    },
    {
        "Hydranten Nr": "H13633",
        "UTM Koord. Rechts": "573972.328",
        "UTM Koord. Hoch": "5932045.065",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Bahnstieg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H11763",
        "UTM Koord. Rechts": "575472.723",
        "UTM Koord. Hoch": "5932862.105",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinfurther Allee",
        "HsNr.": "32"
    },
    {
        "Hydranten Nr": "H16962",
        "UTM Koord. Rechts": "573742.941",
        "UTM Koord. Hoch": "5932381.494",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "121"
    },
    {
        "Hydranten Nr": "H17236",
        "UTM Koord. Rechts": "574834.029",
        "UTM Koord. Hoch": "5932946.353",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dudenweg",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H14335",
        "UTM Koord. Rechts": "574122.349",
        "UTM Koord. Hoch": "5932819.416",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kaspar-Siemers-Stieg",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H16487",
        "UTM Koord. Rechts": "574718.799",
        "UTM Koord. Hoch": "5933000.082",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Merkenstraße",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H16091",
        "UTM Koord. Rechts": "573538.492",
        "UTM Koord. Hoch": "5933662.217",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Höhe",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H16115",
        "UTM Koord. Rechts": "573612.692",
        "UTM Koord. Hoch": "5933625.39",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Höhe",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H14299",
        "UTM Koord. Rechts": "574814.366",
        "UTM Koord. Hoch": "5932829.818",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "163"
    },
    {
        "Hydranten Nr": "H15835",
        "UTM Koord. Rechts": "574830.529",
        "UTM Koord. Hoch": "5932811.316",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "154"
    },
    {
        "Hydranten Nr": "H14307",
        "UTM Koord. Rechts": "574563.232",
        "UTM Koord. Hoch": "5932792.866",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "141"
    },
    {
        "Hydranten Nr": "H14310",
        "UTM Koord. Rechts": "574466.336",
        "UTM Koord. Hoch": "5932778.635",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "131"
    },
    {
        "Hydranten Nr": "H15842",
        "UTM Koord. Rechts": "574468.936",
        "UTM Koord. Hoch": "5932756.573",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "128"
    },
    {
        "Hydranten Nr": "H16312",
        "UTM Koord. Rechts": "573585.04",
        "UTM Koord. Hoch": "5932492.109",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "114"
    },
    {
        "Hydranten Nr": "H19519",
        "UTM Koord. Rechts": "573380.465",
        "UTM Koord. Hoch": "5932274.733",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "220"
    },
    {
        "Hydranten Nr": "H19530",
        "UTM Koord. Rechts": "573233.209",
        "UTM Koord. Hoch": "5932117.937",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "89"
    },
    {
        "Hydranten Nr": "H16029",
        "UTM Koord. Rechts": "573418.074",
        "UTM Koord. Hoch": "5933855.536",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "90"
    },
    {
        "Hydranten Nr": "H16088",
        "UTM Koord. Rechts": "573730.126",
        "UTM Koord. Hoch": "5933568.44",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Höhe",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H16080",
        "UTM Koord. Rechts": "573715.172",
        "UTM Koord. Hoch": "5933596.61",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Höhe",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H12416",
        "UTM Koord. Rechts": "573652.291",
        "UTM Koord. Hoch": "5934045.507",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Merkatorweg",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H12768",
        "UTM Koord. Rechts": "573777.111",
        "UTM Koord. Hoch": "5933936.927",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Braunstieg",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H20907",
        "UTM Koord. Rechts": "575012.704",
        "UTM Koord. Hoch": "5935525.244",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hans-Rubbert-Straße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16167",
        "UTM Koord. Rechts": "573322.312",
        "UTM Koord. Hoch": "5933663.273",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "72"
    },
    {
        "Hydranten Nr": "H16033",
        "UTM Koord. Rechts": "573460.408",
        "UTM Koord. Hoch": "5933924.278",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "96"
    },
    {
        "Hydranten Nr": "H16048",
        "UTM Koord. Rechts": "573697.602",
        "UTM Koord. Hoch": "5933756.091",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Leysahtbogen",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16702",
        "UTM Koord. Rechts": "572884.252",
        "UTM Koord. Hoch": "5934272.924",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Speckenreye",
        "HsNr.": "54"
    },
    {
        "Hydranten Nr": "H16711",
        "UTM Koord. Rechts": "572860.391",
        "UTM Koord. Hoch": "5934065.229",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Stegerwaldring",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H17131",
        "UTM Koord. Rechts": "572174.141",
        "UTM Koord. Hoch": "5933614.422",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Blostwiete",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16063",
        "UTM Koord. Rechts": "574245.27",
        "UTM Koord. Hoch": "5933614.025",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Jenkelweg",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H16043",
        "UTM Koord. Rechts": "573639.595",
        "UTM Koord. Hoch": "5933764.018",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Karl-Strutz-Weg",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "H16656",
        "UTM Koord. Rechts": "573336.268",
        "UTM Koord. Hoch": "5933755.878",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "95"
    },
    {
        "Hydranten Nr": "H16925",
        "UTM Koord. Rechts": "572908.072",
        "UTM Koord. Hoch": "5933892.39",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Ihlestraße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16483",
        "UTM Koord. Rechts": "574862.571",
        "UTM Koord. Hoch": "5933062.298",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Tabulatorweg",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H15829",
        "UTM Koord. Rechts": "574707.923",
        "UTM Koord. Hoch": "5932783.827",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "144"
    },
    {
        "Hydranten Nr": "H16172",
        "UTM Koord. Rechts": "573291.073",
        "UTM Koord. Hoch": "5933603.036",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "68"
    },
    {
        "Hydranten Nr": "H16604",
        "UTM Koord. Rechts": "573388.347",
        "UTM Koord. Hoch": "5933837.814",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "101"
    },
    {
        "Hydranten Nr": "H12767",
        "UTM Koord. Rechts": "573684.931",
        "UTM Koord. Hoch": "5933983.522",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Braunstieg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H18009",
        "UTM Koord. Rechts": "571498.886",
        "UTM Koord. Hoch": "5934112.493",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bauerberg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H18043",
        "UTM Koord. Rechts": "571632.741",
        "UTM Koord. Hoch": "5934279.527",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bauerberg",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H18025",
        "UTM Koord. Rechts": "571688.039",
        "UTM Koord. Hoch": "5934157.981",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Beim Pachthof",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H14248",
        "UTM Koord. Rechts": "574140.814",
        "UTM Koord. Hoch": "5932288.078",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schöfferstieg",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H14265",
        "UTM Koord. Rechts": "574014.619",
        "UTM Koord. Hoch": "5932120.687",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H14266",
        "UTM Koord. Rechts": "574095.295",
        "UTM Koord. Hoch": "5932083.739",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H9547",
        "UTM Koord. Rechts": "575889.344",
        "UTM Koord. Hoch": "5932850.033",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dagebüller Weg",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H11792",
        "UTM Koord. Rechts": "575347.108",
        "UTM Koord. Hoch": "5932658.373",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H10867",
        "UTM Koord. Rechts": "575480.589",
        "UTM Koord. Hoch": "5933654.694",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schlangenkoppel",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H10876",
        "UTM Koord. Rechts": "575623.656",
        "UTM Koord. Hoch": "5933572.443",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Koolbarg",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H11683",
        "UTM Koord. Rechts": "575510.387",
        "UTM Koord. Hoch": "5933565.113",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Koolbarg",
        "HsNr.": "41"
    },
    {
        "Hydranten Nr": "H17129",
        "UTM Koord. Rechts": "572311.951",
        "UTM Koord. Hoch": "5933591.735",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Vierbergen",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H17101",
        "UTM Koord. Rechts": "572233.011",
        "UTM Koord. Hoch": "5933918.413",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "52"
    },
    {
        "Hydranten Nr": "H14338",
        "UTM Koord. Rechts": "574230.621",
        "UTM Koord. Hoch": "5932833.026",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Papyrusweg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H14339",
        "UTM Koord. Rechts": "574334.234",
        "UTM Koord. Hoch": "5932836.989",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Papyrusweg",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H16466",
        "UTM Koord. Rechts": "574508.046",
        "UTM Koord. Hoch": "5932832.282",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "137"
    },
    {
        "Hydranten Nr": "H14334",
        "UTM Koord. Rechts": "574144.28",
        "UTM Koord. Hoch": "5932861.559",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schleemkoppel",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H9563",
        "UTM Koord. Rechts": "575929.291",
        "UTM Koord. Hoch": "5932948.819",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Knivsbergweg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H352",
        "UTM Koord. Rechts": "575983.271",
        "UTM Koord. Hoch": "5932675.133",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Niebüllweg",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H9537",
        "UTM Koord. Rechts": "576104.958",
        "UTM Koord. Hoch": "5932644.676",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Knivsbergweg",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H9543",
        "UTM Koord. Rechts": "576043.78",
        "UTM Koord. Hoch": "5932627.131",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Glinder Au",
        "HsNr.": "99"
    },
    {
        "Hydranten Nr": "H16148",
        "UTM Koord. Rechts": "573704.728",
        "UTM Koord. Hoch": "5933341.234",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Ruhmkoppel",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16031",
        "UTM Koord. Rechts": "573541.636",
        "UTM Koord. Hoch": "5933940.441",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Karl-Strutz-Weg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H16108",
        "UTM Koord. Rechts": "573478.542",
        "UTM Koord. Hoch": "5933556.895",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Julius-Campe-Weg",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H16628",
        "UTM Koord. Rechts": "573315.469",
        "UTM Koord. Hoch": "5933887.127",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kaptaubentwiete",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H16145",
        "UTM Koord. Rechts": "573824.041",
        "UTM Koord. Hoch": "5933476.41",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Weg",
        "HsNr.": "65"
    },
    {
        "Hydranten Nr": "H16046",
        "UTM Koord. Rechts": "573589.726",
        "UTM Koord. Hoch": "5933862.51",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Goldkoppel",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H16057",
        "UTM Koord. Rechts": "573814.81",
        "UTM Koord. Hoch": "5933746.619",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Goldkoppel",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H14337",
        "UTM Koord. Rechts": "574110.032",
        "UTM Koord. Hoch": "5932755.17",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schleemkoppel",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16118",
        "UTM Koord. Rechts": "573888.243",
        "UTM Koord. Hoch": "5933537.325",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Höhe",
        "HsNr.": "47"
    },
    {
        "Hydranten Nr": "H21268",
        "UTM Koord. Rechts": "573334.666",
        "UTM Koord. Hoch": "5931961.366",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "105"
    },
    {
        "Hydranten Nr": "H10885",
        "UTM Koord. Rechts": "575889.711",
        "UTM Koord. Hoch": "5933735.687",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Barsbütteler Weg",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H10883",
        "UTM Koord. Rechts": "575884.013",
        "UTM Koord. Hoch": "5933704.97",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Barsbütteler Weg",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H10833",
        "UTM Koord. Rechts": "575679.165",
        "UTM Koord. Hoch": "5933664.615",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Koolbarg",
        "HsNr.": "32"
    },
    {
        "Hydranten Nr": "H10843",
        "UTM Koord. Rechts": "575405.014",
        "UTM Koord. Hoch": "5933710.498",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Koolbargenredder",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H7760",
        "UTM Koord. Rechts": "576438.013",
        "UTM Koord. Hoch": "5931129.2",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Heideblöck",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H11000",
        "UTM Koord. Rechts": "574936.824",
        "UTM Koord. Hoch": "5933369.466",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kampmoortwiete",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H10340",
        "UTM Koord. Rechts": "576113.031",
        "UTM Koord. Hoch": "5932815.874",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kampener Stieg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H8903",
        "UTM Koord. Rechts": "576149.041",
        "UTM Koord. Hoch": "5932955.014",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Keitumer Weg",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H16185",
        "UTM Koord. Rechts": "573184.16",
        "UTM Koord. Hoch": "5933384.666",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H16111",
        "UTM Koord. Rechts": "573831.339",
        "UTM Koord. Hoch": "5933526.081",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Höhe",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H16079",
        "UTM Koord. Rechts": "573816.966",
        "UTM Koord. Hoch": "5933553.692",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Höhe",
        "HsNr.": "43"
    },
    {
        "Hydranten Nr": "H18035",
        "UTM Koord. Rechts": "571492.964",
        "UTM Koord. Hoch": "5934363.589",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "O'Swaldstraße",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H16646",
        "UTM Koord. Rechts": "573253.804",
        "UTM Koord. Hoch": "5933795.086",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kattensteert",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H16597",
        "UTM Koord. Rechts": "573493.683",
        "UTM Koord. Hoch": "5934053.246",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "123"
    },
    {
        "Hydranten Nr": "H16599",
        "UTM Koord. Rechts": "573450.802",
        "UTM Koord. Hoch": "5933960.425",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "115"
    },
    {
        "Hydranten Nr": "H10887",
        "UTM Koord. Rechts": "575838.362",
        "UTM Koord. Hoch": "5933510.312",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Koolbarg",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H17153",
        "UTM Koord. Rechts": "572225.052",
        "UTM Koord. Hoch": "5933815.839",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Grosseweg",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H17174",
        "UTM Koord. Rechts": "571951.259",
        "UTM Koord. Hoch": "5934003.422",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hasencleverstraße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H5990",
        "UTM Koord. Rechts": "572458.193",
        "UTM Koord. Hoch": "5934482.009",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Audorfring",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H16493",
        "UTM Koord. Rechts": "574647.791",
        "UTM Koord. Hoch": "5932936.879",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Cottaweg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H16494",
        "UTM Koord. Rechts": "574560.8",
        "UTM Koord. Hoch": "5932946.937",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Cottaweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H12413",
        "UTM Koord. Rechts": "573651.153",
        "UTM Koord. Hoch": "5933895.087",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Merkatorweg",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H15843",
        "UTM Koord. Rechts": "574349.438",
        "UTM Koord. Hoch": "5932740.934",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "118"
    },
    {
        "Hydranten Nr": "H14315",
        "UTM Koord. Rechts": "574177.603",
        "UTM Koord. Hoch": "5932733.005",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "107"
    },
    {
        "Hydranten Nr": "H8948",
        "UTM Koord. Rechts": "576069.09",
        "UTM Koord. Hoch": "5931133.811",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "195"
    },
    {
        "Hydranten Nr": "H8954",
        "UTM Koord. Rechts": "575942.879",
        "UTM Koord. Hoch": "5931087.632",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Wilhelm-Lehmbruck-Straße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H8797",
        "UTM Koord. Rechts": "575946.651",
        "UTM Koord. Hoch": "5931173.407",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Wilhelm-Lehmbruck-Straße",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H8298",
        "UTM Koord. Rechts": "576018.05",
        "UTM Koord. Hoch": "5931300.884",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Godenwind",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H8922",
        "UTM Koord. Rechts": "576050.149",
        "UTM Koord. Hoch": "5931547.482",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "44"
    },
    {
        "Hydranten Nr": "H17968",
        "UTM Koord. Rechts": "574702.682",
        "UTM Koord. Hoch": "5931899.583",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Markt",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H8282",
        "UTM Koord. Rechts": "576279.982",
        "UTM Koord. Hoch": "5931104.32",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Grenzdamm",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H7727",
        "UTM Koord. Rechts": "576486.006",
        "UTM Koord. Hoch": "5931067.737",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Grenzdamm",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H7773",
        "UTM Koord. Rechts": "576480.698",
        "UTM Koord. Hoch": "5931075.613",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rahewinkel",
        "HsNr.": "52"
    },
    {
        "Hydranten Nr": "H8997",
        "UTM Koord. Rechts": "576350.934",
        "UTM Koord. Hoch": "5931135.586",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Grenzdamm",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H17997",
        "UTM Koord. Rechts": "574922.255",
        "UTM Koord. Hoch": "5931815.784",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "108"
    },
    {
        "Hydranten Nr": "H18005",
        "UTM Koord. Rechts": "575025.39",
        "UTM Koord. Hoch": "5931738.114",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "120"
    },
    {
        "Hydranten Nr": "H17983",
        "UTM Koord. Rechts": "574949.079",
        "UTM Koord. Hoch": "5931971.076",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kapellenstraße",
        "HsNr.": "117"
    },
    {
        "Hydranten Nr": "H16078",
        "UTM Koord. Rechts": "573731.187",
        "UTM Koord. Hoch": "5933678.559",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Höhe",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H16921",
        "UTM Koord. Rechts": "573135.081",
        "UTM Koord. Hoch": "5933772.337",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Ihlestraße",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H14321",
        "UTM Koord. Rechts": "573994.941",
        "UTM Koord. Hoch": "5932701.294",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "89"
    },
    {
        "Hydranten Nr": "H15856",
        "UTM Koord. Rechts": "573916.367",
        "UTM Koord. Hoch": "5932689.457",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kapellenstraße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16076",
        "UTM Koord. Rechts": "573615.263",
        "UTM Koord. Hoch": "5933695.984",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Höhe",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H18398",
        "UTM Koord. Rechts": "572312.159",
        "UTM Koord. Hoch": "5933373.511",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "407"
    },
    {
        "Hydranten Nr": "H18387",
        "UTM Koord. Rechts": "572255.705",
        "UTM Koord. Hoch": "5933417.122",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "389"
    },
    {
        "Hydranten Nr": "H11744",
        "UTM Koord. Rechts": "575678.423",
        "UTM Koord. Hoch": "5932759.003",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archsumer Weg",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H11743",
        "UTM Koord. Rechts": "575691.679",
        "UTM Koord. Hoch": "5932723.145",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archsumer Weg",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H11788",
        "UTM Koord. Rechts": "575017.092",
        "UTM Koord. Hoch": "5932870.164",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "172"
    },
    {
        "Hydranten Nr": "H16492",
        "UTM Koord. Rechts": "574714.8",
        "UTM Koord. Hoch": "5932908.618",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Merkenstraße",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H15832",
        "UTM Koord. Rechts": "574882.566",
        "UTM Koord. Hoch": "5932786.795",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Amrumer Knick",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H10916",
        "UTM Koord. Rechts": "575475.989",
        "UTM Koord. Hoch": "5933160.624",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "227"
    },
    {
        "Hydranten Nr": "H11725",
        "UTM Koord. Rechts": "575805.296",
        "UTM Koord. Hoch": "5932926.828",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "81"
    },
    {
        "Hydranten Nr": "H5462",
        "UTM Koord. Rechts": "575223.445",
        "UTM Koord. Hoch": "5932885.254",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Wegkoppel",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H5464",
        "UTM Koord. Rechts": "575263.768",
        "UTM Koord. Hoch": "5932809.573",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Wegkoppel",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H5465",
        "UTM Koord. Rechts": "575307.491",
        "UTM Koord. Hoch": "5932728.195",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Wegkoppel",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H17042",
        "UTM Koord. Rechts": "572221.279",
        "UTM Koord. Hoch": "5933986.338",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H16242",
        "UTM Koord. Rechts": "573874.704",
        "UTM Koord. Hoch": "5933378.627",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Michael-Hering-Weg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16337",
        "UTM Koord. Rechts": "573032.333",
        "UTM Koord. Hoch": "5933083.701",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H16898",
        "UTM Koord. Rechts": "573204.156",
        "UTM Koord. Hoch": "5933471.907",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "59"
    },
    {
        "Hydranten Nr": "H9566",
        "UTM Koord. Rechts": "575931.62",
        "UTM Koord. Hoch": "5932965.064",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Knivsbergweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16919",
        "UTM Koord. Rechts": "573165.967",
        "UTM Koord. Hoch": "5933677.453",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Ihlestraße",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H16922",
        "UTM Koord. Rechts": "573086.639",
        "UTM Koord. Hoch": "5933758.443",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Ihlestraße",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H15839",
        "UTM Koord. Rechts": "574614.358",
        "UTM Koord. Hoch": "5932780.52",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "140"
    },
    {
        "Hydranten Nr": "H15836",
        "UTM Koord. Rechts": "574752.522",
        "UTM Koord. Hoch": "5932800.1",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "150"
    },
    {
        "Hydranten Nr": "H15801",
        "UTM Koord. Rechts": "574009.833",
        "UTM Koord. Hoch": "5932566.191",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kapellenstraße",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H14327",
        "UTM Koord. Rechts": "574248.145",
        "UTM Koord. Hoch": "5933008.59",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Pergamentweg",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H16472",
        "UTM Koord. Rechts": "574381.632",
        "UTM Koord. Hoch": "5933024.196",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Pergamentweg",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H14322",
        "UTM Koord. Rechts": "573973.583",
        "UTM Koord. Hoch": "5932777.226",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kaspar-Siemers-Weg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H16109",
        "UTM Koord. Rechts": "573378.186",
        "UTM Koord. Hoch": "5933608.78",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Julius-Campe-Weg",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H16166",
        "UTM Koord. Rechts": "573362.1372",
        "UTM Koord. Hoch": "5933741.451",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "82"
    },
    {
        "Hydranten Nr": "H16901",
        "UTM Koord. Rechts": "573258.377",
        "UTM Koord. Hoch": "5933579.996",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "67"
    },
    {
        "Hydranten Nr": "H10899",
        "UTM Koord. Rechts": "575808.31",
        "UTM Koord. Hoch": "5933410.955",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Moosterhoop",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H14408",
        "UTM Koord. Rechts": "573032.926",
        "UTM Koord. Hoch": "5931619.647",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "83"
    },
    {
        "Hydranten Nr": "H12430",
        "UTM Koord. Rechts": "572320.457",
        "UTM Koord. Hoch": "5930041.871",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H19375",
        "UTM Koord. Rechts": "573750.132",
        "UTM Koord. Hoch": "5931634.36",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "317"
    },
    {
        "Hydranten Nr": "H11233",
        "UTM Koord. Rechts": "573762.596",
        "UTM Koord. Hoch": "5931720.982",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "309"
    },
    {
        "Hydranten Nr": "H11234",
        "UTM Koord. Rechts": "573720.384",
        "UTM Koord. Hoch": "5931820.321",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "301"
    },
    {
        "Hydranten Nr": "H17364",
        "UTM Koord. Rechts": "573830.928",
        "UTM Koord. Hoch": "5932061.533",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rote Brücke",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H21267",
        "UTM Koord. Rechts": "573301.93",
        "UTM Koord. Hoch": "5932052.401",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "91"
    },
    {
        "Hydranten Nr": "H16175",
        "UTM Koord. Rechts": "573229.396",
        "UTM Koord. Hoch": "5933475.695",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "52"
    },
    {
        "Hydranten Nr": "H16130",
        "UTM Koord. Rechts": "573841.633",
        "UTM Koord. Hoch": "5933483.647",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Weg",
        "HsNr.": "58"
    },
    {
        "Hydranten Nr": "H11783",
        "UTM Koord. Rechts": "575088.217",
        "UTM Koord. Hoch": "5932926.804",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Steinkamp",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H11801",
        "UTM Koord. Rechts": "575016.47",
        "UTM Koord. Hoch": "5932633.809",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Landjägerstieg",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H11789",
        "UTM Koord. Rechts": "574928.738",
        "UTM Koord. Hoch": "5932854.1",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "175"
    },
    {
        "Hydranten Nr": "H11767",
        "UTM Koord. Rechts": "575315.546",
        "UTM Koord. Hoch": "5933034.709",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "207"
    },
    {
        "Hydranten Nr": "H9558",
        "UTM Koord. Rechts": "576021.924",
        "UTM Koord. Hoch": "5932783.855",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Knivsbergweg",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H16125",
        "UTM Koord. Rechts": "573256.828",
        "UTM Koord. Hoch": "5933299.854",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sandkoppelweg",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H12769",
        "UTM Koord. Rechts": "573870.479",
        "UTM Koord. Hoch": "5933891.19",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Braunstieg",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H10641",
        "UTM Koord. Rechts": "576082.699",
        "UTM Koord. Hoch": "5933062.986",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "112"
    },
    {
        "Hydranten Nr": "H10995",
        "UTM Koord. Rechts": "575089.669",
        "UTM Koord. Hoch": "5933214.002",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Heinrich-Schulte-Höhe",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H11120",
        "UTM Koord. Rechts": "575593.757",
        "UTM Koord. Hoch": "5933553.16",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Koolbarg",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H11121",
        "UTM Koord. Rechts": "575565.467",
        "UTM Koord. Hoch": "5933558.038",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Koolbarg",
        "HsNr.": "37"
    },
    {
        "Hydranten Nr": "H10943",
        "UTM Koord. Rechts": "575850.837",
        "UTM Koord. Hoch": "5933175.664",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kaltenbergen",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H16298",
        "UTM Koord. Rechts": "573646.763",
        "UTM Koord. Hoch": "5932464.321",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "109"
    },
    {
        "Hydranten Nr": "H16307",
        "UTM Koord. Rechts": "573682.807",
        "UTM Koord. Hoch": "5932416.369",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "120"
    },
    {
        "Hydranten Nr": "H16217",
        "UTM Koord. Rechts": "573214.282",
        "UTM Koord. Hoch": "5933137.962",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Lorenzenweg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H16904",
        "UTM Koord. Rechts": "573008.992",
        "UTM Koord. Hoch": "5933099.665",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H16279",
        "UTM Koord. Rechts": "573696.92",
        "UTM Koord. Hoch": "5932477.278",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Schanze",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H16284",
        "UTM Koord. Rechts": "573671.053",
        "UTM Koord. Hoch": "5932543.774",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Spökelbargring",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H16155",
        "UTM Koord. Rechts": "573497.962",
        "UTM Koord. Hoch": "5933453.293",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Masurenweg",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H16101",
        "UTM Koord. Rechts": "573737.058",
        "UTM Koord. Hoch": "5933416.865",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Julius-Campe-Weg",
        "HsNr.": "37"
    },
    {
        "Hydranten Nr": "H10939",
        "UTM Koord. Rechts": "575820.917",
        "UTM Koord. Hoch": "5933250.294",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kaltenbergen",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H14323",
        "UTM Koord. Rechts": "573994.207",
        "UTM Koord. Hoch": "5932823.02",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kaspar-Siemers-Weg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H14325",
        "UTM Koord. Rechts": "574125.731",
        "UTM Koord. Hoch": "5932938.432",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kaspar-Siemers-Weg",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H14324",
        "UTM Koord. Rechts": "574024.675",
        "UTM Koord. Hoch": "5932871.811",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kaspar-Siemers-Stieg",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H16289",
        "UTM Koord. Rechts": "573609.391",
        "UTM Koord. Hoch": "5932541.023",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Spökelbarg",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H16274",
        "UTM Koord. Rechts": "573752.585",
        "UTM Koord. Hoch": "5932512.507",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schleemer Weg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H9561",
        "UTM Koord. Rechts": "575974.713",
        "UTM Koord. Hoch": "5932870.226",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Knivsbergweg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H15827",
        "UTM Koord. Rechts": "574802.355",
        "UTM Koord. Hoch": "5932612.388",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Marktstraße",
        "HsNr.": "69"
    },
    {
        "Hydranten Nr": "H16931",
        "UTM Koord. Rechts": "572927.853",
        "UTM Koord. Hoch": "5933186.084",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kirchlinden",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H16152",
        "UTM Koord. Rechts": "573532.607",
        "UTM Koord. Hoch": "5933387.407",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Ruhmkoppel",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H16039",
        "UTM Koord. Rechts": "573848.773",
        "UTM Koord. Hoch": "5933667.937",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Karl-Strutz-Weg",
        "HsNr.": "43"
    },
    {
        "Hydranten Nr": "H16040",
        "UTM Koord. Rechts": "573765.642",
        "UTM Koord. Hoch": "5933707.147",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Karl-Strutz-Weg",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H16044",
        "UTM Koord. Rechts": "573550.87",
        "UTM Koord. Hoch": "5933808.581",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Karl-Strutz-Weg",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H11806",
        "UTM Koord. Rechts": "574956.805",
        "UTM Koord. Hoch": "5932757.607",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Wittdüner Weg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H11799",
        "UTM Koord. Rechts": "575182.589",
        "UTM Koord. Hoch": "5932567.299",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H11809",
        "UTM Koord. Rechts": "575422.022",
        "UTM Koord. Hoch": "5932578.762",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sonnenland",
        "HsNr.": "58"
    },
    {
        "Hydranten Nr": "H11810",
        "UTM Koord. Rechts": "575477.852",
        "UTM Koord. Hoch": "5932573.132",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sonnenland",
        "HsNr.": "79"
    },
    {
        "Hydranten Nr": "H10923",
        "UTM Koord. Rechts": "575649.472",
        "UTM Koord. Hoch": "5933429.915",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Koolbarg",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H10924",
        "UTM Koord. Rechts": "575660.711",
        "UTM Koord. Hoch": "5933523.404",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Koolbarg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H10966",
        "UTM Koord. Rechts": "575414.207",
        "UTM Koord. Hoch": "5933534.353",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kantatenweg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H1002",
        "UTM Koord. Rechts": "573653.058",
        "UTM Koord. Hoch": "5933129.263",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hollestraße",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H13523",
        "UTM Koord. Rechts": "572905.317",
        "UTM Koord. Hoch": "5930506.625",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Alter Landweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H16520",
        "UTM Koord. Rechts": "574657.656",
        "UTM Koord. Hoch": "5933827.767",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mattkamp",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H15970",
        "UTM Koord. Rechts": "574070.793",
        "UTM Koord. Hoch": "5934818.627",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "145"
    },
    {
        "Hydranten Nr": "H16052",
        "UTM Koord. Rechts": "573751.951",
        "UTM Koord. Hoch": "5933851.282",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Leysahtbogen",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H16053",
        "UTM Koord. Rechts": "573836.612",
        "UTM Koord. Hoch": "5933803.136",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Leysahtbogen",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H15823",
        "UTM Koord. Rechts": "574388.954",
        "UTM Koord. Hoch": "5932657.435",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Setzergasse",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H15825",
        "UTM Koord. Rechts": "574425.706",
        "UTM Koord. Hoch": "5932730.125",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Setzergasse",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H10920",
        "UTM Koord. Rechts": "575664.963",
        "UTM Koord. Hoch": "5933343.623",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "261"
    },
    {
        "Hydranten Nr": "H18462",
        "UTM Koord. Rechts": "575263.478",
        "UTM Koord. Hoch": "5933628.038",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rodeweg",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H11118",
        "UTM Koord. Rechts": "575622.516",
        "UTM Koord. Hoch": "5933545.574",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Koolbarg",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H11776",
        "UTM Koord. Rechts": "575344.39",
        "UTM Koord. Hoch": "5932849.729",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schümannweg",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H14262",
        "UTM Koord. Rechts": "573917.848",
        "UTM Koord. Hoch": "5932198.754",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Mühlenweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H14275",
        "UTM Koord. Rechts": "573901.031",
        "UTM Koord. Hoch": "5932556.623",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Schleemer Mühle",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H14276",
        "UTM Koord. Rechts": "573936.124",
        "UTM Koord. Hoch": "5932517.888",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schleemer Ring",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H15693",
        "UTM Koord. Rechts": "571683.504",
        "UTM Koord. Hoch": "5934788.402",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "53"
    },
    {
        "Hydranten Nr": "H8768",
        "UTM Koord. Rechts": "575750.224",
        "UTM Koord. Hoch": "5931335.501",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "August-Macke-Weg",
        "HsNr.": "61"
    },
    {
        "Hydranten Nr": "H6426",
        "UTM Koord. Rechts": "573820.486",
        "UTM Koord. Hoch": "5935463.791",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Geißleinweg",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H7985",
        "UTM Koord. Rechts": "573837.238",
        "UTM Koord. Hoch": "5935194.223",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sterntalerstraße",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H13172",
        "UTM Koord. Rechts": "570865.949",
        "UTM Koord. Hoch": "5931284.722",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H9022",
        "UTM Koord. Rechts": "575585.909",
        "UTM Koord. Hoch": "5932175.309",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "77"
    },
    {
        "Hydranten Nr": "H9023",
        "UTM Koord. Rechts": "575636.001",
        "UTM Koord. Hoch": "5932189.804",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "85"
    },
    {
        "Hydranten Nr": "H12255",
        "UTM Koord. Rechts": "571534.274",
        "UTM Koord. Hoch": "5930422.876",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H19384",
        "UTM Koord. Rechts": "573761.196",
        "UTM Koord. Hoch": "5934272.615",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Merkatorweg",
        "HsNr.": "50"
    },
    {
        "Hydranten Nr": "H8299",
        "UTM Koord. Rechts": "575888.732",
        "UTM Koord. Hoch": "5931301.376",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Godenwind",
        "HsNr.": "56"
    },
    {
        "Hydranten Nr": "H8301",
        "UTM Koord. Rechts": "575785.069",
        "UTM Koord. Hoch": "5931301.205",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Godenwind",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H8789",
        "UTM Koord. Rechts": "575685.378",
        "UTM Koord. Hoch": "5931310.758",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Godenwind",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H15762",
        "UTM Koord. Rechts": "572458.161",
        "UTM Koord. Hoch": "5929980.674",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Feldhofe",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H12226",
        "UTM Koord. Rechts": "572062.377",
        "UTM Koord. Hoch": "5929885.05",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "51"
    },
    {
        "Hydranten Nr": "H8766",
        "UTM Koord. Rechts": "575686.678",
        "UTM Koord. Hoch": "5931331.031",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "August-Macke-Weg",
        "HsNr.": "46"
    },
    {
        "Hydranten Nr": "H8771",
        "UTM Koord. Rechts": "575748.897",
        "UTM Koord. Hoch": "5931370.848",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "August-Macke-Weg",
        "HsNr.": "49"
    },
    {
        "Hydranten Nr": "H8773",
        "UTM Koord. Rechts": "575749.019",
        "UTM Koord. Hoch": "5931401.277",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "August-Macke-Weg",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H8775",
        "UTM Koord. Rechts": "575658.313",
        "UTM Koord. Hoch": "5931405.422",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "August-Macke-Weg",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H8780",
        "UTM Koord. Rechts": "575741.945",
        "UTM Koord. Hoch": "5931466.923",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "August-Macke-Weg",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H8782",
        "UTM Koord. Rechts": "575657.837",
        "UTM Koord. Hoch": "5931473.457",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "August-Macke-Weg",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H7776",
        "UTM Koord. Rechts": "576315.794",
        "UTM Koord. Hoch": "5931339.662",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Bienenbusch",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H7768",
        "UTM Koord. Rechts": "576561.019",
        "UTM Koord. Hoch": "5931379.976",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rahewinkel",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H7769",
        "UTM Koord. Rechts": "576560.727",
        "UTM Koord. Hoch": "5931280.906",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rahewinkel",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H7772",
        "UTM Koord. Rechts": "576560.129",
        "UTM Koord. Hoch": "5931078.833",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rahewinkel",
        "HsNr.": "61"
    },
    {
        "Hydranten Nr": "H7780",
        "UTM Koord. Rechts": "576284.588",
        "UTM Koord. Hoch": "5931397.791",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kandinskyallee",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H7793",
        "UTM Koord. Rechts": "576376.495",
        "UTM Koord. Hoch": "5931417.562",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rahewinkel",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H7792",
        "UTM Koord. Rechts": "576376.709",
        "UTM Koord. Hoch": "5931510.895",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Lietbargredder",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H8801",
        "UTM Koord. Rechts": "576112.772",
        "UTM Koord. Hoch": "5931422.447",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Paul-Klee-Straße",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H11753",
        "UTM Koord. Rechts": "575666.702",
        "UTM Koord. Hoch": "5932996.111",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Tinnumer Weg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H8253",
        "UTM Koord. Rechts": "576497.749",
        "UTM Koord. Hoch": "5932190.73",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Am Mühlenbach",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H8256",
        "UTM Koord. Rechts": "576497.607",
        "UTM Koord. Hoch": "5932094.613",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Am Mühlenbach",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H16574",
        "UTM Koord. Rechts": "573561.983",
        "UTM Koord. Hoch": "5934567.003",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Froschkönigweg",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H7996",
        "UTM Koord. Rechts": "573776.123",
        "UTM Koord. Hoch": "5934909.521",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "140"
    },
    {
        "Hydranten Nr": "H16611",
        "UTM Koord. Rechts": "573745.507",
        "UTM Koord. Hoch": "5934946.315",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "134"
    },
    {
        "Hydranten Nr": "H16571",
        "UTM Koord. Rechts": "573690.327",
        "UTM Koord. Hoch": "5934580.221",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Goldelsestieg",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H16572",
        "UTM Koord. Rechts": "573606.324",
        "UTM Koord. Hoch": "5934623.493",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Goldelsestieg",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H8787",
        "UTM Koord. Rechts": "575704.283",
        "UTM Koord. Hoch": "5931542.194",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "H8784",
        "UTM Koord. Rechts": "575740.638",
        "UTM Koord. Hoch": "5931502.77",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "August-Macke-Weg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H8273",
        "UTM Koord. Rechts": "576609.532",
        "UTM Koord. Hoch": "5930672.45",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Lohbrügge",
        "Straßenname": "Asbrookdamm",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H16668",
        "UTM Koord. Rechts": "573435.094",
        "UTM Koord. Hoch": "5934900.75",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Everlingweg",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H16821",
        "UTM Koord. Rechts": "573252.966",
        "UTM Koord. Hoch": "5934826.262",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Dahrendorfweg",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H10975",
        "UTM Koord. Rechts": "575389.67",
        "UTM Koord. Hoch": "5933352.003",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Höhe",
        "HsNr.": "66"
    },
    {
        "Hydranten Nr": "H10977",
        "UTM Koord. Rechts": "575278.525",
        "UTM Koord. Hoch": "5933316.775",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Höhe",
        "HsNr.": "56"
    },
    {
        "Hydranten Nr": "H10853",
        "UTM Koord. Rechts": "575044.121",
        "UTM Koord. Hoch": "5933665.762",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Salomon-Petri-Ring",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H10856",
        "UTM Koord. Rechts": "575117.274",
        "UTM Koord. Hoch": "5933663.773",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Salomon-Petri-Ring",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H10857",
        "UTM Koord. Rechts": "575031.175",
        "UTM Koord. Hoch": "5933628.718",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Salomon-Petri-Ring",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H8790",
        "UTM Koord. Rechts": "575598.855",
        "UTM Koord. Hoch": "5931393.944",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Godenwind",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H8792",
        "UTM Koord. Rechts": "575578.664",
        "UTM Koord. Hoch": "5931421.783",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Godenwind",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H8794",
        "UTM Koord. Rechts": "575510.106",
        "UTM Koord. Hoch": "5931483.619",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Godenwind",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H8973",
        "UTM Koord. Rechts": "575280.841",
        "UTM Koord. Hoch": "5931523.524",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "154"
    },
    {
        "Hydranten Nr": "H8958",
        "UTM Koord. Rechts": "575675.995",
        "UTM Koord. Hoch": "5931225.223",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "167"
    },
    {
        "Hydranten Nr": "H8957",
        "UTM Koord. Rechts": "575737.744",
        "UTM Koord. Hoch": "5931190.609",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "171"
    },
    {
        "Hydranten Nr": "H16680",
        "UTM Koord. Rechts": "573132.185",
        "UTM Koord. Hoch": "5934971.966",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "86"
    },
    {
        "Hydranten Nr": "H16691",
        "UTM Koord. Rechts": "573248.535",
        "UTM Koord. Hoch": "5934656.39",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bleckering",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16686",
        "UTM Koord. Rechts": "573287.349",
        "UTM Koord. Hoch": "5934755.86",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Legienstraße",
        "HsNr.": "233"
    },
    {
        "Hydranten Nr": "H16677",
        "UTM Koord. Rechts": "573279.714",
        "UTM Koord. Hoch": "5934855.371",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Legienstraße",
        "HsNr.": "239"
    },
    {
        "Hydranten Nr": "H12800",
        "UTM Koord. Rechts": "572065.804",
        "UTM Koord. Hoch": "5931469.617",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H16995",
        "UTM Koord. Rechts": "572713.724",
        "UTM Koord. Hoch": "5933985.611",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Vierbergen",
        "HsNr.": "46"
    },
    {
        "Hydranten Nr": "H14410",
        "UTM Koord. Rechts": "572934.405",
        "UTM Koord. Hoch": "5931648.648",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "77"
    },
    {
        "Hydranten Nr": "H7818",
        "UTM Koord. Rechts": "576376.071",
        "UTM Koord. Hoch": "5932128.477",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hollkoppelweg",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H7819",
        "UTM Koord. Rechts": "576432.521",
        "UTM Koord. Hoch": "5932159.273",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hollkoppelweg",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H9024",
        "UTM Koord. Rechts": "575668.459",
        "UTM Koord. Hoch": "5932209.846",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "87"
    },
    {
        "Hydranten Nr": "H9025",
        "UTM Koord. Rechts": "575689.351",
        "UTM Koord. Hoch": "5932147.932",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "103"
    },
    {
        "Hydranten Nr": "H9083",
        "UTM Koord. Rechts": "575806.169",
        "UTM Koord. Hoch": "5932288.085",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Max-Pechstein-Straße",
        "HsNr.": "47"
    },
    {
        "Hydranten Nr": "H9038",
        "UTM Koord. Rechts": "575822.121",
        "UTM Koord. Hoch": "5932106.52",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Edvard-Munch-Straße",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H8970",
        "UTM Koord. Rechts": "575308.977",
        "UTM Koord. Hoch": "5931497.283",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "156"
    },
    {
        "Hydranten Nr": "H8975",
        "UTM Koord. Rechts": "575372.757",
        "UTM Koord. Hoch": "5931469.003",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "133"
    },
    {
        "Hydranten Nr": "H17979",
        "UTM Koord. Rechts": "574922.199",
        "UTM Koord. Hoch": "5932049.369",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sonnenland",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H18867",
        "UTM Koord. Rechts": "575210.539",
        "UTM Koord. Hoch": "5931851.446",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H9013",
        "UTM Koord. Rechts": "575663.129",
        "UTM Koord. Hoch": "5932024.091",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "62"
    },
    {
        "Hydranten Nr": "H9004",
        "UTM Koord. Rechts": "575487.044",
        "UTM Koord. Hoch": "5932004.675",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H9002",
        "UTM Koord. Rechts": "575569.571",
        "UTM Koord. Hoch": "5931922.874",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H9011",
        "UTM Koord. Rechts": "575642.736",
        "UTM Koord. Hoch": "5931998.08",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "38"
    },
    {
        "Hydranten Nr": "H8812",
        "UTM Koord. Rechts": "575871.712",
        "UTM Koord. Hoch": "5931530.842",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H8000",
        "UTM Koord. Rechts": "573923.977",
        "UTM Koord. Hoch": "5935067.74",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Similiberg",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H8279",
        "UTM Koord. Rechts": "576757.544",
        "UTM Koord. Hoch": "5930626.052",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Lohbrügge",
        "Straßenname": "Asbrookdamm",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H16136",
        "UTM Koord. Rechts": "573634.132",
        "UTM Koord. Hoch": "5933244.02",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Weg",
        "HsNr.": "37"
    },
    {
        "Hydranten Nr": "H9034",
        "UTM Koord. Rechts": "575874.166",
        "UTM Koord. Hoch": "5931879.627",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Edvard-Munch-Straße",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H8987",
        "UTM Koord. Rechts": "575321.711",
        "UTM Koord. Hoch": "5931723.688",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hummelweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H8990",
        "UTM Koord. Rechts": "575521.78",
        "UTM Koord. Hoch": "5931732.517",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hummelweg",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H8983",
        "UTM Koord. Rechts": "575392.215",
        "UTM Koord. Hoch": "5931602.068",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Haseneck",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H8993",
        "UTM Koord. Rechts": "575826.5",
        "UTM Koord. Hoch": "5931669.949",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Max-Klinger-Straße",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H18357",
        "UTM Koord. Rechts": "572412.864",
        "UTM Koord. Hoch": "5933154.714",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Am Schiffbeker Berg",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H10711",
        "UTM Koord. Rechts": "573529.15",
        "UTM Koord. Hoch": "5931688.476",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "101"
    },
    {
        "Hydranten Nr": "H10714",
        "UTM Koord. Rechts": "573563.267",
        "UTM Koord. Hoch": "5931599.257",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "103"
    },
    {
        "Hydranten Nr": "H16428",
        "UTM Koord. Rechts": "573664.837",
        "UTM Koord. Hoch": "5934743.758",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Däumlingtwiete",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H16612",
        "UTM Koord. Rechts": "573532.406",
        "UTM Koord. Hoch": "5934627.65",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Goldelsestieg",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H16685",
        "UTM Koord. Rechts": "573427.812",
        "UTM Koord. Hoch": "5935023.311",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "108"
    },
    {
        "Hydranten Nr": "H17031",
        "UTM Koord. Rechts": "572522.008",
        "UTM Koord. Hoch": "5933438.494",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "97"
    },
    {
        "Hydranten Nr": "H17022",
        "UTM Koord. Rechts": "572627.713",
        "UTM Koord. Hoch": "5933346.876",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "103"
    },
    {
        "Hydranten Nr": "H18427",
        "UTM Koord. Rechts": "572294.269",
        "UTM Koord. Hoch": "5933077.707",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kolumbusstraße",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H7605",
        "UTM Koord. Rechts": "572752.407",
        "UTM Koord. Hoch": "5931267.396",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H7614",
        "UTM Koord. Rechts": "572550.584",
        "UTM Koord. Hoch": "5931326.914",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H13085",
        "UTM Koord. Rechts": "571902.394",
        "UTM Koord. Hoch": "5931117.288",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H10979",
        "UTM Koord. Rechts": "575310.924",
        "UTM Koord. Hoch": "5933283.961",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Höhe",
        "HsNr.": "56"
    },
    {
        "Hydranten Nr": "H10980",
        "UTM Koord. Rechts": "575186.387",
        "UTM Koord. Hoch": "5933287.625",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Höhe",
        "HsNr.": "44"
    },
    {
        "Hydranten Nr": "H10992",
        "UTM Koord. Rechts": "575016.54",
        "UTM Koord. Hoch": "5933323.935",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Glitzaweg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H9997",
        "UTM Koord. Rechts": "576133.076",
        "UTM Koord. Hoch": "5932711.732",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Lister Weg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H10962",
        "UTM Koord. Rechts": "575355.256",
        "UTM Koord. Hoch": "5933552.812",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Bonhoefferstraße",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H11822",
        "UTM Koord. Rechts": "575389.154",
        "UTM Koord. Hoch": "5932373.82",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Glinder Au",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H11823",
        "UTM Koord. Rechts": "575300.597",
        "UTM Koord. Hoch": "5932320.036",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Glinder Au",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H10996",
        "UTM Koord. Rechts": "575136.857",
        "UTM Koord. Hoch": "5933129.757",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Heinrich-Schulte-Höhe",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H10964",
        "UTM Koord. Rechts": "575358.893",
        "UTM Koord. Hoch": "5933482.753",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Bonhoefferstraße",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H10999",
        "UTM Koord. Rechts": "574925.895",
        "UTM Koord. Hoch": "5933292.038",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kampmoortwiete",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H10960",
        "UTM Koord. Rechts": "575573.434",
        "UTM Koord. Hoch": "5933528.85",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Ohlwurt",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H10922",
        "UTM Koord. Rechts": "575555.457",
        "UTM Koord. Hoch": "5933409.983",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Breedenweg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H16640",
        "UTM Koord. Rechts": "573206.964",
        "UTM Koord. Hoch": "5933787.231",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kattensteert",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H6462",
        "UTM Koord. Rechts": "574078.692",
        "UTM Koord. Hoch": "5935236.96",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rosenrotweg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H9067",
        "UTM Koord. Rechts": "576068.581",
        "UTM Koord. Hoch": "5931805.659",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oskar-Schlemmer-Straße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H7754",
        "UTM Koord. Rechts": "576203.065",
        "UTM Koord. Hoch": "5931830.812",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kandinskyallee",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H8258",
        "UTM Koord. Rechts": "576575.185",
        "UTM Koord. Hoch": "5931034.921",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Grenzdamm",
        "HsNr.": "48"
    },
    {
        "Hydranten Nr": "H8939",
        "UTM Koord. Rechts": "575769.462",
        "UTM Koord. Hoch": "5931564.35",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "H11938",
        "UTM Koord. Rechts": "572262.162",
        "UTM Koord. Hoch": "5931413.068",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H17108",
        "UTM Koord. Rechts": "572314.593",
        "UTM Koord. Hoch": "5933781.514",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "68"
    },
    {
        "Hydranten Nr": "H18275",
        "UTM Koord. Rechts": "571911.692",
        "UTM Koord. Hoch": "5933963.927",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Rampe",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H18280",
        "UTM Koord. Rechts": "571921.937",
        "UTM Koord. Hoch": "5933939.975",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Rampe",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H16997",
        "UTM Koord. Rechts": "572648.323",
        "UTM Koord. Hoch": "5933887.473",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Vierbergen",
        "HsNr.": "57"
    },
    {
        "Hydranten Nr": "H17137",
        "UTM Koord. Rechts": "572221.071",
        "UTM Koord. Hoch": "5933737.957",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Auersreihe",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H17133",
        "UTM Koord. Rechts": "572217.002",
        "UTM Koord. Hoch": "5933658.486",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Blostwiete",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H17134",
        "UTM Koord. Rechts": "572279.036",
        "UTM Koord. Hoch": "5933703.661",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Grosseweg",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H16020",
        "UTM Koord. Rechts": "573630.04",
        "UTM Koord. Hoch": "5934276.274",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "136"
    },
    {
        "Hydranten Nr": "H16817",
        "UTM Koord. Rechts": "572942.018",
        "UTM Koord. Hoch": "5934591.742",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Querkamp",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H16665",
        "UTM Koord. Rechts": "573109.73",
        "UTM Koord. Hoch": "5934331.041",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Legienstraße",
        "HsNr.": "160"
    },
    {
        "Hydranten Nr": "H16664",
        "UTM Koord. Rechts": "573156.602",
        "UTM Koord. Hoch": "5934420.014",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Legienstraße",
        "HsNr.": "164"
    },
    {
        "Hydranten Nr": "H16662",
        "UTM Koord. Rechts": "573201.404",
        "UTM Koord. Hoch": "5934513.277",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Legienstraße",
        "HsNr.": "166"
    },
    {
        "Hydranten Nr": "H16661",
        "UTM Koord. Rechts": "573223.824",
        "UTM Koord. Hoch": "5934559.048",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Legienstraße",
        "HsNr.": "172"
    },
    {
        "Hydranten Nr": "H6495",
        "UTM Koord. Rechts": "573690.874",
        "UTM Koord. Hoch": "5935038.714",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "127"
    },
    {
        "Hydranten Nr": "H6498",
        "UTM Koord. Rechts": "573750.507",
        "UTM Koord. Hoch": "5935008.419",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Silberberg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H7411",
        "UTM Koord. Rechts": "573394.949",
        "UTM Koord. Hoch": "5935046.213",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "117"
    },
    {
        "Hydranten Nr": "H6831",
        "UTM Koord. Rechts": "572969.208",
        "UTM Koord. Hoch": "5934910.83",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "76"
    },
    {
        "Hydranten Nr": "H7409",
        "UTM Koord. Rechts": "573290.257",
        "UTM Koord. Hoch": "5935036.969",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "109"
    },
    {
        "Hydranten Nr": "H6449",
        "UTM Koord. Rechts": "574123.786",
        "UTM Koord. Hoch": "5935370.492",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rotkäppchenweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H15935",
        "UTM Koord. Rechts": "574119.143",
        "UTM Koord. Hoch": "5935253.244",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergredder",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H18376",
        "UTM Koord. Rechts": "572087.707",
        "UTM Koord. Hoch": "5933561.175",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "361"
    },
    {
        "Hydranten Nr": "H12231",
        "UTM Koord. Rechts": "572147.445",
        "UTM Koord. Hoch": "5929796.476",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "61"
    },
    {
        "Hydranten Nr": "H8930",
        "UTM Koord. Rechts": "575683.481",
        "UTM Koord. Hoch": "5931544.082",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "H8917",
        "UTM Koord. Rechts": "575599.331",
        "UTM Koord. Hoch": "5931533.093",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H8810",
        "UTM Koord. Rechts": "575871.229",
        "UTM Koord. Hoch": "5931318.14",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Münterweg",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H17273",
        "UTM Koord. Rechts": "574215.074",
        "UTM Koord. Hoch": "5932031.606",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mehrenskamp",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H17989",
        "UTM Koord. Rechts": "574827.778",
        "UTM Koord. Hoch": "5931816.341",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "102"
    },
    {
        "Hydranten Nr": "H17729",
        "UTM Koord. Rechts": "574625.587",
        "UTM Koord. Hoch": "5931847.818",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "77"
    },
    {
        "Hydranten Nr": "H7762",
        "UTM Koord. Rechts": "576438.311",
        "UTM Koord. Hoch": "5931230.317",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Heideblöck",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H7763",
        "UTM Koord. Rechts": "576438.5",
        "UTM Koord. Hoch": "5931329.446",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Heideblöck",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H11764",
        "UTM Koord. Rechts": "575568.217",
        "UTM Koord. Hoch": "5932677.782",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinfurther Allee",
        "HsNr.": "52"
    },
    {
        "Hydranten Nr": "H11762",
        "UTM Koord. Rechts": "575419.845",
        "UTM Koord. Hoch": "5932959.409",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinfurther Allee",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H11759",
        "UTM Koord. Rechts": "575463.83",
        "UTM Koord. Hoch": "5932992.308",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinfurther Allee",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H10878",
        "UTM Koord. Rechts": "575458.305",
        "UTM Koord. Hoch": "5933618.409",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schlangenkoppel",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H10879",
        "UTM Koord. Rechts": "575365.695",
        "UTM Koord. Hoch": "5933622.964",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rodeweg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H10953",
        "UTM Koord. Rechts": "575460.49",
        "UTM Koord. Hoch": "5933449.582",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schlangenkoppel",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H7786",
        "UTM Koord. Rechts": "576314.535",
        "UTM Koord. Hoch": "5931702.899",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kleine Holl",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H8257",
        "UTM Koord. Rechts": "576497.602",
        "UTM Koord. Hoch": "5931995.298",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Am Mühlenbach",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H7817",
        "UTM Koord. Rechts": "576376.019",
        "UTM Koord. Hoch": "5932043.227",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hollkoppelweg",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H7815",
        "UTM Koord. Rechts": "576436.518",
        "UTM Koord. Hoch": "5931912.121",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Strietkoppel",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H7746",
        "UTM Koord. Rechts": "576207.985",
        "UTM Koord. Hoch": "5931594.721",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "59"
    },
    {
        "Hydranten Nr": "H9061",
        "UTM Koord. Rechts": "576144.124",
        "UTM Koord. Hoch": "5931673.068",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Feiningerstraße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H9062",
        "UTM Koord. Rechts": "576144.623",
        "UTM Koord. Hoch": "5931793.519",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Feiningerstraße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H7797",
        "UTM Koord. Rechts": "576557.924",
        "UTM Koord. Hoch": "5931740.317",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Große Holl",
        "HsNr.": "32"
    },
    {
        "Hydranten Nr": "H7812",
        "UTM Koord. Rechts": "576558.141",
        "UTM Koord. Hoch": "5931843.01",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Große Holl",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H7811",
        "UTM Koord. Rechts": "576558.247",
        "UTM Koord. Hoch": "5931943.544",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Große Holl",
        "HsNr.": "56"
    },
    {
        "Hydranten Nr": "H16709",
        "UTM Koord. Rechts": "572911.971",
        "UTM Koord. Hoch": "5934143.979",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Stegerwaldring",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H16693",
        "UTM Koord. Rechts": "573202.054",
        "UTM Koord. Hoch": "5934560.408",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Legienstraße",
        "HsNr.": "172"
    },
    {
        "Hydranten Nr": "H16694",
        "UTM Koord. Rechts": "573163.389",
        "UTM Koord. Hoch": "5934481.189",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Legienstraße",
        "HsNr.": "207"
    },
    {
        "Hydranten Nr": "H16695",
        "UTM Koord. Rechts": "573125.134",
        "UTM Koord. Hoch": "5934401.951",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Legienstraße",
        "HsNr.": "162"
    },
    {
        "Hydranten Nr": "H16434",
        "UTM Koord. Rechts": "573698.863",
        "UTM Koord. Hoch": "5934805.952",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Riesenweg",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H16759",
        "UTM Koord. Rechts": "573050.965",
        "UTM Koord. Hoch": "5934466.164",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Am Horner Moor",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H12269",
        "UTM Koord. Rechts": "571293.6",
        "UTM Koord. Hoch": "5930666.212",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H12260",
        "UTM Koord. Rechts": "571372.612",
        "UTM Koord. Hoch": "5930586.589",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H16688",
        "UTM Koord. Rechts": "573214.751",
        "UTM Koord. Hoch": "5934719.535",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bleckering",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H16689",
        "UTM Koord. Rechts": "573182.445",
        "UTM Koord. Hoch": "5934657.3",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bleckering",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H10881",
        "UTM Koord. Rechts": "575262.448",
        "UTM Koord. Hoch": "5933618.212",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rodeweg",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H18463",
        "UTM Koord. Rechts": "575214.357",
        "UTM Koord. Hoch": "5933653.677",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rodeweg",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H17163",
        "UTM Koord. Rechts": "572065.098",
        "UTM Koord. Hoch": "5933864.182",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bömelburgweg",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H18430",
        "UTM Koord. Rechts": "572379.354",
        "UTM Koord. Hoch": "5933027.309",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kolumbusstraße",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H18432",
        "UTM Koord. Rechts": "572459.711",
        "UTM Koord. Hoch": "5932971.752",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kolumbusstraße",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H12251",
        "UTM Koord. Rechts": "571636.695",
        "UTM Koord. Hoch": "5930289.595",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "37"
    },
    {
        "Hydranten Nr": "H5833",
        "UTM Koord. Rechts": "573096.145",
        "UTM Koord. Hoch": "5935034.254",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Ernst-Scherling-Weg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H5819",
        "UTM Koord. Rechts": "572920.666",
        "UTM Koord. Hoch": "5935048.231",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rudolf-Roß-Allee",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H917",
        "UTM Koord. Rechts": "572911.688",
        "UTM Koord. Hoch": "5934989.472",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rudolf-Roß-Allee",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H6832",
        "UTM Koord. Rechts": "573039.947",
        "UTM Koord. Hoch": "5934953.584",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "80"
    },
    {
        "Hydranten Nr": "H15934",
        "UTM Koord. Rechts": "574151.514",
        "UTM Koord. Hoch": "5935349.55",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dringsheide",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H8969",
        "UTM Koord. Rechts": "575372.387",
        "UTM Koord. Hoch": "5931440.842",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "164"
    },
    {
        "Hydranten Nr": "H7725",
        "UTM Koord. Rechts": "576624.507",
        "UTM Koord. Hoch": "5931036.59",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Grenzdamm",
        "HsNr.": "50"
    },
    {
        "Hydranten Nr": "H15878",
        "UTM Koord. Rechts": "571500.761",
        "UTM Koord. Hoch": "5931845.035",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H18355",
        "UTM Koord. Rechts": "572589.092",
        "UTM Koord. Hoch": "5933051.281",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Am Schiffbeker Berg",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "H12293",
        "UTM Koord. Rechts": "572824.603",
        "UTM Koord. Hoch": "5933016.595",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H10845",
        "UTM Koord. Rechts": "575068.356",
        "UTM Koord. Hoch": "5933757.985",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Reinskamp",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H10844",
        "UTM Koord. Rechts": "575092.647",
        "UTM Koord. Hoch": "5933799.988",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Reinskamp",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H17044",
        "UTM Koord. Rechts": "572435.485",
        "UTM Koord. Hoch": "5934038.414",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Stengelestraße",
        "HsNr.": "41"
    },
    {
        "Hydranten Nr": "H17043",
        "UTM Koord. Rechts": "572332.472",
        "UTM Koord. Hoch": "5933960.391",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Stengelestraße",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H17038",
        "UTM Koord. Rechts": "572300.292",
        "UTM Koord. Hoch": "5933863.105",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "65"
    },
    {
        "Hydranten Nr": "H17066",
        "UTM Koord. Rechts": "572279.901",
        "UTM Koord. Hoch": "5933896.982",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "54"
    },
    {
        "Hydranten Nr": "H9049",
        "UTM Koord. Rechts": "575698.14",
        "UTM Koord. Hoch": "5931827.543",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "76"
    },
    {
        "Hydranten Nr": "H9085",
        "UTM Koord. Rechts": "576021.92",
        "UTM Koord. Hoch": "5931825.803",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "78"
    },
    {
        "Hydranten Nr": "H9088",
        "UTM Koord. Rechts": "576090.095",
        "UTM Koord. Hoch": "5931829.77",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "75"
    },
    {
        "Hydranten Nr": "H8943",
        "UTM Koord. Rechts": "575991.851",
        "UTM Koord. Hoch": "5931566.367",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H10660",
        "UTM Koord. Rechts": "576070.313",
        "UTM Koord. Hoch": "5931568.515",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H9072",
        "UTM Koord. Rechts": "575947.436",
        "UTM Koord. Hoch": "5931797.216",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "72"
    },
    {
        "Hydranten Nr": "H7723",
        "UTM Koord. Rechts": "576646.449",
        "UTM Koord. Hoch": "5931044.176",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Grenzdamm",
        "HsNr.": "50"
    },
    {
        "Hydranten Nr": "H8263",
        "UTM Koord. Rechts": "576453.351",
        "UTM Koord. Hoch": "5930994.745",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Grenzdamm",
        "HsNr.": "32"
    },
    {
        "Hydranten Nr": "H18872",
        "UTM Koord. Rechts": "575172.146",
        "UTM Koord. Hoch": "5931727.497",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "H9007",
        "UTM Koord. Rechts": "575567.404",
        "UTM Koord. Hoch": "5932005.686",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H17964",
        "UTM Koord. Rechts": "574799.829",
        "UTM Koord. Hoch": "5931898.705",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Berg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H17969",
        "UTM Koord. Rechts": "574659.729",
        "UTM Koord. Hoch": "5931954.475",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Markt",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H20563",
        "UTM Koord. Rechts": "574710.478",
        "UTM Koord. Hoch": "5931912.649",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Markt",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H17375",
        "UTM Koord. Rechts": "574550.011",
        "UTM Koord. Hoch": "5931818.907",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Weg",
        "HsNr.": "56"
    },
    {
        "Hydranten Nr": "H17377",
        "UTM Koord. Rechts": "574633.894",
        "UTM Koord. Hoch": "5931804.512",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "81"
    },
    {
        "Hydranten Nr": "H8981",
        "UTM Koord. Rechts": "575311.393",
        "UTM Koord. Hoch": "5931676.904",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "119"
    },
    {
        "Hydranten Nr": "H17992",
        "UTM Koord. Rechts": "574958.939",
        "UTM Koord. Hoch": "5931784.325",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "112"
    },
    {
        "Hydranten Nr": "H10533",
        "UTM Koord. Rechts": "576066.63",
        "UTM Koord. Hoch": "5932910.001",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Keitumer Weg",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H10639",
        "UTM Koord. Rechts": "576260.363",
        "UTM Koord. Hoch": "5933021.186",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Keitumer Weg",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H10065",
        "UTM Koord. Rechts": "576233.138",
        "UTM Koord. Hoch": "5932766.552",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Lister Weg",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H10640",
        "UTM Koord. Rechts": "575993.858",
        "UTM Koord. Hoch": "5933013.867",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "100"
    },
    {
        "Hydranten Nr": "H18017",
        "UTM Koord. Rechts": "571716.324",
        "UTM Koord. Hoch": "5934340.661",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bauerberg",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H16432",
        "UTM Koord. Rechts": "573630.834",
        "UTM Koord. Hoch": "5934825.153",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Riesenweg",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H16441",
        "UTM Koord. Rechts": "573676.565",
        "UTM Koord. Hoch": "5934915.586",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Zwergenstieg",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H16430",
        "UTM Koord. Rechts": "573586.053",
        "UTM Koord. Hoch": "5934733.881",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Prinzenweg",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H16435",
        "UTM Koord. Rechts": "573778.9",
        "UTM Koord. Hoch": "5934765.911",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Riesenweg",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H12417",
        "UTM Koord. Rechts": "573676.768",
        "UTM Koord. Hoch": "5934111.5",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Meriandamm",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H13437",
        "UTM Koord. Rechts": "573746.384",
        "UTM Koord. Hoch": "5934219.895",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Merkatorweg",
        "HsNr.": "46"
    },
    {
        "Hydranten Nr": "H12428",
        "UTM Koord. Rechts": "572274.612",
        "UTM Koord. Hoch": "5929953.825",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H15936",
        "UTM Koord. Rechts": "574079.822",
        "UTM Koord. Hoch": "5935162.699",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rehkoppel",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H10959",
        "UTM Koord. Rechts": "575548.943",
        "UTM Koord. Hoch": "5933531.889",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Ohlwurt",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H10897",
        "UTM Koord. Rechts": "575835.003",
        "UTM Koord. Hoch": "5933497.518",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Moosterhoop",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H8805",
        "UTM Koord. Rechts": "575991.55",
        "UTM Koord. Hoch": "5931328.244",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Franz-Marc-Straße",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H8807",
        "UTM Koord. Rechts": "575992.459",
        "UTM Koord. Hoch": "5931527.69",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Franz-Marc-Straße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H8925",
        "UTM Koord. Rechts": "575938.919",
        "UTM Koord. Hoch": "5931546.525",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H11002",
        "UTM Koord. Rechts": "574993.18",
        "UTM Koord. Hoch": "5933104.823",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Steinkamp",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H10907",
        "UTM Koord. Rechts": "575750.92",
        "UTM Koord. Hoch": "5933334.848",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "269"
    },
    {
        "Hydranten Nr": "H8953",
        "UTM Koord. Rechts": "575578.351",
        "UTM Koord. Hoch": "5932056.157",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "47"
    },
    {
        "Hydranten Nr": "H9009",
        "UTM Koord. Rechts": "575526.21",
        "UTM Koord. Hoch": "5932055.817",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "53"
    },
    {
        "Hydranten Nr": "H8994",
        "UTM Koord. Rechts": "575826.799",
        "UTM Koord. Hoch": "5931779.225",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Max-Klinger-Straße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H7800",
        "UTM Koord. Rechts": "576315.121",
        "UTM Koord. Hoch": "5931910.627",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kleine Holl",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H7752",
        "UTM Koord. Rechts": "576207.966",
        "UTM Koord. Hoch": "5931730.156",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kandinskyallee",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H9042",
        "UTM Koord. Rechts": "575911.96",
        "UTM Koord. Hoch": "5931826.356",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "61"
    },
    {
        "Hydranten Nr": "H21271",
        "UTM Koord. Rechts": "573339.184",
        "UTM Koord. Hoch": "5931884.597",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "111"
    },
    {
        "Hydranten Nr": "H9046",
        "UTM Koord. Rechts": "575798.512",
        "UTM Koord. Hoch": "5931827.198",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "57"
    },
    {
        "Hydranten Nr": "H9053",
        "UTM Koord. Rechts": "575611.472",
        "UTM Koord. Hoch": "5931809.335",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "56"
    },
    {
        "Hydranten Nr": "H9056",
        "UTM Koord. Rechts": "575497.365",
        "UTM Koord. Hoch": "5931810.941",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "48"
    },
    {
        "Hydranten Nr": "H9060",
        "UTM Koord. Rechts": "575363.567",
        "UTM Koord. Hoch": "5931827.455",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H9059",
        "UTM Koord. Rechts": "575354.591",
        "UTM Koord. Hoch": "5931812.589",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H18869",
        "UTM Koord. Rechts": "575251.222",
        "UTM Koord. Hoch": "5931907.588",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H17999",
        "UTM Koord. Rechts": "575095.777",
        "UTM Koord. Hoch": "5931867.311",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H18871",
        "UTM Koord. Rechts": "575248.993",
        "UTM Koord. Hoch": "5931839.93",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H8999",
        "UTM Koord. Rechts": "575614.472",
        "UTM Koord. Hoch": "5931867.885",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H9000",
        "UTM Koord. Rechts": "575612.965",
        "UTM Koord. Hoch": "5931953.495",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H9027",
        "UTM Koord. Rechts": "575743.628",
        "UTM Koord. Hoch": "5931994.344",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "131"
    },
    {
        "Hydranten Nr": "H11828",
        "UTM Koord. Rechts": "575290.53",
        "UTM Koord. Hoch": "5932379.55",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dröögsiet",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H11827",
        "UTM Koord. Rechts": "575211.001",
        "UTM Koord. Hoch": "5932330.851",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dröögsiet",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H11003",
        "UTM Koord. Rechts": "574995.645",
        "UTM Koord. Hoch": "5933541.703",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Salomon-Petri-Ring",
        "HsNr.": "77"
    },
    {
        "Hydranten Nr": "H10928",
        "UTM Koord. Rechts": "575710.782",
        "UTM Koord. Hoch": "5933544.206",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Koolbarg",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H11119",
        "UTM Koord. Rechts": "575538.118",
        "UTM Koord. Hoch": "5933561.606",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Koolbarg",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H18364",
        "UTM Koord. Rechts": "571967.266",
        "UTM Koord. Hoch": "5933635.209",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "314"
    },
    {
        "Hydranten Nr": "H12259",
        "UTM Koord. Rechts": "571446.677",
        "UTM Koord. Hoch": "5930511.896",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H12252",
        "UTM Koord. Rechts": "571605.727",
        "UTM Koord. Hoch": "5930350.231",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "37"
    },
    {
        "Hydranten Nr": "H12250",
        "UTM Koord. Rechts": "571672.611",
        "UTM Koord. Hoch": "5930283.147",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "37"
    },
    {
        "Hydranten Nr": "H6438",
        "UTM Koord. Rechts": "574155.448",
        "UTM Koord. Hoch": "5935466.049",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Aschenputtelstraße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16767",
        "UTM Koord. Rechts": "573018.044",
        "UTM Koord. Hoch": "5934852.852",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Querkamp",
        "HsNr.": "69"
    },
    {
        "Hydranten Nr": "H16442",
        "UTM Koord. Rechts": "573762.799",
        "UTM Koord. Hoch": "5934914.229",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "138"
    },
    {
        "Hydranten Nr": "H16443",
        "UTM Koord. Rechts": "573837.608",
        "UTM Koord. Hoch": "5934875.787",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "146"
    },
    {
        "Hydranten Nr": "H12747",
        "UTM Koord. Rechts": "573596.468",
        "UTM Koord. Hoch": "5934163.489",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Meriandamm",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16579",
        "UTM Koord. Rechts": "573499.2",
        "UTM Koord. Hoch": "5934529.996",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Zwergfalkenweg",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H16455",
        "UTM Koord. Rechts": "573634.776",
        "UTM Koord. Hoch": "5934333.723",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "135"
    },
    {
        "Hydranten Nr": "H16592",
        "UTM Koord. Rechts": "573585.32",
        "UTM Koord. Hoch": "5934235.46",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "129"
    },
    {
        "Hydranten Nr": "H16747",
        "UTM Koord. Rechts": "572907.554",
        "UTM Koord. Hoch": "5934397.171",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Redder",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H8937",
        "UTM Koord. Rechts": "575310.168",
        "UTM Koord. Hoch": "5931537.449",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H11829",
        "UTM Koord. Rechts": "575119.836",
        "UTM Koord. Hoch": "5932254.554",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dröögsiet",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H11811",
        "UTM Koord. Rechts": "575639.033",
        "UTM Koord. Hoch": "5932547.757",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinfurther Allee",
        "HsNr.": "66"
    },
    {
        "Hydranten Nr": "H11839",
        "UTM Koord. Rechts": "575266.278",
        "UTM Koord. Hoch": "5932473.869",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sonnenland",
        "HsNr.": "48"
    },
    {
        "Hydranten Nr": "H11813",
        "UTM Koord. Rechts": "575620.041",
        "UTM Koord. Hoch": "5932500.048",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Glinder Au",
        "HsNr.": "71"
    },
    {
        "Hydranten Nr": "H11755",
        "UTM Koord. Rechts": "575595.872",
        "UTM Koord. Hoch": "5933036.075",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Tinnumer Weg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H10972",
        "UTM Koord. Rechts": "575331.539",
        "UTM Koord. Hoch": "5933391.563",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Bonhoefferstraße",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H10860",
        "UTM Koord. Rechts": "575066.939",
        "UTM Koord. Hoch": "5933579.638",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Salomon-Petri-Ring",
        "HsNr.": "53"
    },
    {
        "Hydranten Nr": "H16814",
        "UTM Koord. Rechts": "573075.486",
        "UTM Koord. Hoch": "5934781.1",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Querkamp",
        "HsNr.": "65"
    },
    {
        "Hydranten Nr": "H16816",
        "UTM Koord. Rechts": "572996.558",
        "UTM Koord. Hoch": "5934663.974",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Querkamp",
        "HsNr.": "55"
    },
    {
        "Hydranten Nr": "H12271",
        "UTM Koord. Rechts": "571214.12",
        "UTM Koord. Hoch": "5930746.286",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H12275",
        "UTM Koord. Rechts": "571062.527",
        "UTM Koord. Hoch": "5930898.58",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H7541",
        "UTM Koord. Rechts": "572313.925",
        "UTM Koord. Hoch": "5931146.297",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Moorfleeter Straße",
        "HsNr.": "50"
    },
    {
        "Hydranten Nr": "H7575",
        "UTM Koord. Rechts": "572341.464",
        "UTM Koord. Hoch": "5931151.335",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Moorfleeter Straße",
        "HsNr.": "47"
    },
    {
        "Hydranten Nr": "H17104",
        "UTM Koord. Rechts": "572239.338",
        "UTM Koord. Hoch": "5933896.451",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Stengelestraße",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H17151",
        "UTM Koord. Rechts": "572173.164",
        "UTM Koord. Hoch": "5933851.115",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Stengelestraße",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H17190",
        "UTM Koord. Rechts": "572098.94",
        "UTM Koord. Hoch": "5934006.18",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Von-Elm-Weg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H17191",
        "UTM Koord. Rechts": "572149.935",
        "UTM Koord. Hoch": "5933934.346",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Von-Elm-Weg",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H17142",
        "UTM Koord. Rechts": "572141.105",
        "UTM Koord. Hoch": "5933760.828",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Blosweg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H7783",
        "UTM Koord. Rechts": "576241.45",
        "UTM Koord. Hoch": "5931551.794",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kandinskyallee",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H7740",
        "UTM Koord. Rechts": "576205.445",
        "UTM Koord. Hoch": "5931349.272",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kandinskyallee",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H7738",
        "UTM Koord. Rechts": "576207.618",
        "UTM Koord. Hoch": "5931445.835",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kandinskyallee",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H19308",
        "UTM Koord. Rechts": "573563.381",
        "UTM Koord. Hoch": "5931324.236",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Unterer Landweg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H17021",
        "UTM Koord. Rechts": "572586.921",
        "UTM Koord. Hoch": "5933304.64",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "105"
    },
    {
        "Hydranten Nr": "H17120",
        "UTM Koord. Rechts": "572753.748",
        "UTM Koord. Hoch": "5933103.373",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H6461",
        "UTM Koord. Rechts": "574097.081",
        "UTM Koord. Hoch": "5935291.28",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schneewittchenweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16890",
        "UTM Koord. Rechts": "572112.3",
        "UTM Koord. Hoch": "5934518.414",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H17057",
        "UTM Koord. Rechts": "572083.753",
        "UTM Koord. Hoch": "5934237.364",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bergstieg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H17115",
        "UTM Koord. Rechts": "572676.336",
        "UTM Koord. Hoch": "5933160.03",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H17112",
        "UTM Koord. Rechts": "572462.585",
        "UTM Koord. Hoch": "5933493.459",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "112"
    },
    {
        "Hydranten Nr": "H17128",
        "UTM Koord. Rechts": "572236.464",
        "UTM Koord. Hoch": "5933551.088",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "KLGV.138 Letzter Heller",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H10719",
        "UTM Koord. Rechts": "573525.705",
        "UTM Koord. Hoch": "5931465.639",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "111"
    },
    {
        "Hydranten Nr": "H11096",
        "UTM Koord. Rechts": "574150.32",
        "UTM Koord. Hoch": "5935235.37",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergredder",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H11875",
        "UTM Koord. Rechts": "574131.839",
        "UTM Koord. Hoch": "5935191.637",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rehkoppel",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H16756",
        "UTM Koord. Rechts": "572727.361",
        "UTM Koord. Hoch": "5934546.671",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Am Horner Moor",
        "HsNr.": "41"
    },
    {
        "Hydranten Nr": "H16731",
        "UTM Koord. Rechts": "572448.496",
        "UTM Koord. Hoch": "5934314.537",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kroogblöcke",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H16729",
        "UTM Koord. Rechts": "572530.149",
        "UTM Koord. Hoch": "5934296.078",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kroogblöcke",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H16728",
        "UTM Koord. Rechts": "572628.685",
        "UTM Koord. Hoch": "5934285.546",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kroogblöcke",
        "HsNr.": "49"
    },
    {
        "Hydranten Nr": "H16831",
        "UTM Koord. Rechts": "572499.646",
        "UTM Koord. Hoch": "5934183.162",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "97"
    },
    {
        "Hydranten Nr": "H16877",
        "UTM Koord. Rechts": "572468.809",
        "UTM Koord. Hoch": "5934185.62",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "70"
    },
    {
        "Hydranten Nr": "H16849",
        "UTM Koord. Rechts": "572494.202",
        "UTM Koord. Hoch": "5934542.906",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Speckenreye",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H16850",
        "UTM Koord. Rechts": "572575.962",
        "UTM Koord. Hoch": "5934485.331",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Speckenreye",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H6480",
        "UTM Koord. Rechts": "574039.629",
        "UTM Koord. Hoch": "5935128.874",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sterntalerstraße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H14398",
        "UTM Koord. Rechts": "573438.689",
        "UTM Koord. Hoch": "5931499.761",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "105"
    },
    {
        "Hydranten Nr": "H14400",
        "UTM Koord. Rechts": "573360.369",
        "UTM Koord. Hoch": "5931523.578",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "99"
    },
    {
        "Hydranten Nr": "H7581",
        "UTM Koord. Rechts": "572871.648",
        "UTM Koord. Hoch": "5930811.289",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Porgesring",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H7617",
        "UTM Koord. Rechts": "572707.323",
        "UTM Koord. Hoch": "5930914.304",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Porgesring",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H12968",
        "UTM Koord. Rechts": "572330.021",
        "UTM Koord. Hoch": "5930844.82",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H12982",
        "UTM Koord. Rechts": "572001.567",
        "UTM Koord. Hoch": "5931054.102",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H12987",
        "UTM Koord. Rechts": "572559.343",
        "UTM Koord. Hoch": "5930216.75",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Halskestraße",
        "HsNr.": "67"
    },
    {
        "Hydranten Nr": "H19313",
        "UTM Koord. Rechts": "573295.966",
        "UTM Koord. Hoch": "5931014.419",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "KLGV.126 Unterer Landweg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H19318",
        "UTM Koord. Rechts": "573175.787",
        "UTM Koord. Hoch": "5930873.639",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Unterer Landweg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H19320",
        "UTM Koord. Rechts": "573110.038",
        "UTM Koord. Hoch": "5930792.388",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Unterer Landweg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H19322",
        "UTM Koord. Rechts": "573043.951",
        "UTM Koord. Hoch": "5930710.187",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "KLGV.126 Unterer Landweg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H16942",
        "UTM Koord. Rechts": "572719.363",
        "UTM Koord. Hoch": "5933451.321",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kuriergang",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16933",
        "UTM Koord. Rechts": "572810.877",
        "UTM Koord. Hoch": "5933102.753",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H16852",
        "UTM Koord. Rechts": "572734.016",
        "UTM Koord. Hoch": "5934379.381",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Querkamp",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H16947",
        "UTM Koord. Rechts": "572682.639",
        "UTM Koord. Hoch": "5933337.813",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Legienstraße",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H16946",
        "UTM Koord. Rechts": "572665.949",
        "UTM Koord. Hoch": "5933251.345",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Legienstraße",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H17027",
        "UTM Koord. Rechts": "572572.236",
        "UTM Koord. Hoch": "5933405.63",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "97"
    },
    {
        "Hydranten Nr": "H17028",
        "UTM Koord. Rechts": "572597.365",
        "UTM Koord. Hoch": "5933493.958",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonring",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H8942",
        "UTM Koord. Rechts": "575892.577",
        "UTM Koord. Hoch": "5931565.53",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H11757",
        "UTM Koord. Rechts": "575529.826",
        "UTM Koord. Hoch": "5933009.253",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Morsumer Weg",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H6505",
        "UTM Koord. Rechts": "573425.82",
        "UTM Koord. Hoch": "5935122.662",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dannerallee",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H5797",
        "UTM Koord. Rechts": "573159.439",
        "UTM Koord. Hoch": "5935499.188",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Böcklerstraße",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H5796",
        "UTM Koord. Rechts": "573261.752",
        "UTM Koord. Hoch": "5935500.334",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Böcklerstraße",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H7980",
        "UTM Koord. Rechts": "573740.681",
        "UTM Koord. Hoch": "5935206.874",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sterntalerstraße",
        "HsNr.": "38"
    },
    {
        "Hydranten Nr": "H10705",
        "UTM Koord. Rechts": "573574.972",
        "UTM Koord. Hoch": "5931878.077",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Rote Brücke",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H15793",
        "UTM Koord. Rechts": "571579.69",
        "UTM Koord. Hoch": "5931893.166",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H17096",
        "UTM Koord. Rechts": "572084.814",
        "UTM Koord. Hoch": "5934124.796",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H17205",
        "UTM Koord. Rechts": "571970.116",
        "UTM Koord. Hoch": "5934067.878",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Posteltsweg",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H18823",
        "UTM Koord. Rechts": "572217.346",
        "UTM Koord. Hoch": "5934486.645",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H13122",
        "UTM Koord. Rechts": "571307.789",
        "UTM Koord. Hoch": "5931702.701",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H10643",
        "UTM Koord. Rechts": "572993.926",
        "UTM Koord. Hoch": "5931135.68",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "44"
    },
    {
        "Hydranten Nr": "H7597",
        "UTM Koord. Rechts": "572987.988",
        "UTM Koord. Hoch": "5931156.032",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H10727",
        "UTM Koord. Rechts": "573638.589",
        "UTM Koord. Hoch": "5931452.315",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "337"
    },
    {
        "Hydranten Nr": "H11230",
        "UTM Koord. Rechts": "573687.908",
        "UTM Koord. Hoch": "5931529.418",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "329"
    },
    {
        "Hydranten Nr": "H7593",
        "UTM Koord. Rechts": "573098.139",
        "UTM Koord. Hoch": "5931076.067",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "47"
    },
    {
        "Hydranten Nr": "H7601",
        "UTM Koord. Rechts": "572931.389",
        "UTM Koord. Hoch": "5931181.504",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H7557",
        "UTM Koord. Rechts": "572531.611",
        "UTM Koord. Hoch": "5931702.547",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H9953",
        "UTM Koord. Rechts": "572609.112",
        "UTM Koord. Hoch": "5931743.788",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "63"
    },
    {
        "Hydranten Nr": "H14412",
        "UTM Koord. Rechts": "572822.062",
        "UTM Koord. Hoch": "5931681.797",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "68"
    },
    {
        "Hydranten Nr": "H12974",
        "UTM Koord. Rechts": "572160.492",
        "UTM Koord. Hoch": "5930954.111",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H12273",
        "UTM Koord. Rechts": "571138.263",
        "UTM Koord. Hoch": "5930821.859",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H13039",
        "UTM Koord. Rechts": "571538.872",
        "UTM Koord. Hoch": "5930999.226",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H7521",
        "UTM Koord. Rechts": "572828.613",
        "UTM Koord. Hoch": "5932622.337",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Moorfleeter Straße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H16939",
        "UTM Koord. Rechts": "572897.583",
        "UTM Koord. Hoch": "5933365.059",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hauskoppel",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H18419",
        "UTM Koord. Rechts": "572667.4",
        "UTM Koord. Hoch": "5933130.881",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H18412",
        "UTM Koord. Rechts": "572521.04",
        "UTM Koord. Hoch": "5933214.442",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "449"
    },
    {
        "Hydranten Nr": "H12289",
        "UTM Koord. Rechts": "572742.232",
        "UTM Koord. Hoch": "5933079.122",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H17754",
        "UTM Koord. Rechts": "572032.523",
        "UTM Koord. Hoch": "5933231.229",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kolumbusstraße",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H17756",
        "UTM Koord. Rechts": "572205.777",
        "UTM Koord. Hoch": "5933129.544",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kolumbusstraße",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H16733",
        "UTM Koord. Rechts": "572414.133",
        "UTM Koord. Hoch": "5934340.216",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kroogblöcke",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "H12436",
        "UTM Koord. Rechts": "572308.092",
        "UTM Koord. Hoch": "5930231.939",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "63"
    },
    {
        "Hydranten Nr": "H13010",
        "UTM Koord. Rechts": "572841.759",
        "UTM Koord. Hoch": "5930426.336",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Alter Landweg",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H13013",
        "UTM Koord. Rechts": "572760.039",
        "UTM Koord. Hoch": "5930335.921",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Alter Landweg",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H11945",
        "UTM Koord. Rechts": "572463.594",
        "UTM Koord. Hoch": "5930097.788",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "KLGV.604 Moorfleet",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H14402",
        "UTM Koord. Rechts": "573260.508",
        "UTM Koord. Hoch": "5931552.594",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "93"
    },
    {
        "Hydranten Nr": "H19337",
        "UTM Koord. Rechts": "572952.188",
        "UTM Koord. Hoch": "5930581.276",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Unterer Landweg",
        "HsNr.": "93"
    },
    {
        "Hydranten Nr": "H7589",
        "UTM Koord. Rechts": "573238.058",
        "UTM Koord. Hoch": "5930967.784",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "55"
    },
    {
        "Hydranten Nr": "H7578",
        "UTM Koord. Rechts": "573084.743",
        "UTM Koord. Hoch": "5930975.455",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Porgesring",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H7580",
        "UTM Koord. Rechts": "572956.066",
        "UTM Koord. Hoch": "5930821.383",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Porgesring",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H17121",
        "UTM Koord. Rechts": "572825.782",
        "UTM Koord. Hoch": "5933051.133",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H15949",
        "UTM Koord. Rechts": "574245.763",
        "UTM Koord. Hoch": "5935068.612",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hasenbanckweg",
        "HsNr.": "80"
    },
    {
        "Hydranten Nr": "H11879",
        "UTM Koord. Rechts": "574208.525",
        "UTM Koord. Hoch": "5935179.611",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergredder",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H11097",
        "UTM Koord. Rechts": "574185.384",
        "UTM Koord. Hoch": "5935230.522",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergredder",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H17753",
        "UTM Koord. Rechts": "571942.862",
        "UTM Koord. Hoch": "5933278.361",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kolumbusstraße",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H16891",
        "UTM Koord. Rechts": "571988.549",
        "UTM Koord. Hoch": "5934535.081",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H17050",
        "UTM Koord. Rechts": "572183.557",
        "UTM Koord. Hoch": "5934157.983",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hasencleverstraße",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H8290",
        "UTM Koord. Rechts": "576366.377",
        "UTM Koord. Hoch": "5932290.848",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Am Mühlenbach",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "H9080",
        "UTM Koord. Rechts": "575928.145",
        "UTM Koord. Hoch": "5932165.046",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mondrianweg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H11844",
        "UTM Koord. Rechts": "575010.788",
        "UTM Koord. Hoch": "5932206.462",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sonnenland",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H9020",
        "UTM Koord. Rechts": "575571.384",
        "UTM Koord. Hoch": "5932125.769",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "67"
    },
    {
        "Hydranten Nr": "H11843",
        "UTM Koord. Rechts": "575075.372",
        "UTM Koord. Hoch": "5932297.296",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sonnenland",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H11850",
        "UTM Koord. Rechts": "574990.724",
        "UTM Koord. Hoch": "5932460.753",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H15681",
        "UTM Koord. Rechts": "574054.7",
        "UTM Koord. Hoch": "5935312.352",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schneewittchenweg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H15746",
        "UTM Koord. Rechts": "574065.84",
        "UTM Koord. Hoch": "5935461.728",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Aschenputtelstraße",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H10720",
        "UTM Koord. Rechts": "573586.291",
        "UTM Koord. Hoch": "5931405.567",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "340"
    },
    {
        "Hydranten Nr": "H7582",
        "UTM Koord. Rechts": "572785.772",
        "UTM Koord. Hoch": "5930864.07",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Porgesring",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H12950",
        "UTM Koord. Rechts": "572033.608",
        "UTM Koord. Hoch": "5930567.025",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "54"
    },
    {
        "Hydranten Nr": "H17056",
        "UTM Koord. Rechts": "572121.423",
        "UTM Koord. Hoch": "5934175.747",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bergstieg",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H16211",
        "UTM Koord. Rechts": "573288.211",
        "UTM Koord. Hoch": "5933169.31",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Reclamstraße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H7603",
        "UTM Koord. Rechts": "572845.602",
        "UTM Koord. Hoch": "5931232.457",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H13831",
        "UTM Koord. Rechts": "572847.102",
        "UTM Koord. Hoch": "5933267.143",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hauskoppel",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H16929",
        "UTM Koord. Rechts": "572861.488",
        "UTM Koord. Hoch": "5933135.992",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kirchlinden",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H18336",
        "UTM Koord. Rechts": "572175.538",
        "UTM Koord. Hoch": "5933367.161",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Letzter Heller",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H12965",
        "UTM Koord. Rechts": "572414.33",
        "UTM Koord. Hoch": "5930791.08",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H12961",
        "UTM Koord. Rechts": "572500.477",
        "UTM Koord. Hoch": "5930736.469",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H12981",
        "UTM Koord. Rechts": "572046.521",
        "UTM Koord. Hoch": "5931010.71",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H12984",
        "UTM Koord. Rechts": "571973.667",
        "UTM Koord. Hoch": "5931058.349",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H17008",
        "UTM Koord. Rechts": "572647.796",
        "UTM Koord. Hoch": "5933574.06",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Helma-Steinbach-Weg",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H17007",
        "UTM Koord. Rechts": "572561.545",
        "UTM Koord. Hoch": "5933639.669",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Helma-Steinbach-Weg",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H17010",
        "UTM Koord. Rechts": "572463.437",
        "UTM Koord. Hoch": "5933673.38",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Vierbergen",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H16876",
        "UTM Koord. Rechts": "572518.376",
        "UTM Koord. Hoch": "5934145.768",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "72"
    },
    {
        "Hydranten Nr": "H16880",
        "UTM Koord. Rechts": "572367.397",
        "UTM Koord. Hoch": "5934259.358",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "79"
    },
    {
        "Hydranten Nr": "H16883",
        "UTM Koord. Rechts": "572270.905",
        "UTM Koord. Hoch": "5934360.446",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "57"
    },
    {
        "Hydranten Nr": "H17045",
        "UTM Koord. Rechts": "572498.562",
        "UTM Koord. Hoch": "5934101.903",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "72"
    },
    {
        "Hydranten Nr": "H8778",
        "UTM Koord. Rechts": "575705.688",
        "UTM Koord. Hoch": "5931449.498",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "August-Macke-Weg",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H16862",
        "UTM Koord. Rechts": "572875.227",
        "UTM Koord. Hoch": "5933979.195",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "116"
    },
    {
        "Hydranten Nr": "H16870",
        "UTM Koord. Rechts": "572899.659",
        "UTM Koord. Hoch": "5933971.679",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "116"
    },
    {
        "Hydranten Nr": "H19309",
        "UTM Koord. Rechts": "573496.58",
        "UTM Koord. Hoch": "5931243.631",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Unterer Landweg",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H12236",
        "UTM Koord. Rechts": "571996.362",
        "UTM Koord. Hoch": "5929951.912",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "47"
    },
    {
        "Hydranten Nr": "H12426",
        "UTM Koord. Rechts": "572220.97",
        "UTM Koord. Hoch": "5929878.934",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "72"
    },
    {
        "Hydranten Nr": "H16872",
        "UTM Koord. Rechts": "572716.854",
        "UTM Koord. Hoch": "5934042.888",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "88"
    },
    {
        "Hydranten Nr": "H16986",
        "UTM Koord. Rechts": "572869.454",
        "UTM Koord. Hoch": "5933878.442",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Legienstraße",
        "HsNr.": "127"
    },
    {
        "Hydranten Nr": "H16985",
        "UTM Koord. Rechts": "572831.382",
        "UTM Koord. Hoch": "5933795.82",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Legienstraße",
        "HsNr.": "115"
    },
    {
        "Hydranten Nr": "H16743",
        "UTM Koord. Rechts": "572806.138",
        "UTM Koord. Hoch": "5934437.719",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Querkamp",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H16744",
        "UTM Koord. Rechts": "572872.014",
        "UTM Koord. Hoch": "5934512.072",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Am Horner Moor",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H13113",
        "UTM Koord. Rechts": "571195.668",
        "UTM Koord. Hoch": "5931597.783",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "71"
    },
    {
        "Hydranten Nr": "H5840",
        "UTM Koord. Rechts": "572682.522",
        "UTM Koord. Hoch": "5935023.577",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Grüningweg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H906",
        "UTM Koord. Rechts": "572869.555",
        "UTM Koord. Hoch": "5934934.08",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Heinrich-Kaufmann-Ring",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H17124",
        "UTM Koord. Rechts": "572306.873",
        "UTM Koord. Hoch": "5933484.336",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Weddestraße",
        "HsNr.": "71"
    },
    {
        "Hydranten Nr": "H17123",
        "UTM Koord. Rechts": "572379.011",
        "UTM Koord. Hoch": "5933421.044",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Weddestraße",
        "HsNr.": "84"
    },
    {
        "Hydranten Nr": "H12423",
        "UTM Koord. Rechts": "572167.868",
        "UTM Koord. Hoch": "5929804.773",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "76"
    },
    {
        "Hydranten Nr": "H13026",
        "UTM Koord. Rechts": "572728.848",
        "UTM Koord. Hoch": "5930226.074",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Alter Landweg",
        "HsNr.": "48"
    },
    {
        "Hydranten Nr": "H15919",
        "UTM Koord. Rechts": "574220.114",
        "UTM Koord. Hoch": "5935337.205",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dringsheide",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16739",
        "UTM Koord. Rechts": "572366.437",
        "UTM Koord. Hoch": "5934405.778",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kroogblöcke",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H8001",
        "UTM Koord. Rechts": "574049.235",
        "UTM Koord. Hoch": "5935157.691",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sterntalerstraße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H7998",
        "UTM Koord. Rechts": "573925.599",
        "UTM Koord. Hoch": "5935182.081",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sterntalerstraße",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H11679",
        "UTM Koord. Rechts": "574930.301",
        "UTM Koord. Hoch": "5935450.004",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergweg",
        "HsNr.": "41"
    },
    {
        "Hydranten Nr": "H15921",
        "UTM Koord. Rechts": "574304.32",
        "UTM Koord. Hoch": "5935371.559",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dringsheide",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H18329",
        "UTM Koord. Rechts": "571904.292",
        "UTM Koord. Hoch": "5933539.038",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Marshallweg",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H18332",
        "UTM Koord. Rechts": "572223.246",
        "UTM Koord. Hoch": "5933295.022",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Letzter Heller",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H18340",
        "UTM Koord. Rechts": "572104.578",
        "UTM Koord. Hoch": "5933372.74",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Letzter Heller",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H13024",
        "UTM Koord. Rechts": "572730.848",
        "UTM Koord. Hoch": "5930247.466",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Alter Landweg",
        "HsNr.": "41"
    },
    {
        "Hydranten Nr": "H17020",
        "UTM Koord. Rechts": "572619.449",
        "UTM Koord. Hoch": "5933222.843",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "111"
    },
    {
        "Hydranten Nr": "H11884",
        "UTM Koord. Rechts": "574338.912",
        "UTM Koord. Hoch": "5935160.832",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergredder",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H11673",
        "UTM Koord. Rechts": "574973.035",
        "UTM Koord. Hoch": "5935296.633",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Haferblöcken",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H16826",
        "UTM Koord. Rechts": "572644.012",
        "UTM Koord. Hoch": "5934097.433",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "111"
    },
    {
        "Hydranten Nr": "H16833",
        "UTM Koord. Rechts": "572328.127",
        "UTM Koord. Hoch": "5934316.404",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "67"
    },
    {
        "Hydranten Nr": "H16834",
        "UTM Koord. Rechts": "572260.613",
        "UTM Koord. Hoch": "5934415.124",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "45"
    },
    {
        "Hydranten Nr": "H16873",
        "UTM Koord. Rechts": "572575.651",
        "UTM Koord. Hoch": "5934112.623",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "82"
    },
    {
        "Hydranten Nr": "H11906",
        "UTM Koord. Rechts": "574925.927",
        "UTM Koord. Hoch": "5935353.852",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergredder",
        "HsNr.": "90"
    },
    {
        "Hydranten Nr": "H7547",
        "UTM Koord. Rechts": "572423.762",
        "UTM Koord. Hoch": "5931378.209",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H7552",
        "UTM Koord. Rechts": "572454.98",
        "UTM Koord. Hoch": "5931353.808",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H13034",
        "UTM Koord. Rechts": "571725.582",
        "UTM Koord. Hoch": "5930844.184",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "44"
    },
    {
        "Hydranten Nr": "H13032",
        "UTM Koord. Rechts": "571807.942",
        "UTM Koord. Hoch": "5930779.009",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "46"
    },
    {
        "Hydranten Nr": "H13030",
        "UTM Koord. Rechts": "571884.105",
        "UTM Koord. Hoch": "5930714.205",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "48"
    },
    {
        "Hydranten Nr": "H18061",
        "UTM Koord. Rechts": "571809.898",
        "UTM Koord. Hoch": "5933928.542",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Nedderndorfer Weg",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H11960",
        "UTM Koord. Rechts": "571440.802",
        "UTM Koord. Hoch": "5931761.416",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "51"
    },
    {
        "Hydranten Nr": "H11675",
        "UTM Koord. Rechts": "574726.639",
        "UTM Koord. Hoch": "5935484.466",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergweg",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H11678",
        "UTM Koord. Rechts": "574827.741",
        "UTM Koord. Hoch": "5935467.57",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergweg",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H15702",
        "UTM Koord. Rechts": "571797.751",
        "UTM Koord. Hoch": "5934441.881",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H586",
        "UTM Koord. Rechts": "573205.727",
        "UTM Koord. Hoch": "5929934.756",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "KLGV.601 Billbrook Dorf",
        "HsNr.": "49"
    },
    {
        "Hydranten Nr": "H16587",
        "UTM Koord. Rechts": "573732.03",
        "UTM Koord. Hoch": "5934528.51",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Goldelsestieg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H6383",
        "UTM Koord. Rechts": "573692.299",
        "UTM Koord. Hoch": "5935697.953",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Aladinweg",
        "HsNr.": "80"
    },
    {
        "Hydranten Nr": "H6482",
        "UTM Koord. Rechts": "573763.781",
        "UTM Koord. Hoch": "5935717.165",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Aladinweg",
        "HsNr.": "68"
    },
    {
        "Hydranten Nr": "H6397",
        "UTM Koord. Rechts": "573774.513",
        "UTM Koord. Hoch": "5935655.326",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rübezahlstraße",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H17200",
        "UTM Koord. Rechts": "571867.379",
        "UTM Koord. Hoch": "5934119.101",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Pagenfelder Platz",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H18018",
        "UTM Koord. Rechts": "571807.428",
        "UTM Koord. Hoch": "5934211.448",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Beim Hirtenkaten",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H18241",
        "UTM Koord. Rechts": "571839.208",
        "UTM Koord. Hoch": "5934093.431",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Pagenfelder Platz",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H13072",
        "UTM Koord. Rechts": "571850.393",
        "UTM Koord. Hoch": "5931136.751",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H13092",
        "UTM Koord. Rechts": "571558.93",
        "UTM Koord. Hoch": "5931440.076",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H13106",
        "UTM Koord. Rechts": "571697.226",
        "UTM Koord. Hoch": "5931465.337",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H18065",
        "UTM Koord. Rechts": "571713.423",
        "UTM Koord. Hoch": "5933861.05",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "213"
    },
    {
        "Hydranten Nr": "H18080",
        "UTM Koord. Rechts": "571821.862",
        "UTM Koord. Hoch": "5933735.614",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "283"
    },
    {
        "Hydranten Nr": "H18047",
        "UTM Koord. Rechts": "571863.557",
        "UTM Koord. Hoch": "5933742.4",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "283"
    },
    {
        "Hydranten Nr": "H18088",
        "UTM Koord. Rechts": "571514.08",
        "UTM Koord. Hoch": "5934001.293",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "175"
    },
    {
        "Hydranten Nr": "H16427",
        "UTM Koord. Rechts": "573752.29",
        "UTM Koord. Hoch": "5934700.466",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Däumlingtwiete",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H19564",
        "UTM Koord. Rechts": "571833.288",
        "UTM Koord. Hoch": "5932620.316",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Wöhlerstraße",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H2913",
        "UTM Koord. Rechts": "574382.149",
        "UTM Koord. Hoch": "5930574.089",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Billwerder Billdeich",
        "HsNr.": "90"
    },
    {
        "Hydranten Nr": "H6418",
        "UTM Koord. Rechts": "573558.431",
        "UTM Koord. Hoch": "5935644.553",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Zwischen den Hecken",
        "HsNr.": "74"
    },
    {
        "Hydranten Nr": "H6570",
        "UTM Koord. Rechts": "573365.302",
        "UTM Koord. Hoch": "5935630.593",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Zwischen den Hecken",
        "HsNr.": "50"
    },
    {
        "Hydranten Nr": "H17866",
        "UTM Koord. Rechts": "571156.673",
        "UTM Koord. Hoch": "5934366.306",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Beim Rauhen Hause",
        "HsNr.": "66"
    },
    {
        "Hydranten Nr": "H17867",
        "UTM Koord. Rechts": "571256.601",
        "UTM Koord. Hoch": "5934331.667",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Dunckersweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H18153",
        "UTM Koord. Rechts": "571857.549",
        "UTM Koord. Hoch": "5934511.763",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H18135",
        "UTM Koord. Rechts": "571110.43",
        "UTM Koord. Hoch": "5934374.873",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rhiemsweg",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H18138",
        "UTM Koord. Rechts": "571482.663",
        "UTM Koord. Hoch": "5934405.514",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "O'Swaldstraße",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H18243",
        "UTM Koord. Rechts": "571825.981",
        "UTM Koord. Hoch": "5934211.698",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H16022",
        "UTM Koord. Rechts": "573582.193",
        "UTM Koord. Hoch": "5934181.062",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "120"
    },
    {
        "Hydranten Nr": "H16594",
        "UTM Koord. Rechts": "573538.952",
        "UTM Koord. Hoch": "5934143.518",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "127"
    },
    {
        "Hydranten Nr": "H6514",
        "UTM Koord. Rechts": "573422.632",
        "UTM Koord. Hoch": "5935338.773",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dannerallee",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H18044",
        "UTM Koord. Rechts": "571060.456",
        "UTM Koord. Hoch": "5934371.436",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Beim Rauhen Hause",
        "HsNr.": "50"
    },
    {
        "Hydranten Nr": "H18132",
        "UTM Koord. Rechts": "571052.167",
        "UTM Koord. Hoch": "5934247.337",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "69"
    },
    {
        "Hydranten Nr": "H15996",
        "UTM Koord. Rechts": "573921.214",
        "UTM Koord. Hoch": "5934618.907",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Danckwerthweg",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H15994",
        "UTM Koord. Rechts": "573954.439",
        "UTM Koord. Hoch": "5934685.394",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Danckwerthweg",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H18290",
        "UTM Koord. Rechts": "571564.298",
        "UTM Koord. Hoch": "5933513.186",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Heckenpfad",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H18265",
        "UTM Koord. Rechts": "571843.72",
        "UTM Koord. Hoch": "5933173.783",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Jürsweg",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H13125",
        "UTM Koord. Rechts": "571262.075",
        "UTM Koord. Hoch": "5931668.915",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "57"
    },
    {
        "Hydranten Nr": "H11057",
        "UTM Koord. Rechts": "570804.464",
        "UTM Koord. Hoch": "5931136.537",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H11255",
        "UTM Koord. Rechts": "573865.835",
        "UTM Koord. Hoch": "5935667.42",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rübezahlstraße",
        "HsNr.": "46"
    },
    {
        "Hydranten Nr": "H11901",
        "UTM Koord. Rechts": "574833.212",
        "UTM Koord. Hoch": "5935322.066",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergredder",
        "HsNr.": "80"
    },
    {
        "Hydranten Nr": "H585",
        "UTM Koord. Rechts": "573307.403",
        "UTM Koord. Hoch": "5929791.139",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Dweerlandweg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H15999",
        "UTM Koord. Rechts": "573888.088",
        "UTM Koord. Hoch": "5934536.786",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Danckwerthweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H16446",
        "UTM Koord. Rechts": "573823.701",
        "UTM Koord. Hoch": "5934708.385",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "170"
    },
    {
        "Hydranten Nr": "H7933",
        "UTM Koord. Rechts": "574820.361",
        "UTM Koord. Hoch": "5935420.128",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Am Maisfeld",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H7935",
        "UTM Koord. Rechts": "574880.037",
        "UTM Koord. Hoch": "5935385.57",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Am Maisfeld",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H11886",
        "UTM Koord. Rechts": "574438.531",
        "UTM Koord. Hoch": "5935187.137",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergredder",
        "HsNr.": "38"
    },
    {
        "Hydranten Nr": "H6422",
        "UTM Koord. Rechts": "573552.999",
        "UTM Koord. Hoch": "5935737.322",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kreyensaal",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H19558",
        "UTM Koord. Rechts": "571809.676",
        "UTM Koord. Hoch": "5932527.994",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H6386",
        "UTM Koord. Rechts": "573852.923",
        "UTM Koord. Hoch": "5935736.067",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Aladinweg",
        "HsNr.": "56"
    },
    {
        "Hydranten Nr": "H6331",
        "UTM Koord. Rechts": "571350.437",
        "UTM Koord. Hoch": "5935255.371",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "119"
    },
    {
        "Hydranten Nr": "H18790",
        "UTM Koord. Rechts": "574761.654",
        "UTM Koord. Hoch": "5935409.144",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rohrkolbenstieg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H18261",
        "UTM Koord. Rechts": "571403.466",
        "UTM Koord. Hoch": "5933921.856",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Culinstraße",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H18262",
        "UTM Koord. Rechts": "571502.09",
        "UTM Koord. Hoch": "5933879.539",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Culinstraße",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H18263",
        "UTM Koord. Rechts": "571586.039",
        "UTM Koord. Hoch": "5933848.878",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Culinstraße",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H18273",
        "UTM Koord. Rechts": "571428.011",
        "UTM Koord. Hoch": "5933990.55",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bauerbergweg",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H16018",
        "UTM Koord. Rechts": "573833.459",
        "UTM Koord. Hoch": "5934546.693",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Pitersenstieg",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H16003",
        "UTM Koord. Rechts": "573838.36",
        "UTM Koord. Hoch": "5934438.482",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Danckwerthweg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16009",
        "UTM Koord. Rechts": "573860.346",
        "UTM Koord. Hoch": "5934733.096",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "176"
    },
    {
        "Hydranten Nr": "H11670",
        "UTM Koord. Rechts": "574773.183",
        "UTM Koord. Hoch": "5935221.065",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Haferblöcken",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H11669",
        "UTM Koord. Rechts": "574673.713",
        "UTM Koord. Hoch": "5935185.841",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Haferblöcken",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H4896",
        "UTM Koord. Rechts": "574735.673",
        "UTM Koord. Hoch": "5935379.176",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Harry-Hartz-Weg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H13568",
        "UTM Koord. Rechts": "574545.396",
        "UTM Koord. Hoch": "5935323.055",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Zittergrasweg",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H13571",
        "UTM Koord. Rechts": "574603.963",
        "UTM Koord. Hoch": "5935317.164",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergredder",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H13573",
        "UTM Koord. Rechts": "574638.58",
        "UTM Koord. Hoch": "5935414.168",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rispengrasweg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H13575",
        "UTM Koord. Rechts": "574663.381",
        "UTM Koord. Hoch": "5935383.508",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Pompeselstieg",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H13576",
        "UTM Koord. Rechts": "574683.322",
        "UTM Koord. Hoch": "5935324.618",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Pompeselstieg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H14391",
        "UTM Koord. Rechts": "574565.838",
        "UTM Koord. Hoch": "5935160.413",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dinkelkamp",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H14390",
        "UTM Koord. Rechts": "574503.224",
        "UTM Koord. Hoch": "5935169.061",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergredder",
        "HsNr.": "38"
    },
    {
        "Hydranten Nr": "H17082",
        "UTM Koord. Rechts": "571868.661",
        "UTM Koord. Hoch": "5934414.799",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H15982",
        "UTM Koord. Rechts": "574016.394",
        "UTM Koord. Hoch": "5934731.585",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Triftkoppel",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H9057",
        "UTM Koord. Rechts": "575470.096",
        "UTM Koord. Hoch": "5931826.626",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "41"
    },
    {
        "Hydranten Nr": "H19483",
        "UTM Koord. Rechts": "571387.271",
        "UTM Koord. Hoch": "5932441.781",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H17666",
        "UTM Koord. Rechts": "571835.707",
        "UTM Koord. Hoch": "5933684.583",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "302"
    },
    {
        "Hydranten Nr": "H18037",
        "UTM Koord. Rechts": "571420.46",
        "UTM Koord. Hoch": "5934252.983",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hertogestraße",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H16614",
        "UTM Koord. Rechts": "573500.353",
        "UTM Koord. Hoch": "5934291.838",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Seeadlerstieg",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "H12410",
        "UTM Koord. Rechts": "570873.686",
        "UTM Koord. Hoch": "5934370.571",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Beim Rauhen Hause",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H6365",
        "UTM Koord. Rechts": "571310.717",
        "UTM Koord. Hoch": "5935007.42",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rhiemsweg",
        "HsNr.": "65"
    },
    {
        "Hydranten Nr": "H6364",
        "UTM Koord. Rechts": "571379.05",
        "UTM Koord. Hoch": "5934962.859",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rhiemsweg",
        "HsNr.": "76"
    },
    {
        "Hydranten Nr": "H6357",
        "UTM Koord. Rechts": "571594.57",
        "UTM Koord. Hoch": "5934800.609",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rhiemsweg",
        "HsNr.": "98"
    },
    {
        "Hydranten Nr": "H11062",
        "UTM Koord. Rechts": "571536.832",
        "UTM Koord. Hoch": "5929983.797",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Sandwisch",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H11068",
        "UTM Koord. Rechts": "571696.46",
        "UTM Koord. Hoch": "5929903.78",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Sandwisch",
        "HsNr.": "41"
    },
    {
        "Hydranten Nr": "H17157",
        "UTM Koord. Rechts": "572007.911",
        "UTM Koord. Hoch": "5933821.618",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Weddestraße",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H11024",
        "UTM Koord. Rechts": "571300.601",
        "UTM Koord. Hoch": "5929606.85",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "165"
    },
    {
        "Hydranten Nr": "H19565",
        "UTM Koord. Rechts": "571835.219",
        "UTM Koord. Hoch": "5932690.897",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Wöhlerstraße",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H21819",
        "UTM Koord. Rechts": "571638.981",
        "UTM Koord. Hoch": "5932016.498",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Wöhlerstraße",
        "HsNr.": "32"
    },
    {
        "Hydranten Nr": "H13088",
        "UTM Koord. Rechts": "571615.011",
        "UTM Koord. Hoch": "5931335.309",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H13083",
        "UTM Koord. Rechts": "571663.1",
        "UTM Koord. Hoch": "5931283.285",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H13082",
        "UTM Koord. Rechts": "571713.559",
        "UTM Koord. Hoch": "5931237.489",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H17842",
        "UTM Koord. Rechts": "571281.767",
        "UTM Koord. Hoch": "5934852.4",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "163"
    },
    {
        "Hydranten Nr": "H17846",
        "UTM Koord. Rechts": "571358.547",
        "UTM Koord. Hoch": "5934814.715",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "169"
    },
    {
        "Hydranten Nr": "H17835",
        "UTM Koord. Rechts": "571121.607",
        "UTM Koord. Hoch": "5934921.5",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "147"
    },
    {
        "Hydranten Nr": "H17838",
        "UTM Koord. Rechts": "571208.695",
        "UTM Koord. Hoch": "5934885.986",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "166"
    },
    {
        "Hydranten Nr": "H17194",
        "UTM Koord. Rechts": "571895.103",
        "UTM Koord. Hoch": "5934035.281",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Rampe",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H18033",
        "UTM Koord. Rechts": "571771.328",
        "UTM Koord. Hoch": "5934027.806",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bei der Martinskirche",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H18042",
        "UTM Koord. Rechts": "571590.852",
        "UTM Koord. Hoch": "5934236.594",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bauerberg",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H17821",
        "UTM Koord. Rechts": "571273.94",
        "UTM Koord. Hoch": "5934829.429",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "170"
    },
    {
        "Hydranten Nr": "H19813",
        "UTM Koord. Rechts": "574323.362",
        "UTM Koord. Hoch": "5930653.367",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Billwerder Billdeich",
        "HsNr.": "80"
    },
    {
        "Hydranten Nr": "H19816",
        "UTM Koord. Rechts": "574167.811",
        "UTM Koord. Hoch": "5930864.907",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Billwerder Billdeich",
        "HsNr.": "62"
    },
    {
        "Hydranten Nr": "H19821",
        "UTM Koord. Rechts": "573720.734",
        "UTM Koord. Hoch": "5931096.372",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Billwerder Billdeich",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H19822",
        "UTM Koord. Rechts": "573653.964",
        "UTM Koord. Hoch": "5931193.723",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Billwerder Billdeich",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H19817",
        "UTM Koord. Rechts": "574056.3",
        "UTM Koord. Hoch": "5930922.019",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Billwerder Billdeich",
        "HsNr.": "55"
    },
    {
        "Hydranten Nr": "H19815",
        "UTM Koord. Rechts": "574249.792",
        "UTM Koord. Hoch": "5930748.209",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Billwerder Billdeich",
        "HsNr.": "72"
    },
    {
        "Hydranten Nr": "H19818",
        "UTM Koord. Rechts": "573999.504",
        "UTM Koord. Hoch": "5930944.242",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Billwerder Billdeich",
        "HsNr.": "48"
    },
    {
        "Hydranten Nr": "H18086",
        "UTM Koord. Rechts": "571618.877",
        "UTM Koord. Hoch": "5933906.835",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "208"
    },
    {
        "Hydranten Nr": "H18122",
        "UTM Koord. Rechts": "571557.79",
        "UTM Koord. Hoch": "5933949.571",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "200"
    },
    {
        "Hydranten Nr": "H21752",
        "UTM Koord. Rechts": "573513.96",
        "UTM Koord. Hoch": "5934215.718",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Seeadlerstieg",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H15690",
        "UTM Koord. Rechts": "571728.175",
        "UTM Koord. Hoch": "5934660.199",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "41"
    },
    {
        "Hydranten Nr": "H17797",
        "UTM Koord. Rechts": "571211.851",
        "UTM Koord. Hoch": "5934642.783",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rhiemsweg",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H18312",
        "UTM Koord. Rechts": "571687.7247",
        "UTM Koord. Hoch": "5933004.408",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Eekholtesweg",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H18308",
        "UTM Koord. Rechts": "571626.412",
        "UTM Koord. Hoch": "5932966.673",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Eekholtesweg",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H17798",
        "UTM Koord. Rechts": "571215.512",
        "UTM Koord. Hoch": "5934740.263",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rhiemsweg",
        "HsNr.": "37"
    },
    {
        "Hydranten Nr": "H16633",
        "UTM Koord. Rechts": "573039.473",
        "UTM Koord. Hoch": "5934023.509",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Wildentenstieg",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H17627",
        "UTM Koord. Rechts": "570774.619",
        "UTM Koord. Hoch": "5935027.486",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H18078",
        "UTM Koord. Rechts": "571685.933",
        "UTM Koord. Hoch": "5933852.424",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "217"
    },
    {
        "Hydranten Nr": "H13046",
        "UTM Koord. Rechts": "572277.316",
        "UTM Koord. Hoch": "5929728.165",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Unterer Landweg",
        "HsNr.": "77"
    },
    {
        "Hydranten Nr": "H15421",
        "UTM Koord. Rechts": "572317.721",
        "UTM Koord. Hoch": "5929781.694",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Unterer Landweg",
        "HsNr.": "81"
    },
    {
        "Hydranten Nr": "H10991",
        "UTM Koord. Rechts": "572039.751",
        "UTM Koord. Hoch": "5929658.581",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Brennerhof",
        "HsNr.": "121"
    },
    {
        "Hydranten Nr": "H19343",
        "UTM Koord. Rechts": "571807.126",
        "UTM Koord. Hoch": "5932945.397",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Daniel-Bartels-Weg",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H17873",
        "UTM Koord. Rechts": "571450.284",
        "UTM Koord. Hoch": "5934303.853",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hertogestraße",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H17868",
        "UTM Koord. Rechts": "571350.354",
        "UTM Koord. Hoch": "5934318.43",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Dunckersweg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H13649",
        "UTM Koord. Rechts": "571972.099",
        "UTM Koord. Hoch": "5928786.333",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Amandus-Stubbe-Straße",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H18030",
        "UTM Koord. Rechts": "571744.345",
        "UTM Koord. Hoch": "5934121.603",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Beim Pachthof",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H18039",
        "UTM Koord. Rechts": "571534.681",
        "UTM Koord. Hoch": "5934209.426",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "O'Swaldstraße",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H13043",
        "UTM Koord. Rechts": "572177.474",
        "UTM Koord. Hoch": "5929772.266",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "63"
    },
    {
        "Hydranten Nr": "H19491",
        "UTM Koord. Rechts": "571737.7",
        "UTM Koord. Hoch": "5932714.962",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "38"
    },
    {
        "Hydranten Nr": "H18323",
        "UTM Koord. Rechts": "571449.188",
        "UTM Koord. Hoch": "5934040.301",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "165"
    },
    {
        "Hydranten Nr": "H11047",
        "UTM Koord. Rechts": "570910.815",
        "UTM Koord. Hoch": "5930398.779",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "73"
    },
    {
        "Hydranten Nr": "H11053",
        "UTM Koord. Rechts": "570780.836",
        "UTM Koord. Hoch": "5930841.219",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H11055",
        "UTM Koord. Rechts": "570766.942",
        "UTM Koord. Hoch": "5931053.88",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H16521",
        "UTM Koord. Rechts": "574637.466",
        "UTM Koord. Hoch": "5933936.404",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mattkamp",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H17140",
        "UTM Koord. Rechts": "572123.242",
        "UTM Koord. Hoch": "5933670.232",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Weddestraße",
        "HsNr.": "45"
    },
    {
        "Hydranten Nr": "H10986",
        "UTM Koord. Rechts": "574931.5",
        "UTM Koord. Hoch": "5933218.248",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Höhe",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H10956",
        "UTM Koord. Rechts": "571739.082",
        "UTM Koord. Hoch": "5928543.91",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "271"
    },
    {
        "Hydranten Nr": "H6412",
        "UTM Koord. Rechts": "574221.487",
        "UTM Koord. Hoch": "5935663.378",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rautendeleinweg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H6392",
        "UTM Koord. Rechts": "574066.096",
        "UTM Koord. Hoch": "5935696.862",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rübezahlstraße",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H6379",
        "UTM Koord. Rechts": "574187.366",
        "UTM Koord. Hoch": "5935788.853",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Aladinweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H6380",
        "UTM Koord. Rechts": "574061.502",
        "UTM Koord. Hoch": "5935773.236",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Aladinweg",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H15930",
        "UTM Koord. Rechts": "574338.914",
        "UTM Koord. Hoch": "5935595.539",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dringsheide",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H15932",
        "UTM Koord. Rechts": "574291.921",
        "UTM Koord. Hoch": "5935632.527",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dringsheide",
        "HsNr.": "74"
    },
    {
        "Hydranten Nr": "H6391",
        "UTM Koord. Rechts": "574156.615",
        "UTM Koord. Hoch": "5935699.514",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rübezahlstraße",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H9026",
        "UTM Koord. Rechts": "575677.474",
        "UTM Koord. Hoch": "5932061.847",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "119"
    },
    {
        "Hydranten Nr": "H10982",
        "UTM Koord. Rechts": "575084.693",
        "UTM Koord. Hoch": "5933255.126",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Höhe",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H11007",
        "UTM Koord. Rechts": "571418.029",
        "UTM Koord. Hoch": "5929333.654",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H16653",
        "UTM Koord. Rechts": "572972.05",
        "UTM Koord. Hoch": "5933941.054",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kattensteert",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H19486",
        "UTM Koord. Rechts": "571509.807",
        "UTM Koord. Hoch": "5932601.404",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H18383",
        "UTM Koord. Rechts": "572155.959",
        "UTM Koord. Hoch": "5933463.881",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "348"
    },
    {
        "Hydranten Nr": "H18347",
        "UTM Koord. Rechts": "572052.172",
        "UTM Koord. Hoch": "5933544.232",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Brückenweg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H18366",
        "UTM Koord. Rechts": "572027.891",
        "UTM Koord. Hoch": "5933587.626",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "328"
    },
    {
        "Hydranten Nr": "H6394",
        "UTM Koord. Rechts": "573954.697",
        "UTM Koord. Hoch": "5935681.344",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rübezahlstraße",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H15766",
        "UTM Koord. Rechts": "574038.09",
        "UTM Koord. Hoch": "5935631.575",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rautendeleinweg",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H6376",
        "UTM Koord. Rechts": "574290.053",
        "UTM Koord. Hoch": "5935866.774",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Aladinweg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H6415",
        "UTM Koord. Rechts": "574240.26",
        "UTM Koord. Hoch": "5935714.419",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rübezahlstraße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H17110",
        "UTM Koord. Rechts": "572385.88",
        "UTM Koord. Hoch": "5933644.678",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "84"
    },
    {
        "Hydranten Nr": "H18339",
        "UTM Koord. Rechts": "572202.438",
        "UTM Koord. Hoch": "5933424.947",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Letzter Heller",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H16851",
        "UTM Koord. Rechts": "572648.316",
        "UTM Koord. Hoch": "5934436.723",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Speckenreye",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H15914",
        "UTM Koord. Rechts": "574326.969",
        "UTM Koord. Hoch": "5935707.08",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dringsheide",
        "HsNr.": "66"
    },
    {
        "Hydranten Nr": "H13551",
        "UTM Koord. Rechts": "574933.013",
        "UTM Koord. Hoch": "5933069.186",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dudenweg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H10950",
        "UTM Koord. Rechts": "571993.501",
        "UTM Koord. Hoch": "5928387.993",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "296"
    },
    {
        "Hydranten Nr": "H10952",
        "UTM Koord. Rechts": "571803.897",
        "UTM Koord. Hoch": "5928503.536",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "276"
    },
    {
        "Hydranten Nr": "H6381",
        "UTM Koord. Rechts": "573960.457",
        "UTM Koord. Hoch": "5935758.058",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Aladinweg",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H16464",
        "UTM Koord. Rechts": "574651.945",
        "UTM Koord. Hoch": "5933652.897",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mattkamp",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H13084",
        "UTM Koord. Rechts": "571662.961",
        "UTM Koord. Hoch": "5931301.339",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H13080",
        "UTM Koord. Rechts": "571737.05",
        "UTM Koord. Hoch": "5931232.321",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H17795",
        "UTM Koord. Rechts": "570976.59",
        "UTM Koord. Hoch": "5934701.69",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Snitgerreihe",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H6359",
        "UTM Koord. Rechts": "571551.478",
        "UTM Koord. Hoch": "5934880.729",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rhiemsweg",
        "HsNr.": "91"
    },
    {
        "Hydranten Nr": "H16543",
        "UTM Koord. Rechts": "574360.568",
        "UTM Koord. Hoch": "5933419.008",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archenholzstraße",
        "HsNr.": "65"
    },
    {
        "Hydranten Nr": "H16524",
        "UTM Koord. Rechts": "574894.537",
        "UTM Koord. Hoch": "5933510.298",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Am Kampmoor",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H16526",
        "UTM Koord. Rechts": "574822.036",
        "UTM Koord. Hoch": "5933475.834",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Am Kampmoor",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H17817",
        "UTM Koord. Rechts": "571625.055",
        "UTM Koord. Hoch": "5934633.162",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "204"
    },
    {
        "Hydranten Nr": "H16451",
        "UTM Koord. Rechts": "573875.874",
        "UTM Koord. Hoch": "5933191.16",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archenholzstraße",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H13564",
        "UTM Koord. Rechts": "573928.68",
        "UTM Koord. Hoch": "5933800.372",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Höfnageleck",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H12418",
        "UTM Koord. Rechts": "573900.543",
        "UTM Koord. Hoch": "5933813.798",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Höfnageleck",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H16067",
        "UTM Koord. Rechts": "573971.197",
        "UTM Koord. Hoch": "5933659.184",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Jenkelweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H20916",
        "UTM Koord. Rechts": "574877.338",
        "UTM Koord. Hoch": "5935785.81",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hans-Rubbert-Straße",
        "HsNr.": "133"
    },
    {
        "Hydranten Nr": "H20807",
        "UTM Koord. Rechts": "574706.487",
        "UTM Koord. Hoch": "5935682.68",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hans-Rubbert-Straße",
        "HsNr.": "79"
    },
    {
        "Hydranten Nr": "H20829",
        "UTM Koord. Rechts": "574910.154",
        "UTM Koord. Hoch": "5935542.34",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hans-Rubbert-Straße",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H10914",
        "UTM Koord. Rechts": "575550.388",
        "UTM Koord. Hoch": "5933218.644",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "235"
    },
    {
        "Hydranten Nr": "H17827",
        "UTM Koord. Rechts": "571348.001",
        "UTM Koord. Hoch": "5934793.164",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "174"
    },
    {
        "Hydranten Nr": "H17819",
        "UTM Koord. Rechts": "571442.692",
        "UTM Koord. Hoch": "5934742.793",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "184"
    },
    {
        "Hydranten Nr": "H6168",
        "UTM Koord. Rechts": "571383.661",
        "UTM Koord. Hoch": "5935078.207",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Tribünenweg",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H6333",
        "UTM Koord. Rechts": "571523.26",
        "UTM Koord. Hoch": "5935281.367",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "115"
    },
    {
        "Hydranten Nr": "H17829",
        "UTM Koord. Rechts": "571537.273",
        "UTM Koord. Hoch": "5934687.982",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "194"
    },
    {
        "Hydranten Nr": "H7586",
        "UTM Koord. Rechts": "572433.161",
        "UTM Koord. Hoch": "5931089.99",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Porgesring",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H14240",
        "UTM Koord. Rechts": "574203.099",
        "UTM Koord. Hoch": "5932162.625",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Korverweg",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H19363",
        "UTM Koord. Rechts": "571699.568",
        "UTM Koord. Hoch": "5932218.006",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H3879",
        "UTM Koord. Rechts": "571901.609",
        "UTM Koord. Hoch": "5932284.691",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H3873",
        "UTM Koord. Rechts": "572205.542",
        "UTM Koord. Hoch": "5932199.813",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "45"
    },
    {
        "Hydranten Nr": "H20381",
        "UTM Koord. Rechts": "571828.163",
        "UTM Koord. Hoch": "5931962.917",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H20382",
        "UTM Koord. Rechts": "571685.032",
        "UTM Koord. Hoch": "5932010.539",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H21334",
        "UTM Koord. Rechts": "571722.059",
        "UTM Koord. Hoch": "5932334.831",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H21321",
        "UTM Koord. Rechts": "572465.471",
        "UTM Koord. Hoch": "5932329.37",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H21324",
        "UTM Koord. Rechts": "572342.039",
        "UTM Koord. Hoch": "5932363.679",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H20910",
        "UTM Koord. Rechts": "574896.203",
        "UTM Koord. Hoch": "5935619.032",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Wasserfenchelstieg",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H20840",
        "UTM Koord. Rechts": "574943.479",
        "UTM Koord. Hoch": "5935682.615",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Honiggrasweg",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H19581",
        "UTM Koord. Rechts": "572664.456",
        "UTM Koord. Hoch": "5932257.68",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "54"
    },
    {
        "Hydranten Nr": "H19579",
        "UTM Koord. Rechts": "572568.772",
        "UTM Koord. Hoch": "5932284.603",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H19354",
        "UTM Koord. Rechts": "571747.729",
        "UTM Koord. Hoch": "5932395.157",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Wöhlerstraße",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H21329",
        "UTM Koord. Rechts": "572116.556",
        "UTM Koord. Hoch": "5932426.449",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H21921",
        "UTM Koord. Rechts": "571728.904",
        "UTM Koord. Hoch": "5931989.007",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H18058",
        "UTM Koord. Rechts": "571774.324",
        "UTM Koord. Hoch": "5933994.459",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Pagenfelder Straße",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H18124",
        "UTM Koord. Rechts": "571467.547",
        "UTM Koord. Hoch": "5933999.116",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "150"
    },
    {
        "Hydranten Nr": "H13881",
        "UTM Koord. Rechts": "576216.428",
        "UTM Koord. Hoch": "5934077.33",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "KLGV.154 Merkenstraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H17373",
        "UTM Koord. Rechts": "574350.386",
        "UTM Koord. Hoch": "5931920.846",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Weg",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H3841",
        "UTM Koord. Rechts": "576374.442",
        "UTM Koord. Hoch": "5933301.234",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Willinghusener Weg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H14203",
        "UTM Koord. Rechts": "574674.67",
        "UTM Koord. Hoch": "5932130.315",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kapellenstraße",
        "HsNr.": "93"
    },
    {
        "Hydranten Nr": "H10991",
        "UTM Koord. Rechts": "575088.135",
        "UTM Koord. Hoch": "5933362.12",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Glitzaweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H13882",
        "UTM Koord. Rechts": "576254.575",
        "UTM Koord. Hoch": "5934185.608",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hegenredder",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H13883",
        "UTM Koord. Rechts": "576285.604",
        "UTM Koord. Hoch": "5934275.873",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hegenredder",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H19505",
        "UTM Koord. Rechts": "572510.924",
        "UTM Koord. Hoch": "5932533.796",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "134"
    },
    {
        "Hydranten Nr": "H19507",
        "UTM Koord. Rechts": "572604.079",
        "UTM Koord. Hoch": "5932500.955",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "141"
    },
    {
        "Hydranten Nr": "H19552",
        "UTM Koord. Rechts": "572018.514",
        "UTM Koord. Hoch": "5932457.616",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H19555",
        "UTM Koord. Rechts": "571913.844",
        "UTM Koord. Hoch": "5932486.783",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H13127",
        "UTM Koord. Rechts": "571116.188",
        "UTM Koord. Hoch": "5931535.817",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "73"
    },
    {
        "Hydranten Nr": "H20371",
        "UTM Koord. Rechts": "572424.896",
        "UTM Koord. Hoch": "5931786.878",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "45"
    },
    {
        "Hydranten Nr": "H20372",
        "UTM Koord. Rechts": "572323.465",
        "UTM Koord. Hoch": "5931816.81",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H20374",
        "UTM Koord. Rechts": "572230.082",
        "UTM Koord. Hoch": "5931844.391",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H20376",
        "UTM Koord. Rechts": "572041.295",
        "UTM Koord. Hoch": "5931899.522",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H20377",
        "UTM Koord. Rechts": "571946.022",
        "UTM Koord. Hoch": "5931927.852",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H18270",
        "UTM Koord. Rechts": "571325.496",
        "UTM Koord. Hoch": "5933873.378",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bauerbergweg",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H18266",
        "UTM Koord. Rechts": "571256.53",
        "UTM Koord. Hoch": "5933762.223",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bauerbergweg",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H12749",
        "UTM Koord. Rechts": "573835.705",
        "UTM Koord. Hoch": "5934036.655",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Meriandamm",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H13118",
        "UTM Koord. Rechts": "571280.428",
        "UTM Koord. Hoch": "5931659.188",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "57"
    },
    {
        "Hydranten Nr": "H10739",
        "UTM Koord. Rechts": "571662.122",
        "UTM Koord. Hoch": "5932374.324",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Wöhlerstraße",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H7501",
        "UTM Koord. Rechts": "572785.535",
        "UTM Koord. Hoch": "5932363.99",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Moorfleeter Straße",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H21736",
        "UTM Koord. Rechts": "571036.888",
        "UTM Koord. Hoch": "5931478.417",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "54"
    },
    {
        "Hydranten Nr": "H21010",
        "UTM Koord. Rechts": "575756.65",
        "UTM Koord. Hoch": "5932531.85",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Glinder Au",
        "HsNr.": "79"
    },
    {
        "Hydranten Nr": "H14210",
        "UTM Koord. Rechts": "574605.751",
        "UTM Koord. Hoch": "5932265.424",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Borchardsheide",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H9065",
        "UTM Koord. Rechts": "576067.616",
        "UTM Koord. Hoch": "5931592.447",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oskar-Schlemmer-Straße",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H18359",
        "UTM Koord. Rechts": "571896.654",
        "UTM Koord. Hoch": "5933687.31",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "302"
    },
    {
        "Hydranten Nr": "H21740",
        "UTM Koord. Rechts": "571117.177",
        "UTM Koord. Hoch": "5931539.465",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "73"
    },
    {
        "Hydranten Nr": "H11237",
        "UTM Koord. Rechts": "573657.16",
        "UTM Koord. Hoch": "5931926.979",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "285"
    },
    {
        "Hydranten Nr": "H18411",
        "UTM Koord. Rechts": "572438.115",
        "UTM Koord. Hoch": "5933253.932",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "439"
    },
    {
        "Hydranten Nr": "H15917",
        "UTM Koord. Rechts": "574239.563",
        "UTM Koord. Hoch": "5935412.648",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dringsheide",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H15925",
        "UTM Koord. Rechts": "574352.978",
        "UTM Koord. Hoch": "5935463.496",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dringsheide",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H15963",
        "UTM Koord. Rechts": "574067.726",
        "UTM Koord. Hoch": "5934962.121",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hondiusweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H10995",
        "UTM Koord. Rechts": "572128.542",
        "UTM Koord. Hoch": "5929779.163",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "61"
    },
    {
        "Hydranten Nr": "H9071",
        "UTM Koord. Rechts": "575946.968",
        "UTM Koord. Hoch": "5931696.658",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Ittenstraße",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H8806",
        "UTM Koord. Rechts": "575992.084",
        "UTM Koord. Hoch": "5931432.306",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Franz-Marc-Straße",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H19502",
        "UTM Koord. Rechts": "572422.728",
        "UTM Koord. Hoch": "5932565.397",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "122"
    },
    {
        "Hydranten Nr": "H13117",
        "UTM Koord. Rechts": "571264.164",
        "UTM Koord. Hoch": "5931647.703",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "59"
    },
    {
        "Hydranten Nr": "H20588",
        "UTM Koord. Rechts": "575063.842",
        "UTM Koord. Hoch": "5935899.117",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Haferblöcken",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H8289",
        "UTM Koord. Rechts": "576225.482",
        "UTM Koord. Hoch": "5932127.143",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kandinskyallee",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H8951",
        "UTM Koord. Rechts": "576045.191",
        "UTM Koord. Hoch": "5931080.263",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "191"
    },
    {
        "Hydranten Nr": "H8947",
        "UTM Koord. Rechts": "576069.236",
        "UTM Koord. Hoch": "5931237.269",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "203"
    },
    {
        "Hydranten Nr": "H7766",
        "UTM Koord. Rechts": "576479.269",
        "UTM Koord. Hoch": "5931396.549",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rahewinkel",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H11840",
        "UTM Koord. Rechts": "575183.55",
        "UTM Koord. Hoch": "5932422.641",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sonnenland",
        "HsNr.": "44"
    },
    {
        "Hydranten Nr": "H6407",
        "UTM Koord. Rechts": "574183.423",
        "UTM Koord. Hoch": "5935630.67",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rautendeleinweg",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H8945",
        "UTM Koord. Rechts": "576165.669",
        "UTM Koord. Hoch": "5931572.69",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "55"
    },
    {
        "Hydranten Nr": "H5942",
        "UTM Koord. Rechts": "572530.257",
        "UTM Koord. Hoch": "5935462.367",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Stoltenstraße",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H358",
        "UTM Koord. Rechts": "575841.714",
        "UTM Koord. Hoch": "5932655.318",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Niebüllweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H359",
        "UTM Koord. Rechts": "575792.183",
        "UTM Koord. Hoch": "5932635.504",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Niebüllweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H9545",
        "UTM Koord. Rechts": "575861.989",
        "UTM Koord. Hoch": "5932584.153",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Glinder Au",
        "HsNr.": "85"
    },
    {
        "Hydranten Nr": "H16766",
        "UTM Koord. Rechts": "572977.15",
        "UTM Koord. Hoch": "5934759.686",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bergmannring",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H17156",
        "UTM Koord. Rechts": "572040.347",
        "UTM Koord. Hoch": "5933794.627",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Weddestraße",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H17148",
        "UTM Koord. Rechts": "572094.485",
        "UTM Koord. Hoch": "5933797.275",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Stengelestraße",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H17160",
        "UTM Koord. Rechts": "571989.069",
        "UTM Koord. Hoch": "5933869.971",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Weddestraße",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H6411",
        "UTM Koord. Rechts": "574267.12",
        "UTM Koord. Hoch": "5935688.428",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "70"
    },
    {
        "Hydranten Nr": "H8278",
        "UTM Koord. Rechts": "576713.434",
        "UTM Koord. Hoch": "5930554.642",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Lohbrügge",
        "Straßenname": "Asbrookdamm",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H13028",
        "UTM Koord. Rechts": "572592.987",
        "UTM Koord. Hoch": "5930149.727",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "KLGV.604 Moorfleet",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H14340",
        "UTM Koord. Rechts": "574428.772",
        "UTM Koord. Hoch": "5932850.349",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Papyrusweg",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H16627",
        "UTM Koord. Rechts": "574050.725",
        "UTM Koord. Hoch": "5933086.668",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archenholzstraße",
        "HsNr.": "50"
    },
    {
        "Hydranten Nr": "H16669",
        "UTM Koord. Rechts": "573342.836",
        "UTM Koord. Hoch": "5934898.722",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Legienstraße",
        "HsNr.": "242"
    },
    {
        "Hydranten Nr": "H18402",
        "UTM Koord. Rechts": "572333.6",
        "UTM Koord. Hoch": "5933320.614",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "380"
    },
    {
        "Hydranten Nr": "H14273",
        "UTM Koord. Rechts": "574004.555",
        "UTM Koord. Hoch": "5932486.569",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Mühlenweg",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H2909",
        "UTM Koord. Rechts": "574688.346",
        "UTM Koord. Hoch": "5930180.218",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Billwerder Billdeich",
        "HsNr.": "126"
    },
    {
        "Hydranten Nr": "H10935",
        "UTM Koord. Rechts": "572467.601",
        "UTM Koord. Hoch": "5927599.268",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Allermöhe",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "377"
    },
    {
        "Hydranten Nr": "H10937",
        "UTM Koord. Rechts": "572444.644",
        "UTM Koord. Hoch": "5927706.885",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Allermöhe",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "371"
    },
    {
        "Hydranten Nr": "H12202",
        "UTM Koord. Rechts": "571841.627",
        "UTM Koord. Hoch": "5929960.387",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Hein-Baxmann-Stieg",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H11029",
        "UTM Koord. Rechts": "571064.759",
        "UTM Koord. Hoch": "5929582.593",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Holzhafenufer",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H11061",
        "UTM Koord. Rechts": "571419.657",
        "UTM Koord. Hoch": "5930003.42",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Sandwisch",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H5928",
        "UTM Koord. Rechts": "572426.829",
        "UTM Koord. Hoch": "5935149.654",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Stoltenstraße",
        "HsNr.": "60"
    },
    {
        "Hydranten Nr": "H17831",
        "UTM Koord. Rechts": "571060.226",
        "UTM Koord. Hoch": "5934918.734",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "144"
    },
    {
        "Hydranten Nr": "H17770",
        "UTM Koord. Rechts": "571091.059",
        "UTM Koord. Hoch": "5934538.856",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Weg",
        "HsNr.": "192"
    },
    {
        "Hydranten Nr": "H10939",
        "UTM Koord. Rechts": "572415.68",
        "UTM Koord. Hoch": "5927909.011",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "356"
    },
    {
        "Hydranten Nr": "H18316",
        "UTM Koord. Rechts": "571648.449",
        "UTM Koord. Hoch": "5933351.905",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Daniel-Bartels-Weg",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H11955",
        "UTM Koord. Rechts": "571399.242",
        "UTM Koord. Hoch": "5931618.871",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "55"
    },
    {
        "Hydranten Nr": "H12433",
        "UTM Koord. Rechts": "572350.76",
        "UTM Koord. Hoch": "5930128.947",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H13161",
        "UTM Koord. Rechts": "570885.666",
        "UTM Koord. Hoch": "5931438.049",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "KLGV.117 Tiefstack-Süd",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H6346",
        "UTM Koord. Rechts": "571653.176",
        "UTM Koord. Hoch": "5934882.817",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "63"
    },
    {
        "Hydranten Nr": "H15912",
        "UTM Koord. Rechts": "572315.432",
        "UTM Koord. Hoch": "5934306.308",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "52"
    },
    {
        "Hydranten Nr": "H14271",
        "UTM Koord. Rechts": "573985.565",
        "UTM Koord. Hoch": "5932389.615",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Mühlenweg",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H2910",
        "UTM Koord. Rechts": "574599.32",
        "UTM Koord. Hoch": "5930296.906",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Billwerder Billdeich",
        "HsNr.": "116"
    },
    {
        "Hydranten Nr": "H15756",
        "UTM Koord. Rechts": "573977.749",
        "UTM Koord. Hoch": "5935563.458",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Bärenhäuterweg",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H13599",
        "UTM Koord. Rechts": "572474.215",
        "UTM Koord. Hoch": "5928508.866",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Amandus-Stubbe-Straße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H15696",
        "UTM Koord. Rechts": "571763.116",
        "UTM Koord. Hoch": "5934472.87",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H13596",
        "UTM Koord. Rechts": "572656.726",
        "UTM Koord. Hoch": "5928230.787",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Amandus-Stubbe-Straße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H12976",
        "UTM Koord. Rechts": "572085.118",
        "UTM Koord. Hoch": "5931000.947",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H10938",
        "UTM Koord. Rechts": "572422.486",
        "UTM Koord. Hoch": "5927812.331",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Allermöhe",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "359"
    },
    {
        "Hydranten Nr": "H4861",
        "UTM Koord. Rechts": "571496.758",
        "UTM Koord. Hoch": "5932401.03",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H11034",
        "UTM Koord. Rechts": "571216.693",
        "UTM Koord. Hoch": "5929980.251",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "129"
    },
    {
        "Hydranten Nr": "H18818",
        "UTM Koord. Rechts": "571789.292",
        "UTM Koord. Hoch": "5933160.138",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Rampe",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H10962",
        "UTM Koord. Rechts": "571623.626",
        "UTM Koord. Hoch": "5928485.158",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Schleusenweg",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H11014",
        "UTM Koord. Rechts": "571563.108",
        "UTM Koord. Hoch": "5929551.471",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Kirchenweg",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "H11008",
        "UTM Koord. Rechts": "571388.302",
        "UTM Koord. Hoch": "5929397.048",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "191"
    },
    {
        "Hydranten Nr": "H14233",
        "UTM Koord. Rechts": "574383.262",
        "UTM Koord. Hoch": "5932278.692",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kapellenstraße",
        "HsNr.": "63"
    },
    {
        "Hydranten Nr": "H11947",
        "UTM Koord. Rechts": "574218.551",
        "UTM Koord. Hoch": "5932381.911",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kapellenstraße",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H15752",
        "UTM Koord. Rechts": "573932.944",
        "UTM Koord. Hoch": "5935372.158",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schneewittchenweg",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H10838",
        "UTM Koord. Rechts": "575597.997",
        "UTM Koord. Hoch": "5933687.553",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Koolbargenredder",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H10724",
        "UTM Koord. Rechts": "571498.752",
        "UTM Koord. Hoch": "5928976.363",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "231"
    },
    {
        "Hydranten Nr": "H18040",
        "UTM Koord. Rechts": "571373.413",
        "UTM Koord. Hoch": "5934101.421",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hertogestraße",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H13058",
        "UTM Koord. Rechts": "571466.484",
        "UTM Koord. Hoch": "5931074.345",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H11784",
        "UTM Koord. Rechts": "575022.915",
        "UTM Koord. Hoch": "5933040.859",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Steinkamp",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H11022",
        "UTM Koord. Rechts": "571313.021",
        "UTM Koord. Hoch": "5929376.067",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "191"
    },
    {
        "Hydranten Nr": "H12992",
        "UTM Koord. Rechts": "572669.813",
        "UTM Koord. Hoch": "5930293.858",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Alter Landweg",
        "HsNr.": "44"
    },
    {
        "Hydranten Nr": "H14242",
        "UTM Koord. Rechts": "574177.499",
        "UTM Koord. Hoch": "5932206.948",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Arndesstieg",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H10973",
        "UTM Koord. Rechts": "571678.03",
        "UTM Koord. Hoch": "5928770.76",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Brennerhof",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H4022",
        "UTM Koord. Rechts": "577453.551",
        "UTM Koord. Hoch": "5932599.135",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Stormarnstraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H10722",
        "UTM Koord. Rechts": "571557.337",
        "UTM Koord. Hoch": "5928780.966",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "245"
    },
    {
        "Hydranten Nr": "H7791",
        "UTM Koord. Rechts": "576376.886",
        "UTM Koord. Hoch": "5931607.15",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Lietbargredder",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H17371",
        "UTM Koord. Rechts": "573744.125",
        "UTM Koord. Hoch": "5931983.339",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rote Brücke",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H6465",
        "UTM Koord. Rechts": "573994.379",
        "UTM Koord. Hoch": "5935251.934",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rosenrotweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H8295",
        "UTM Koord. Rechts": "576114.077",
        "UTM Koord. Hoch": "5931297.834",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kandinskyallee",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H10911",
        "UTM Koord. Rechts": "575615.148",
        "UTM Koord. Hoch": "5933259.109",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "248"
    },
    {
        "Hydranten Nr": "H16473",
        "UTM Koord. Rechts": "574488.831",
        "UTM Koord. Hoch": "5933024.709",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Pergamentweg",
        "HsNr.": "52"
    },
    {
        "Hydranten Nr": "H17372",
        "UTM Koord. Rechts": "574241.363",
        "UTM Koord. Hoch": "5931971.958",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Weg",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H10977",
        "UTM Koord. Rechts": "571716.017",
        "UTM Koord. Hoch": "5929052.79",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Brennerhof",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H12968",
        "UTM Koord. Rechts": "573600.769",
        "UTM Koord. Hoch": "5929587.171",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Dweerlandweg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H11005",
        "UTM Koord. Rechts": "575339.272",
        "UTM Koord. Hoch": "5932477.956",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sonnenland",
        "HsNr.": "54"
    },
    {
        "Hydranten Nr": "H10742",
        "UTM Koord. Rechts": "571060.168",
        "UTM Koord. Hoch": "5928534.637",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Hauptdeich",
        "HsNr.": "46"
    },
    {
        "Hydranten Nr": "H11720",
        "UTM Koord. Rechts": "575801.64",
        "UTM Koord. Hoch": "5933039.08",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kaltenbergen",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H13098",
        "UTM Koord. Rechts": "571569.208",
        "UTM Koord. Hoch": "5931475.175",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H10740",
        "UTM Koord. Rechts": "571218.549",
        "UTM Koord. Hoch": "5928504.439",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Hauptdeich",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H16667",
        "UTM Koord. Rechts": "573437.922",
        "UTM Koord. Hoch": "5934859.927",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Everlingweg",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H8269",
        "UTM Koord. Rechts": "576340.536",
        "UTM Koord. Hoch": "5930878.322",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Asbrookdamm",
        "HsNr.": "38"
    },
    {
        "Hydranten Nr": "H10858",
        "UTM Koord. Rechts": "575106.245",
        "UTM Koord. Hoch": "5933584.516",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Salomon-Petri-Ring",
        "HsNr.": "65"
    },
    {
        "Hydranten Nr": "H11820",
        "UTM Koord. Rechts": "575473.394",
        "UTM Koord. Hoch": "5932496.453",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sonnenland",
        "HsNr.": "81"
    },
    {
        "Hydranten Nr": "H11754",
        "UTM Koord. Rechts": "575605.146",
        "UTM Koord. Hoch": "5932985.773",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Tinnumer Weg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H12277",
        "UTM Koord. Rechts": "570975.269",
        "UTM Koord. Hoch": "5930987.605",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H16623",
        "UTM Koord. Rechts": "573228.971",
        "UTM Koord. Hoch": "5934331.099",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fischadlerstieg",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H15954",
        "UTM Koord. Rechts": "574198.283",
        "UTM Koord. Hoch": "5934851.907",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hasenbanckweg",
        "HsNr.": "58"
    },
    {
        "Hydranten Nr": "H15974",
        "UTM Koord. Rechts": "574081.008",
        "UTM Koord. Hoch": "5934509.018",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hasenbanckweg",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H15976",
        "UTM Koord. Rechts": "574061.406",
        "UTM Koord. Hoch": "5934433.407",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hasenbanckweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H16531",
        "UTM Koord. Rechts": "574780.544",
        "UTM Koord. Hoch": "5933293.356",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Baxmannweg",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H16544",
        "UTM Koord. Rechts": "574459.283",
        "UTM Koord. Hoch": "5933431.658",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archenholzstraße",
        "HsNr.": "73"
    },
    {
        "Hydranten Nr": "H16478",
        "UTM Koord. Rechts": "574549.706",
        "UTM Koord. Hoch": "5933066.22",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Cottaweg",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H8798",
        "UTM Koord. Rechts": "575946.764",
        "UTM Koord. Hoch": "5931278.504",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Godenwind",
        "HsNr.": "58"
    },
    {
        "Hydranten Nr": "H17975",
        "UTM Koord. Rechts": "574747.729",
        "UTM Koord. Hoch": "5931985.107",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Marktstraße",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H8283",
        "UTM Koord. Rechts": "576240.616",
        "UTM Koord. Hoch": "5931124.992",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Grenzdamm",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H18006",
        "UTM Koord. Rechts": "575127.555",
        "UTM Koord. Hoch": "5931657.602",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "146"
    },
    {
        "Hydranten Nr": "H16619",
        "UTM Koord. Rechts": "573383.511",
        "UTM Koord. Hoch": "5934466.671",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sturmvogelweg",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H8960",
        "UTM Koord. Rechts": "575595.803",
        "UTM Koord. Hoch": "5931276.918",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "157"
    },
    {
        "Hydranten Nr": "H8956",
        "UTM Koord. Rechts": "575846.589",
        "UTM Koord. Hoch": "5931142.641",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "183"
    },
    {
        "Hydranten Nr": "H16674",
        "UTM Koord. Rechts": "573256.667",
        "UTM Koord. Hoch": "5934962.058",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Legienstraße",
        "HsNr.": "251"
    },
    {
        "Hydranten Nr": "H11940",
        "UTM Koord. Rechts": "572358.106",
        "UTM Koord. Hoch": "5931384.578",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H7542",
        "UTM Koord. Rechts": "572372.321",
        "UTM Koord. Hoch": "5931235.534",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Moorfleeter Straße",
        "HsNr.": "46"
    },
    {
        "Hydranten Nr": "H7742",
        "UTM Koord. Rechts": "576192.208",
        "UTM Koord. Hoch": "5931551.216",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "48"
    },
    {
        "Hydranten Nr": "H16025",
        "UTM Koord. Rechts": "573548.429",
        "UTM Koord. Hoch": "5934114.3",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "116"
    },
    {
        "Hydranten Nr": "H6430",
        "UTM Koord. Rechts": "573806.574",
        "UTM Koord. Hoch": "5935558.968",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Gänselieselweg",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H15851",
        "UTM Koord. Rechts": "574052.217",
        "UTM Koord. Hoch": "5932693.954",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "96"
    },
    {
        "Hydranten Nr": "H7533",
        "UTM Koord. Rechts": "572589.093",
        "UTM Koord. Hoch": "5931851.521",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Moorfleeter Straße",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H10910",
        "UTM Koord. Rechts": "575636.641",
        "UTM Koord. Hoch": "5933287.428",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "251"
    },
    {
        "Hydranten Nr": "H13148",
        "UTM Koord. Rechts": "570870.85",
        "UTM Koord. Hoch": "5931163.747",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H14406",
        "UTM Koord. Rechts": "573144.632",
        "UTM Koord. Hoch": "5931586.74",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "85"
    },
    {
        "Hydranten Nr": "H11310",
        "UTM Koord. Rechts": "576246.076",
        "UTM Koord. Hoch": "5932167.397",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hollkoppelweg",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H19310",
        "UTM Koord. Rechts": "573431.157",
        "UTM Koord. Hoch": "5931165.554",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Unterer Landweg",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "H11868",
        "UTM Koord. Rechts": "574069.446",
        "UTM Koord. Hoch": "5935202.083",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rosenrotweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H6564",
        "UTM Koord. Rechts": "573421.076",
        "UTM Koord. Hoch": "5935442.807",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Dannerallee",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H10915",
        "UTM Koord. Rechts": "575515.707",
        "UTM Koord. Hoch": "5933177.409",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "232"
    },
    {
        "Hydranten Nr": "H10918",
        "UTM Koord. Rechts": "575399.61",
        "UTM Koord. Hoch": "5933095.577",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "217"
    },
    {
        "Hydranten Nr": "H9035",
        "UTM Koord. Rechts": "575874.18",
        "UTM Koord. Hoch": "5931981.149",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Edvard-Munch-Straße",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H8996",
        "UTM Koord. Rechts": "575394.02",
        "UTM Koord. Hoch": "5931698.387",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Haseneck",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H18356",
        "UTM Koord. Rechts": "572494.72",
        "UTM Koord. Hoch": "5933106.706",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Am Schiffbeker Berg",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H21206",
        "UTM Koord. Rechts": "573582.989",
        "UTM Koord. Hoch": "5931683.508",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Zinkhüttenweg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H6824",
        "UTM Koord. Rechts": "572795.527",
        "UTM Koord. Hoch": "5934812.682",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "59"
    },
    {
        "Hydranten Nr": "H16559",
        "UTM Koord. Rechts": "574726.403",
        "UTM Koord. Hoch": "5933196.195",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Merkenstraße",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H16192",
        "UTM Koord. Rechts": "573197.409",
        "UTM Koord. Hoch": "5933167.002",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Nathstieg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H16273",
        "UTM Koord. Rechts": "573739.034",
        "UTM Koord. Hoch": "5932596.287",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schleemer Weg",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H19525",
        "UTM Koord. Rechts": "573644.905",
        "UTM Koord. Hoch": "5932090.876",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "270"
    },
    {
        "Hydranten Nr": "H10952",
        "UTM Koord. Rechts": "575417.032",
        "UTM Koord. Hoch": "5933403.551",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schlangenkoppel",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H17033",
        "UTM Koord. Rechts": "572512.005",
        "UTM Koord. Hoch": "5933591.954",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "87"
    },
    {
        "Hydranten Nr": "H16132",
        "UTM Koord. Rechts": "573747.001",
        "UTM Koord. Hoch": "5933373.791",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Weg",
        "HsNr.": "50"
    },
    {
        "Hydranten Nr": "H16899",
        "UTM Koord. Rechts": "573186.291",
        "UTM Koord. Hoch": "5933436.688",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "55"
    },
    {
        "Hydranten Nr": "H16683",
        "UTM Koord. Rechts": "573505.506",
        "UTM Koord. Hoch": "5935016.963",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "116"
    },
    {
        "Hydranten Nr": "H15676",
        "UTM Koord. Rechts": "573743.102",
        "UTM Koord. Hoch": "5935355.259",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schneewittchenweg",
        "HsNr.": "50"
    },
    {
        "Hydranten Nr": "H7611",
        "UTM Koord. Rechts": "572656.503",
        "UTM Koord. Hoch": "5931295.81",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H10984",
        "UTM Koord. Rechts": "575017.856",
        "UTM Koord. Hoch": "5933233.913",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Höhe",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H10324",
        "UTM Koord. Rechts": "576326.825",
        "UTM Koord. Hoch": "5932828.258",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Lister Weg",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H10997",
        "UTM Koord. Rechts": "575156.157",
        "UTM Koord. Hoch": "5933068.932",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Heinrich-Schulte-Höhe",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "H16755",
        "UTM Koord. Rechts": "572807.455",
        "UTM Koord. Hoch": "5934538.748",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Am Horner Moor",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H7567",
        "UTM Koord. Rechts": "572212.125",
        "UTM Koord. Hoch": "5930961.224",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Moorfleeter Straße",
        "HsNr.": "52"
    },
    {
        "Hydranten Nr": "H12959",
        "UTM Koord. Rechts": "572584.392",
        "UTM Koord. Hoch": "5930682.885",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H12439",
        "UTM Koord. Rechts": "572228.525",
        "UTM Koord. Hoch": "5930326.444",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "63"
    },
    {
        "Hydranten Nr": "H19317",
        "UTM Koord. Rechts": "573236.728",
        "UTM Koord. Hoch": "5930945.572",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Unterer Landweg",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H15956",
        "UTM Koord. Rechts": "574024.493",
        "UTM Koord. Hoch": "5934900.807",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Daniel-Frese-Straße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16936",
        "UTM Koord. Rechts": "572935.95",
        "UTM Koord. Hoch": "5933348.326",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kuriergang",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H15840",
        "UTM Koord. Rechts": "574531.577",
        "UTM Koord. Hoch": "5932767.347",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "132"
    },
    {
        "Hydranten Nr": "H14326",
        "UTM Koord. Rechts": "574247.416",
        "UTM Koord. Hoch": "5933016.137",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Pergamentweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H7807",
        "UTM Koord. Rechts": "576397.187",
        "UTM Koord. Hoch": "5931973.301",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Große Holl",
        "HsNr.": "64"
    },
    {
        "Hydranten Nr": "H10700",
        "UTM Koord. Rechts": "576068.126",
        "UTM Koord. Hoch": "5931721.956",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oskar-Schlemmer-Straße",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H17167",
        "UTM Koord. Rechts": "571948.896",
        "UTM Koord. Hoch": "5933935.277",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Rampe",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H10715",
        "UTM Koord. Rechts": "573580.042",
        "UTM Koord. Hoch": "5931504.26",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "107"
    },
    {
        "Hydranten Nr": "H16342",
        "UTM Koord. Rechts": "573658.056",
        "UTM Koord. Hoch": "5932742.094",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "61"
    },
    {
        "Hydranten Nr": "H6779",
        "UTM Koord. Rechts": "573789.431",
        "UTM Koord. Hoch": "5932833.053",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schleemer Weg",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H16218",
        "UTM Koord. Rechts": "573225.845",
        "UTM Koord. Hoch": "5933062.369",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Lorenzenweg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16267",
        "UTM Koord. Rechts": "573102.141",
        "UTM Koord. Hoch": "5932898.436",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H16269",
        "UTM Koord. Rechts": "573173.128",
        "UTM Koord. Hoch": "5932823.182",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "66"
    },
    {
        "Hydranten Nr": "H10799",
        "UTM Koord. Rechts": "576160.284",
        "UTM Koord. Hoch": "5933115.633",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "122"
    },
    {
        "Hydranten Nr": "H19526",
        "UTM Koord. Rechts": "573637.958",
        "UTM Koord. Hoch": "5932000.56",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "277"
    },
    {
        "Hydranten Nr": "H11231",
        "UTM Koord. Rechts": "573757.379",
        "UTM Koord. Hoch": "5931619.737",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "323"
    },
    {
        "Hydranten Nr": "H10706",
        "UTM Koord. Rechts": "573481.779",
        "UTM Koord. Hoch": "5931844.309",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Rote Brücke",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H16019",
        "UTM Koord. Rechts": "573672.03",
        "UTM Koord. Hoch": "5934359.413",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "144"
    },
    {
        "Hydranten Nr": "H16758",
        "UTM Koord. Rechts": "572942.353",
        "UTM Koord. Hoch": "5934514.624",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Am Horner Moor",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H6493",
        "UTM Koord. Rechts": "573871.925",
        "UTM Koord. Hoch": "5934948.559",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Similiberg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H16618",
        "UTM Koord. Rechts": "573468.234",
        "UTM Koord. Hoch": "5934423.076",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sturmvogelweg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H16811",
        "UTM Koord. Rechts": "573077.698",
        "UTM Koord. Hoch": "5934881.681",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Querkamp",
        "HsNr.": "69"
    },
    {
        "Hydranten Nr": "H16832",
        "UTM Koord. Rechts": "572406.72",
        "UTM Koord. Hoch": "5934254.91",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "83"
    },
    {
        "Hydranten Nr": "H15877",
        "UTM Koord. Rechts": "571593.737",
        "UTM Koord. Hoch": "5931938.168",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H11738",
        "UTM Koord. Rechts": "575525.311",
        "UTM Koord. Hoch": "5932754.974",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinfurther Allee",
        "HsNr.": "44"
    },
    {
        "Hydranten Nr": "H15821",
        "UTM Koord. Rechts": "574537.759",
        "UTM Koord. Hoch": "5932666.447",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Brockhausweg",
        "HsNr.": "84"
    },
    {
        "Hydranten Nr": "H15804",
        "UTM Koord. Rechts": "574138.291",
        "UTM Koord. Hoch": "5932583.16",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oberschleems",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H16221",
        "UTM Koord. Rechts": "573895.561",
        "UTM Koord. Hoch": "5933305.995",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Liebezeitstraße",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H11808",
        "UTM Koord. Rechts": "574924.072",
        "UTM Koord. Hoch": "5932582.97",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Landjägerstieg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H11790",
        "UTM Koord. Rechts": "574934.925",
        "UTM Koord. Hoch": "5932834.817",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "162"
    },
    {
        "Hydranten Nr": "H5460",
        "UTM Koord. Rechts": "575192.219",
        "UTM Koord. Hoch": "5932947.779",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "191"
    },
    {
        "Hydranten Nr": "H16761",
        "UTM Koord. Rechts": "572918.642",
        "UTM Koord. Hoch": "5934645.7",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Zürnerweg",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H12239",
        "UTM Koord. Rechts": "571921.4",
        "UTM Koord. Hoch": "5930027.831",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "47"
    },
    {
        "Hydranten Nr": "H8926",
        "UTM Koord. Rechts": "575816.982",
        "UTM Koord. Hoch": "5931545.377",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H913",
        "UTM Koord. Rechts": "572788.244",
        "UTM Koord. Hoch": "5934944.775",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Heinrich-Kaufmann-Ring",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H16949",
        "UTM Koord. Rechts": "572698.95",
        "UTM Koord. Hoch": "5933457.008",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Legienstraße",
        "HsNr.": "47"
    },
    {
        "Hydranten Nr": "H17029",
        "UTM Koord. Rechts": "572547.788",
        "UTM Koord. Hoch": "5933534.099",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonring",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H11758",
        "UTM Koord. Rechts": "575582.052",
        "UTM Koord. Hoch": "5932891.076",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Morsumer Weg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H5823",
        "UTM Koord. Rechts": "572930.19",
        "UTM Koord. Hoch": "5935234.128",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rudolf-Roß-Allee",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H16081",
        "UTM Koord. Rechts": "573625.078",
        "UTM Koord. Hoch": "5933639.165",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Höhe",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H16638",
        "UTM Koord. Rechts": "573181.704",
        "UTM Koord. Hoch": "5933798.787",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kattensteert",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H17737",
        "UTM Koord. Rechts": "574361.72",
        "UTM Koord. Hoch": "5931988.541",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "43"
    },
    {
        "Hydranten Nr": "H17974",
        "UTM Koord. Rechts": "574435.888",
        "UTM Koord. Hoch": "5932009.804",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Lohkamp",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H17358",
        "UTM Koord. Rechts": "573903.708",
        "UTM Koord. Hoch": "5931985.465",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rotenbrückenweg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H10710",
        "UTM Koord. Rechts": "573472.032",
        "UTM Koord. Hoch": "5931779.785",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "93"
    },
    {
        "Hydranten Nr": "H15807",
        "UTM Koord. Rechts": "574181.502",
        "UTM Koord. Hoch": "5932682.963",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oberschleems",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H10347",
        "UTM Koord. Rechts": "576301.556",
        "UTM Koord. Hoch": "5932929.6",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Lister Weg",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H10961",
        "UTM Koord. Rechts": "575527.939",
        "UTM Koord. Hoch": "5933468.276",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Ohlwurt",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H16278",
        "UTM Koord. Rechts": "573736.633",
        "UTM Koord. Hoch": "5932438.303",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Schanze",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H8811",
        "UTM Koord. Rechts": "575871.385",
        "UTM Koord. Hoch": "5931418.813",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Münterweg",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H17725",
        "UTM Koord. Rechts": "574637.8641",
        "UTM Koord. Hoch": "5931825.041",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "79"
    },
    {
        "Hydranten Nr": "H7775",
        "UTM Koord. Rechts": "576315.574",
        "UTM Koord. Hoch": "5931239.653",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Bienenbusch",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H7787",
        "UTM Koord. Rechts": "576240.428",
        "UTM Koord. Hoch": "5931648.392",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kandinskyallee",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H7794",
        "UTM Koord. Rechts": "576409.11",
        "UTM Koord. Hoch": "5931681.513",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Große Holl",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H16624",
        "UTM Koord. Rechts": "573182.02",
        "UTM Koord. Hoch": "5934241.887",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fischadlerstieg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16610",
        "UTM Koord. Rechts": "573834.529",
        "UTM Koord. Hoch": "5934901.447",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Similiberg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H9957",
        "UTM Koord. Rechts": "572707.221",
        "UTM Koord. Hoch": "5931716.345",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "63"
    },
    {
        "Hydranten Nr": "H12272",
        "UTM Koord. Rechts": "571297.169",
        "UTM Koord. Hoch": "5930632.854",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H16941",
        "UTM Koord. Rechts": "572819.26",
        "UTM Koord. Hoch": "5933404.113",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kuriergang",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H18416",
        "UTM Koord. Rechts": "572616.411",
        "UTM Koord. Hoch": "5933157.459",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16740",
        "UTM Koord. Rechts": "572318.122",
        "UTM Koord. Hoch": "5934459.436",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kroogblöcke",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H14306",
        "UTM Koord. Rechts": "574657.997",
        "UTM Koord. Hoch": "5932809.378",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "149"
    },
    {
        "Hydranten Nr": "H14312",
        "UTM Koord. Rechts": "574329.596",
        "UTM Koord. Hoch": "5932760.358",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "121"
    },
    {
        "Hydranten Nr": "H16260",
        "UTM Koord. Rechts": "573724.529",
        "UTM Koord. Hoch": "5932368.118",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "121"
    },
    {
        "Hydranten Nr": "H19523",
        "UTM Koord. Rechts": "573630.341",
        "UTM Koord. Hoch": "5932194.867",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "262"
    },
    {
        "Hydranten Nr": "H16092",
        "UTM Koord. Rechts": "573416.273",
        "UTM Koord. Hoch": "5933719.088",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Höhe",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H12415",
        "UTM Koord. Rechts": "573609.2",
        "UTM Koord. Hoch": "5933960.292",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Merkatorweg",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H18426",
        "UTM Koord. Rechts": "574773.991",
        "UTM Koord. Hoch": "5932347.333",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Pastor-Manzke-Weg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H11730",
        "UTM Koord. Rechts": "575805.706",
        "UTM Koord. Hoch": "5932909.823",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "81"
    },
    {
        "Hydranten Nr": "H16703",
        "UTM Koord. Rechts": "572809.833",
        "UTM Koord. Hoch": "5934323.388",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Speckenreye",
        "HsNr.": "46"
    },
    {
        "Hydranten Nr": "H9554",
        "UTM Koord. Rechts": "576071.168",
        "UTM Koord. Hoch": "5932693.898",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Knivsbergweg",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H15800",
        "UTM Koord. Rechts": "573946.692",
        "UTM Koord. Hoch": "5932619.352",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kapellenstraße",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H17166",
        "UTM Koord. Rechts": "572012.401",
        "UTM Koord. Hoch": "5933946.842",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bömelburgweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H18433",
        "UTM Koord. Rechts": "572556.113",
        "UTM Koord. Hoch": "5932916.654",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kolumbusstraße",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H16313",
        "UTM Koord. Rechts": "573500.28",
        "UTM Koord. Hoch": "5932553.756",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "106"
    },
    {
        "Hydranten Nr": "H14279",
        "UTM Koord. Rechts": "574059.294",
        "UTM Koord. Hoch": "5932446.172",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oberschleems",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H11349",
        "UTM Koord. Rechts": "573746.793",
        "UTM Koord. Hoch": "5933202.276",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archenholzstraße",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H16007",
        "UTM Koord. Rechts": "573919.144",
        "UTM Koord. Hoch": "5934841.983",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "186"
    },
    {
        "Hydranten Nr": "H16657",
        "UTM Koord. Rechts": "573372.502",
        "UTM Koord. Hoch": "5933740.162",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "82"
    },
    {
        "Hydranten Nr": "H16285",
        "UTM Koord. Rechts": "573641.635",
        "UTM Koord. Hoch": "5932517.252",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Schanze",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16573",
        "UTM Koord. Rechts": "573657.711",
        "UTM Koord. Hoch": "5934519.353",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Froschkönigweg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H15828",
        "UTM Koord. Rechts": "574748.816",
        "UTM Koord. Hoch": "5932710.107",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Marktstraße",
        "HsNr.": "83"
    },
    {
        "Hydranten Nr": "H5834",
        "UTM Koord. Rechts": "573004.407",
        "UTM Koord. Hoch": "5935028.207",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Ernst-Scherling-Weg",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H5815",
        "UTM Koord. Rechts": "572926.02",
        "UTM Koord. Hoch": "5934972.518",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rudolf-Roß-Allee",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H8260",
        "UTM Koord. Rechts": "576477.45",
        "UTM Koord. Hoch": "5931054.672",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Grenzdamm",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H18408",
        "UTM Koord. Rechts": "572393.734",
        "UTM Koord. Hoch": "5933258.6",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "392"
    },
    {
        "Hydranten Nr": "H16250",
        "UTM Koord. Rechts": "573795.19",
        "UTM Koord. Hoch": "5932284.409",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "127"
    },
    {
        "Hydranten Nr": "H16222",
        "UTM Koord. Rechts": "574015.585",
        "UTM Koord. Hoch": "5933296.276",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Liebezeitstraße",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H18008",
        "UTM Koord. Rechts": "571547.324",
        "UTM Koord. Hoch": "5934180.956",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bauerberg",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H14249",
        "UTM Koord. Rechts": "574162.084",
        "UTM Koord. Hoch": "5932354.223",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schöfferstieg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H10874",
        "UTM Koord. Rechts": "575521.674",
        "UTM Koord. Hoch": "5933589.144",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Koolbarg",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H16924",
        "UTM Koord. Rechts": "573033.002",
        "UTM Koord. Hoch": "5933834.314",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Ihlestraße",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H14331",
        "UTM Koord. Rechts": "574172.29",
        "UTM Koord. Hoch": "5932948.196",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schleemkoppel",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H355",
        "UTM Koord. Rechts": "575932.268",
        "UTM Koord. Hoch": "5932768.32",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Niebüllweg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H357",
        "UTM Koord. Rechts": "575925.242",
        "UTM Koord. Hoch": "5932709.101",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Niebüllweg",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H16045",
        "UTM Koord. Rechts": "573518.954",
        "UTM Koord. Hoch": "5933878.885",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Goldkoppel",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H18354",
        "UTM Koord. Rechts": "572685.135",
        "UTM Koord. Hoch": "5933003.82",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Am Schiffbeker Berg",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H17036",
        "UTM Koord. Rechts": "572375.825",
        "UTM Koord. Hoch": "5933726.367",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "77"
    },
    {
        "Hydranten Nr": "H11819",
        "UTM Koord. Rechts": "575416.314",
        "UTM Koord. Hoch": "5932431.274",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Erhard-Dressel-Bogen",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H8261",
        "UTM Koord. Rechts": "576547.147",
        "UTM Koord. Hoch": "5931009",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Grenzdamm",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H9032",
        "UTM Koord. Rechts": "575680.031",
        "UTM Koord. Hoch": "5931956.326",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H9082",
        "UTM Koord. Rechts": "575860.379",
        "UTM Koord. Hoch": "5932213.656",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Max-Pechstein-Straße",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H15966",
        "UTM Koord. Rechts": "574270.341",
        "UTM Koord. Hoch": "5934805.232",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hasenbanckweg",
        "HsNr.": "58"
    },
    {
        "Hydranten Nr": "H16026",
        "UTM Koord. Rechts": "573484.179",
        "UTM Koord. Hoch": "5933986.614",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "100"
    },
    {
        "Hydranten Nr": "H16630",
        "UTM Koord. Rechts": "573221.629",
        "UTM Koord. Hoch": "5933933.602",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Albatrosweg",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H11795",
        "UTM Koord. Rechts": "575263.278",
        "UTM Koord. Hoch": "5932612.042",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H9546",
        "UTM Koord. Rechts": "575966.949",
        "UTM Koord. Hoch": "5932610.116",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Glinder Au",
        "HsNr.": "95"
    },
    {
        "Hydranten Nr": "H17976",
        "UTM Koord. Rechts": "574869.433",
        "UTM Koord. Hoch": "5932044.19",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kapellenstraße",
        "HsNr.": "112"
    },
    {
        "Hydranten Nr": "H17374",
        "UTM Koord. Rechts": "574445.386",
        "UTM Koord. Hoch": "5931875.8",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Weg",
        "HsNr.": "44"
    },
    {
        "Hydranten Nr": "H8980",
        "UTM Koord. Rechts": "575315.023",
        "UTM Koord. Hoch": "5931705.084",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "119"
    },
    {
        "Hydranten Nr": "H10636",
        "UTM Koord. Rechts": "576227.904",
        "UTM Koord. Hoch": "5932996.697",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Keitumer Weg",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H16445",
        "UTM Koord. Rechts": "573867.994",
        "UTM Koord. Hoch": "5934799.709",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "180"
    },
    {
        "Hydranten Nr": "H12766",
        "UTM Koord. Rechts": "574093.795",
        "UTM Koord. Hoch": "5934109.156",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Daseweg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H16047",
        "UTM Koord. Rechts": "573675.622",
        "UTM Koord. Hoch": "5933819.217",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Goldkoppel",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H16129",
        "UTM Koord. Rechts": "573853.588",
        "UTM Koord. Hoch": "5933493.273",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Weg",
        "HsNr.": "58"
    },
    {
        "Hydranten Nr": "H19340",
        "UTM Koord. Rechts": "572935.934",
        "UTM Koord. Hoch": "5930551.127",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Alter Landweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H10871",
        "UTM Koord. Rechts": "575530.612",
        "UTM Koord. Hoch": "5933663.432",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schlangenkoppel",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H11777",
        "UTM Koord. Rechts": "575394.559",
        "UTM Koord. Hoch": "5932756.294",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schümannweg",
        "HsNr.": "46"
    },
    {
        "Hydranten Nr": "H21204",
        "UTM Koord. Rechts": "572205.932",
        "UTM Koord. Hoch": "5933842.823",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Grosseweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H13438",
        "UTM Koord. Rechts": "573830.951",
        "UTM Koord. Hoch": "5934177.45",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hogenbergkamp",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H19301",
        "UTM Koord. Rechts": "573295.871",
        "UTM Koord. Hoch": "5931877.981",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "92"
    },
    {
        "Hydranten Nr": "H16741",
        "UTM Koord. Rechts": "572386.952",
        "UTM Koord. Hoch": "5934315.435",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hasencleverstraße",
        "HsNr.": "36"
    },
    {
        "Hydranten Nr": "H10968",
        "UTM Koord. Rechts": "575439.879",
        "UTM Koord. Hoch": "5933532.354",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kantatenweg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H6485",
        "UTM Koord. Rechts": "573939.597",
        "UTM Koord. Hoch": "5934930.018",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "185"
    },
    {
        "Hydranten Nr": "H15955",
        "UTM Koord. Rechts": "574123.343",
        "UTM Koord. Hoch": "5934883.949",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Daniel-Frese-Straße",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H10346",
        "UTM Koord. Rechts": "576207.788",
        "UTM Koord. Hoch": "5932872.053",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kampener Stieg",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H16173",
        "UTM Koord. Rechts": "573272.28",
        "UTM Koord. Hoch": "5933549.766",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "62"
    },
    {
        "Hydranten Nr": "H20057",
        "UTM Koord. Rechts": "572094.767",
        "UTM Koord. Hoch": "5934352.109",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sandkamp",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H16647",
        "UTM Koord. Rechts": "573153.125",
        "UTM Koord. Hoch": "5933847.747",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kattensteert",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H16632",
        "UTM Koord. Rechts": "573129.927",
        "UTM Koord. Hoch": "5933978.706",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Haubentaucherweg",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H16600",
        "UTM Koord. Rechts": "573405.68",
        "UTM Koord. Hoch": "5933870.81",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "105"
    },
    {
        "Hydranten Nr": "H10904",
        "UTM Koord. Rechts": "575790.515",
        "UTM Koord. Hoch": "5933347.932",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Moosterhoop",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H7803",
        "UTM Koord. Rechts": "576288.905",
        "UTM Koord. Hoch": "5931973.117",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Große Holl",
        "HsNr.": "68"
    },
    {
        "Hydranten Nr": "H10738",
        "UTM Koord. Rechts": "573404.579",
        "UTM Koord. Hoch": "5931849.639",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "94"
    },
    {
        "Hydranten Nr": "H9052",
        "UTM Koord. Rechts": "575585.032",
        "UTM Koord. Hoch": "5931828.597",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "56"
    },
    {
        "Hydranten Nr": "H16530",
        "UTM Koord. Rechts": "574837.923",
        "UTM Koord. Hoch": "5933327.383",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Baxmannweg",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H17188",
        "UTM Koord. Rechts": "572040.977",
        "UTM Koord. Hoch": "5934060.18",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hasencleverstraße",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H5993",
        "UTM Koord. Rechts": "572542.233",
        "UTM Koord. Hoch": "5934440.768",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Audorfring",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H15763",
        "UTM Koord. Rechts": "572578.803",
        "UTM Koord. Hoch": "5929848.228",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "KLGV.604 Moorfleet",
        "HsNr.": "59"
    },
    {
        "Hydranten Nr": "H8769",
        "UTM Koord. Rechts": "575706.862",
        "UTM Koord. Hoch": "5931349.955",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "August-Macke-Weg",
        "HsNr.": "44"
    },
    {
        "Hydranten Nr": "H8777",
        "UTM Koord. Rechts": "575741.803",
        "UTM Koord. Hoch": "5931436.994",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "August-Macke-Weg",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H7771",
        "UTM Koord. Rechts": "576560.368",
        "UTM Koord. Hoch": "5931179.07",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rahewinkel",
        "HsNr.": "49"
    },
    {
        "Hydranten Nr": "H8802",
        "UTM Koord. Rechts": "576112.936",
        "UTM Koord. Hoch": "5931537.894",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "46"
    },
    {
        "Hydranten Nr": "H10931",
        "UTM Koord. Rechts": "575797.589",
        "UTM Koord. Hoch": "5933540.839",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Koolbarg",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H16411",
        "UTM Koord. Rechts": "573682.719",
        "UTM Koord. Hoch": "5934660.88",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Prinzenweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H16438",
        "UTM Koord. Rechts": "573757.84",
        "UTM Koord. Hoch": "5934842.569",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Zwergenstieg",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H16580",
        "UTM Koord. Rechts": "573593.249",
        "UTM Koord. Hoch": "5934482.466",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Zwergfalkenweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H16748",
        "UTM Koord. Rechts": "572997.996",
        "UTM Koord. Hoch": "5934333.08",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Redder",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H16238",
        "UTM Koord. Rechts": "574044.975",
        "UTM Koord. Hoch": "5933435.288",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Felginerweg",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H6424",
        "UTM Koord. Rechts": "573716.866",
        "UTM Koord. Hoch": "5935471.576",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Geißleinweg",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H16270",
        "UTM Koord. Rechts": "573250.753",
        "UTM Koord. Hoch": "5932765.332",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "67"
    },
    {
        "Hydranten Nr": "H16263",
        "UTM Koord. Rechts": "573036.497",
        "UTM Koord. Hoch": "5932889.549",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "50"
    },
    {
        "Hydranten Nr": "H16320",
        "UTM Koord. Rechts": "573255.929",
        "UTM Koord. Hoch": "5932739.381",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Billstedter Hauptstraße",
        "HsNr.": "74"
    },
    {
        "Hydranten Nr": "H16198",
        "UTM Koord. Rechts": "573125.698",
        "UTM Koord. Hoch": "5933208.836",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Nathstieg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16205",
        "UTM Koord. Rechts": "573130.837",
        "UTM Koord. Hoch": "5933264.337",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Nathstieg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H16325",
        "UTM Koord. Rechts": "573365.154",
        "UTM Koord. Hoch": "5932759.414",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hertelstieg",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H16323",
        "UTM Koord. Rechts": "573154.142",
        "UTM Koord. Hoch": "5932724.555",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Geesttwiete",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H14289",
        "UTM Koord. Rechts": "574350.996",
        "UTM Koord. Hoch": "5932432.791",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Druckerstraße",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H16360",
        "UTM Koord. Rechts": "573304.912",
        "UTM Koord. Hoch": "5932829.09",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H16356",
        "UTM Koord. Rechts": "573176.948",
        "UTM Koord. Hoch": "5932860.609",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16349",
        "UTM Koord. Rechts": "573343.706",
        "UTM Koord. Hoch": "5932827.82",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H16452",
        "UTM Koord. Rechts": "573995.318",
        "UTM Koord. Hoch": "5933180.843",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archenholzstraße",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H16334",
        "UTM Koord. Rechts": "572977.664",
        "UTM Koord. Hoch": "5932975.56",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H16384",
        "UTM Koord. Rechts": "573591.431",
        "UTM Koord. Hoch": "5932863.829",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Reclamstraße",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H878",
        "UTM Koord. Rechts": "573927.311",
        "UTM Koord. Hoch": "5934861.855",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "131"
    },
    {
        "Hydranten Nr": "H9524",
        "UTM Koord. Rechts": "576349.999",
        "UTM Koord. Hoch": "5933243.607",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H16405",
        "UTM Koord. Rechts": "573302.185",
        "UTM Koord. Hoch": "5932869.306",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H5828",
        "UTM Koord. Rechts": "573067.277",
        "UTM Koord. Hoch": "5935174.873",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Laufkötterweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H15677",
        "UTM Koord. Rechts": "573842.036",
        "UTM Koord. Hoch": "5935344.576",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schneewittchenweg",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H14294",
        "UTM Koord. Rechts": "574428.353",
        "UTM Koord. Hoch": "5932370.706",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Teubnerweg",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H16533",
        "UTM Koord. Rechts": "574840.579",
        "UTM Koord. Hoch": "5933270.406",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Baxmannstraße",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H11450",
        "UTM Koord. Rechts": "576004.668",
        "UTM Koord. Hoch": "5934350.893",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Barsbütteler Weg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H20914",
        "UTM Koord. Rechts": "574778.686",
        "UTM Koord. Hoch": "5935802.6",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hans-Rubbert-Straße",
        "HsNr.": "101"
    },
    {
        "Hydranten Nr": "H14215",
        "UTM Koord. Rechts": "574572.578",
        "UTM Koord. Hoch": "5932361.053",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Teubnerweg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H881",
        "UTM Koord. Rechts": "573974.292",
        "UTM Koord. Hoch": "5934837.705",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "160"
    },
    {
        "Hydranten Nr": "H19534",
        "UTM Koord. Rechts": "572910.595",
        "UTM Koord. Hoch": "5932208.324",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "59"
    },
    {
        "Hydranten Nr": "H12752",
        "UTM Koord. Rechts": "573909.142",
        "UTM Koord. Hoch": "5933880.612",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Höfnageleck",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H17405",
        "UTM Koord. Rechts": "571667.53",
        "UTM Koord. Hoch": "5932112.513",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Wöhlerstraße",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H13880",
        "UTM Koord. Rechts": "576241.288",
        "UTM Koord. Hoch": "5933992.813",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "KLGV.154 Merkenstraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H3880",
        "UTM Koord. Rechts": "571763.273",
        "UTM Koord. Hoch": "5932323.555",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Wöhlerstraße",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H18423",
        "UTM Koord. Rechts": "572023.143",
        "UTM Koord. Hoch": "5933622.802",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "329"
    },
    {
        "Hydranten Nr": "H6406",
        "UTM Koord. Rechts": "574131.247",
        "UTM Koord. Hoch": "5935632.178",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rautendeleinweg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H20375",
        "UTM Koord. Rechts": "572131.39",
        "UTM Koord. Hoch": "5931873.151",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H18249",
        "UTM Koord. Rechts": "571857.478",
        "UTM Koord. Hoch": "5934057.993",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Rampe",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H20856",
        "UTM Koord. Rechts": "571690.211",
        "UTM Koord. Hoch": "5932119.991",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Wöhlerstraße",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H21325",
        "UTM Koord. Rechts": "572247.836",
        "UTM Koord. Hoch": "5932390.021",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H6378",
        "UTM Koord. Rechts": "574262.547",
        "UTM Koord. Hoch": "5935784.994",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Aladinweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H7564",
        "UTM Koord. Rechts": "572533.633",
        "UTM Koord. Hoch": "5931766.543",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Werner-Siemens-Straße",
        "HsNr.": "57"
    },
    {
        "Hydranten Nr": "H20838",
        "UTM Koord. Rechts": "575025.523",
        "UTM Koord. Hoch": "5935598.156",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Wasserfenchelstieg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H18157",
        "UTM Koord. Rechts": "571477.826",
        "UTM Koord. Hoch": "5934021.907",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "171"
    },
    {
        "Hydranten Nr": "H10948",
        "UTM Koord. Rechts": "572066.552",
        "UTM Koord. Hoch": "5928322.421",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "309"
    },
    {
        "Hydranten Nr": "H6390",
        "UTM Koord. Rechts": "574232.713",
        "UTM Koord. Hoch": "5935699.135",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rübezahlstraße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H9066",
        "UTM Koord. Rechts": "576067.984",
        "UTM Koord. Hoch": "5931697.124",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oskar-Schlemmer-Straße",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H6337",
        "UTM Koord. Rechts": "571558.483",
        "UTM Koord. Hoch": "5935173.566",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "101"
    },
    {
        "Hydranten Nr": "H15915",
        "UTM Koord. Rechts": "574388.147",
        "UTM Koord. Hoch": "5935667.322",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dringsheide",
        "HsNr.": "50"
    },
    {
        "Hydranten Nr": "H16540",
        "UTM Koord. Rechts": "574318.198",
        "UTM Koord. Hoch": "5933398.908",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archenholzstraße",
        "HsNr.": "68"
    },
    {
        "Hydranten Nr": "H17825",
        "UTM Koord. Rechts": "571157.554",
        "UTM Koord. Hoch": "5934881.007",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "154"
    },
    {
        "Hydranten Nr": "H10986",
        "UTM Koord. Rechts": "571936.984",
        "UTM Koord. Hoch": "5929485.163",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Brennerhof",
        "HsNr.": "98"
    },
    {
        "Hydranten Nr": "H19510",
        "UTM Koord. Rechts": "572715.796",
        "UTM Koord. Hoch": "5932468.813",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "153"
    },
    {
        "Hydranten Nr": "H18377",
        "UTM Koord. Rechts": "572096.393",
        "UTM Koord. Hoch": "5933515.732",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "338"
    },
    {
        "Hydranten Nr": "H20842",
        "UTM Koord. Rechts": "575037.502",
        "UTM Koord. Hoch": "5935667.529",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Haferblöcken",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H3866",
        "UTM Koord. Rechts": "572110.769",
        "UTM Koord. Hoch": "5932226.274",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "41"
    },
    {
        "Hydranten Nr": "H11018",
        "UTM Koord. Rechts": "571741.853",
        "UTM Koord. Hoch": "5929795.624",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Kirchenweg",
        "HsNr.": "63"
    },
    {
        "Hydranten Nr": "H12403",
        "UTM Koord. Rechts": "570863.158",
        "UTM Koord. Hoch": "5934327.07",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H12234",
        "UTM Koord. Rechts": "571985.626",
        "UTM Koord. Hoch": "5929935.228",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "47"
    },
    {
        "Hydranten Nr": "H19489",
        "UTM Koord. Rechts": "571571.613",
        "UTM Koord. Hoch": "5932623.406",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H17081",
        "UTM Koord. Rechts": "572445.662",
        "UTM Koord. Hoch": "5933366.598",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Weddestraße",
        "HsNr.": "96"
    },
    {
        "Hydranten Nr": "H16613",
        "UTM Koord. Rechts": "573454.334",
        "UTM Koord. Hoch": "5934201.054",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Seeadlerstieg",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H12408",
        "UTM Koord. Rechts": "570824.1361",
        "UTM Koord. Hoch": "5934357.446",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H17003",
        "UTM Koord. Rechts": "572522.193",
        "UTM Koord. Hoch": "5933705.122",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Vierbergen",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H6166",
        "UTM Koord. Rechts": "571478.223",
        "UTM Koord. Hoch": "5935072.97",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Tribünenweg",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H11005",
        "UTM Koord. Rechts": "571413.84",
        "UTM Koord. Hoch": "5929299.588",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H13651",
        "UTM Koord. Rechts": "571714.172",
        "UTM Koord. Hoch": "5928561.723",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "271"
    },
    {
        "Hydranten Nr": "H18330",
        "UTM Koord. Rechts": "571949.624",
        "UTM Koord. Hoch": "5933505.02",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Marshallweg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H19324",
        "UTM Koord. Rechts": "572968.221",
        "UTM Koord. Hoch": "5930602.797",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Unterer Landweg",
        "HsNr.": "93"
    },
    {
        "Hydranten Nr": "H6363",
        "UTM Koord. Rechts": "571472.341",
        "UTM Koord. Hoch": "5934934.769",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rhiemsweg",
        "HsNr.": "83"
    },
    {
        "Hydranten Nr": "H11909",
        "UTM Koord. Rechts": "574994.312",
        "UTM Koord. Hoch": "5935376.472",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergredder",
        "HsNr.": "92"
    },
    {
        "Hydranten Nr": "H16013",
        "UTM Koord. Rechts": "573721.295",
        "UTM Koord. Hoch": "5934457.037",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "144"
    },
    {
        "Hydranten Nr": "H6419",
        "UTM Koord. Rechts": "573459.669",
        "UTM Koord. Hoch": "5935638.608",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Zwischen den Hecken",
        "HsNr.": "60"
    },
    {
        "Hydranten Nr": "H12342",
        "UTM Koord. Rechts": "572116.296",
        "UTM Koord. Hoch": "5929761.94",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Sandwisch",
        "HsNr.": "83"
    },
    {
        "Hydranten Nr": "H15960",
        "UTM Koord. Rechts": "574109.539",
        "UTM Koord. Hoch": "5935150.494",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rehkoppel",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H15944",
        "UTM Koord. Rechts": "574161.577",
        "UTM Koord. Hoch": "5935093.915",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Olberskamp",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H13121",
        "UTM Koord. Rechts": "571422.78",
        "UTM Koord. Hoch": "5931786.087",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H6330",
        "UTM Koord. Rechts": "571370.708",
        "UTM Koord. Hoch": "5935149.89",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Tribünenweg",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H17822",
        "UTM Koord. Rechts": "571236.194",
        "UTM Koord. Hoch": "5934845.562",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "166"
    },
    {
        "Hydranten Nr": "H17848",
        "UTM Koord. Rechts": "571440.324",
        "UTM Koord. Hoch": "5934771.122",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sievekingsallee",
        "HsNr.": "177"
    },
    {
        "Hydranten Nr": "H11677",
        "UTM Koord. Rechts": "574589.45",
        "UTM Koord. Hoch": "5935519.813",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H18256",
        "UTM Koord. Rechts": "571358.914",
        "UTM Koord. Hoch": "5933929.325",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bauerbergweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H18079",
        "UTM Koord. Rechts": "571752.898",
        "UTM Koord. Hoch": "5933794.844",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Nedderndorfer Weg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H10959",
        "UTM Koord. Rechts": "571694.269",
        "UTM Koord. Hoch": "5928497.73",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Brückenstraße",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H15985",
        "UTM Koord. Rechts": "573984.718",
        "UTM Koord. Hoch": "5934631.122",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Lindenbergkoppel",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H16012",
        "UTM Koord. Rechts": "573758.529",
        "UTM Koord. Hoch": "5934527.47",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "150"
    },
    {
        "Hydranten Nr": "H6401",
        "UTM Koord. Rechts": "573875.28",
        "UTM Koord. Hoch": "5935582.08",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sesamweg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H13103",
        "UTM Koord. Rechts": "571591.668",
        "UTM Koord. Hoch": "5931455.681",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H19819",
        "UTM Koord. Rechts": "573853.126",
        "UTM Koord. Hoch": "5930990.36",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Billwerder Billdeich",
        "HsNr.": "43"
    },
    {
        "Hydranten Nr": "H19823",
        "UTM Koord. Rechts": "573628.055",
        "UTM Koord. Hoch": "5931329.814",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Billwerder Billdeich",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H11680",
        "UTM Koord. Rechts": "575003.022",
        "UTM Koord. Hoch": "5935438.147",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergweg",
        "HsNr.": "44"
    },
    {
        "Hydranten Nr": "H7579",
        "UTM Koord. Rechts": "573021.723",
        "UTM Koord. Hoch": "5930897.113",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Porgesring",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H6823",
        "UTM Koord. Rechts": "572711.805",
        "UTM Koord. Hoch": "5934765.646",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "70"
    },
    {
        "Hydranten Nr": "H18050",
        "UTM Koord. Rechts": "571790.544",
        "UTM Koord. Hoch": "5933798.202",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Nedderndorfer Weg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H11671",
        "UTM Koord. Rechts": "574880.271",
        "UTM Koord. Hoch": "5935258.579",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Haferblöcken",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H13574",
        "UTM Koord. Rechts": "574562.719",
        "UTM Koord. Hoch": "5935426.178",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rispengrasweg",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H15928",
        "UTM Koord. Rechts": "574405.618",
        "UTM Koord. Hoch": "5935507.698",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Dringsheide",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H18087",
        "UTM Koord. Rechts": "571586.95",
        "UTM Koord. Hoch": "5933958.967",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "197"
    },
    {
        "Hydranten Nr": "H15961",
        "UTM Koord. Rechts": "574258.535",
        "UTM Koord. Hoch": "5935161.816",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hasenbanckweg",
        "HsNr.": "88"
    },
    {
        "Hydranten Nr": "H15988",
        "UTM Koord. Rechts": "573975.673",
        "UTM Koord. Hoch": "5934522.249",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Neddermeyerstieg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H16011",
        "UTM Koord. Rechts": "573806.859",
        "UTM Koord. Hoch": "5934627.384",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "164"
    },
    {
        "Hydranten Nr": "H11847",
        "UTM Koord. Rechts": "574969.027",
        "UTM Koord. Hoch": "5932136.678",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sonnenland",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H11841",
        "UTM Koord. Rechts": "575094.484",
        "UTM Koord. Hoch": "5932369.664",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sonnenland",
        "HsNr.": "38"
    },
    {
        "Hydranten Nr": "H11098",
        "UTM Koord. Rechts": "574226.016",
        "UTM Koord. Hoch": "5935244.377",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergredder",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H12954",
        "UTM Koord. Rechts": "571960.44",
        "UTM Koord. Hoch": "5930641.807",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "52"
    },
    {
        "Hydranten Nr": "H6417",
        "UTM Koord. Rechts": "573678.694",
        "UTM Koord. Hoch": "5935649.649",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Zwischen den Hecken",
        "HsNr.": "86"
    },
    {
        "Hydranten Nr": "H19485",
        "UTM Koord. Rechts": "571416.002",
        "UTM Koord. Hoch": "5932511.701",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H17059",
        "UTM Koord. Rechts": "572127.44",
        "UTM Koord. Hoch": "5934304.327",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bergstieg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H12802",
        "UTM Koord. Rechts": "572121.493",
        "UTM Koord. Hoch": "5931466.458",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H16934",
        "UTM Koord. Rechts": "572737.372",
        "UTM Koord. Hoch": "5933208.533",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kirchlinden",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H7989",
        "UTM Koord. Rechts": "573861.115",
        "UTM Koord. Hoch": "5935130.161",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Glasbergstraße",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H18032",
        "UTM Koord. Rechts": "571709.203",
        "UTM Koord. Hoch": "5934066.474",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bei der Martinskirche",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H10736",
        "UTM Koord. Rechts": "573583.523",
        "UTM Koord. Hoch": "5931341.558",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Unterer Landweg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H16983",
        "UTM Koord. Rechts": "572792.78",
        "UTM Koord. Hoch": "5933711.748",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Legienstraße",
        "HsNr.": "99"
    },
    {
        "Hydranten Nr": "H16879",
        "UTM Koord. Rechts": "572454.895",
        "UTM Koord. Hoch": "5934193.366",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "70"
    },
    {
        "Hydranten Nr": "H16838",
        "UTM Koord. Rechts": "572282.39",
        "UTM Koord. Hoch": "5934224.37",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hasencleverstraße",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H16865",
        "UTM Koord. Rechts": "572764.456",
        "UTM Koord. Hoch": "5934024.289",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "92"
    },
    {
        "Hydranten Nr": "H18148",
        "UTM Koord. Rechts": "571791.196",
        "UTM Koord. Hoch": "5934538.184",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Weg",
        "HsNr.": "282"
    },
    {
        "Hydranten Nr": "H20966",
        "UTM Koord. Rechts": "573677.785",
        "UTM Koord. Hoch": "5934418.761",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "139"
    },
    {
        "Hydranten Nr": "H18045",
        "UTM Koord. Rechts": "570970.235",
        "UTM Koord. Hoch": "5934376.786",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Beim Rauhen Hause",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H17882",
        "UTM Koord. Rechts": "571362.452",
        "UTM Koord. Hoch": "5934248.077",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Beim Rauhen Hause",
        "HsNr.": "43"
    },
    {
        "Hydranten Nr": "H19312",
        "UTM Koord. Rechts": "573363.476",
        "UTM Koord. Hoch": "5931096.694",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "KLGV.126 Unterer Landweg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H16914",
        "UTM Koord. Rechts": "572880.549",
        "UTM Koord. Hoch": "5933663.362",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinfeldtstraße",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H16425",
        "UTM Koord. Rechts": "573775.841",
        "UTM Koord. Hoch": "5934616.887",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "157"
    },
    {
        "Hydranten Nr": "H13835",
        "UTM Koord. Rechts": "572800.07",
        "UTM Koord. Hoch": "5933168.119",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kirchlinden",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H10989",
        "UTM Koord. Rechts": "575182.28",
        "UTM Koord. Hoch": "5933357.014",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Glitzaweg",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H12236",
        "UTM Koord. Rechts": "576445.621",
        "UTM Koord. Hoch": "5933251.478",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Glinder Straße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H7796",
        "UTM Koord. Rechts": "576503.388",
        "UTM Koord. Hoch": "5931681.321",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Große Holl",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H17141",
        "UTM Koord. Rechts": "572066.116",
        "UTM Koord. Hoch": "5933740.936",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Weddestraße",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H910",
        "UTM Koord. Rechts": "572790.871",
        "UTM Koord. Hoch": "5934878.109",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Heinrich-Kaufmann-Ring",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "H17990",
        "UTM Koord. Rechts": "574721.298",
        "UTM Koord. Hoch": "5931750.601",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "87"
    },
    {
        "Hydranten Nr": "H9015",
        "UTM Koord. Rechts": "575605.711",
        "UTM Koord. Hoch": "5932072.461",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "42"
    },
    {
        "Hydranten Nr": "H8932",
        "UTM Koord. Rechts": "575572.642",
        "UTM Koord. Hoch": "5931542.598",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Havighorster Redder",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H9549",
        "UTM Koord. Rechts": "575780.301",
        "UTM Koord. Hoch": "5932538.148",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Glinder Au",
        "HsNr.": "81"
    },
    {
        "Hydranten Nr": "H20498",
        "UTM Koord. Rechts": "576948.503",
        "UTM Koord. Hoch": "5931071.449",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Lohbrügge",
        "Straßenname": "Steinbeker Grenzdamm",
        "HsNr.": "68"
    },
    {
        "Hydranten Nr": "H10969",
        "UTM Koord. Rechts": "571637.623",
        "UTM Koord. Hoch": "5928622.531",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "259"
    },
    {
        "Hydranten Nr": "H46009",
        "UTM Koord. Rechts": "575233.7396",
        "UTM Koord. Hoch": "5932278.113",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Glinder Au",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H46017",
        "UTM Koord. Rechts": "575156.721",
        "UTM Koord. Hoch": "5932213.409",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Glinder Au",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H46019",
        "UTM Koord. Rechts": "575092.056",
        "UTM Koord. Hoch": "5932133.709",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Glinder Au",
        "HsNr.": "19"
    },
    {
        "Hydranten Nr": "H46021",
        "UTM Koord. Rechts": "575031.446",
        "UTM Koord. Hoch": "5932040.977",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Glinder Au",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H46027",
        "UTM Koord. Rechts": "571980.147",
        "UTM Koord. Hoch": "5929841.946",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Sandwisch",
        "HsNr.": "67"
    },
    {
        "Hydranten Nr": "H46031",
        "UTM Koord. Rechts": "571543.827",
        "UTM Koord. Hoch": "5928485.091",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Hauptdeich",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H46039",
        "UTM Koord. Rechts": "574001.631",
        "UTM Koord. Hoch": "5933560.651",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kaeriusweg",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H46041",
        "UTM Koord. Rechts": "574044.052",
        "UTM Koord. Hoch": "5932772.816",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "103"
    },
    {
        "Hydranten Nr": "H46171",
        "UTM Koord. Rechts": "571763.5897",
        "UTM Koord. Hoch": "5934466.178",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H46228",
        "UTM Koord. Rechts": "573147.752",
        "UTM Koord. Hoch": "5934175.915",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Wildentenstieg",
        "HsNr.": "61"
    },
    {
        "Hydranten Nr": "H46265",
        "UTM Koord. Rechts": "573544.803",
        "UTM Koord. Hoch": "5933531.041",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Julius-Campe-Weg",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H46279",
        "UTM Koord. Rechts": "575963.842",
        "UTM Koord. Hoch": "5932106.499",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Max-Pechstein-Straße",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H46281",
        "UTM Koord. Rechts": "571665.367",
        "UTM Koord. Hoch": "5933437.685",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Heckenpfad",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H46282",
        "UTM Koord. Rechts": "575695.992",
        "UTM Koord. Hoch": "5933033.669",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rantumer Weg",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H46295",
        "UTM Koord. Rechts": "576397.591",
        "UTM Koord. Hoch": "5931093.882",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Grenzdamm",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H46307",
        "UTM Koord. Rechts": "574342.493",
        "UTM Koord. Hoch": "5932683.926",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Letternkamp",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H46447",
        "UTM Koord. Rechts": "573472.628",
        "UTM Koord. Hoch": "5933074.627",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Weg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H46448",
        "UTM Koord. Rechts": "573412.596",
        "UTM Koord. Hoch": "5933007.069",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Öjendorfer Weg",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H46470",
        "UTM Koord. Rechts": "572157.768",
        "UTM Koord. Hoch": "5934028.501",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "38"
    },
    {
        "Hydranten Nr": "H46533",
        "UTM Koord. Rechts": "573572.327",
        "UTM Koord. Hoch": "5933584.425",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Masurenweg",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H46544",
        "UTM Koord. Rechts": "575095.6414",
        "UTM Koord. Hoch": "5932518.685",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H46545",
        "UTM Koord. Rechts": "575373.375",
        "UTM Koord. Hoch": "5933038.579",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinfurther Allee",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H46596",
        "UTM Koord. Rechts": "572622.697",
        "UTM Koord. Hoch": "5930968.608",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Porgesring",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H46740",
        "UTM Koord. Rechts": "571535.68",
        "UTM Koord. Hoch": "5934298.143",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Dunckersweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H46399",
        "UTM Koord. Rechts": "572567.379",
        "UTM Koord. Hoch": "5933775.208",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Vierbergen",
        "HsNr.": "37"
    },
    {
        "Hydranten Nr": "H46400",
        "UTM Koord. Rechts": "572669.879",
        "UTM Koord. Hoch": "5933692.802",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Riedweg",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H46768",
        "UTM Koord. Rechts": "575447.7642",
        "UTM Koord. Hoch": "5931401.382",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "141"
    },
    {
        "Hydranten Nr": "H46792",
        "UTM Koord. Rechts": "571682.66",
        "UTM Koord. Hoch": "5928401.391",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Brückenstraße",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H46151",
        "UTM Koord. Rechts": "572880.03",
        "UTM Koord. Hoch": "5934856.992",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "74"
    },
    {
        "Hydranten Nr": "H46912",
        "UTM Koord. Rechts": "572023.72",
        "UTM Koord. Hoch": "5933433.16",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Letzter Heller",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H46942",
        "UTM Koord. Rechts": "573963.888",
        "UTM Koord. Hoch": "5934113.151",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Behaimweg",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H47033",
        "UTM Koord. Rechts": "575511.44",
        "UTM Koord. Hoch": "5931343.44",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "147"
    },
    {
        "Hydranten Nr": "H47071",
        "UTM Koord. Rechts": "572140",
        "UTM Koord. Hoch": "5934100.28",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H47088",
        "UTM Koord. Rechts": "576314.723",
        "UTM Koord. Hoch": "5931800.201",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kleine Holl",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H47133",
        "UTM Koord. Rechts": "573451.058",
        "UTM Koord. Hoch": "5933362.127",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Masurenweg",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H46655",
        "UTM Koord. Rechts": "573354.608",
        "UTM Koord. Hoch": "5933445.767",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Annaberg",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H46654",
        "UTM Koord. Rechts": "573392.187",
        "UTM Koord. Hoch": "5933518.512",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Annaberg",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H47553",
        "UTM Koord. Rechts": "571105.35",
        "UTM Koord. Hoch": "5930183.67",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "100"
    },
    {
        "Hydranten Nr": "H47561",
        "UTM Koord. Rechts": "573480.89",
        "UTM Koord. Hoch": "5933284.91",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Gothaer Weg",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H47726",
        "UTM Koord. Rechts": "576435.9302",
        "UTM Koord. Hoch": "5931791.279",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Strietkoppel",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H47727",
        "UTM Koord. Rechts": "573647.51",
        "UTM Koord. Hoch": "5933476.49",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Julius-Campe-Weg",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H47738",
        "UTM Koord. Rechts": "574618.0731",
        "UTM Koord. Hoch": "5935246.48",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergredder",
        "HsNr.": "54"
    },
    {
        "Hydranten Nr": "H47739",
        "UTM Koord. Rechts": "574546.85",
        "UTM Koord. Hoch": "5935223.113",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergredder",
        "HsNr.": "46"
    },
    {
        "Hydranten Nr": "H47781",
        "UTM Koord. Rechts": "574740.049",
        "UTM Koord. Hoch": "5935290.189",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fuchsbergredder",
        "HsNr.": "70"
    },
    {
        "Hydranten Nr": "H47842",
        "UTM Koord. Rechts": "573617.002",
        "UTM Koord. Hoch": "5933344.583",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Ruhmkoppel",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H47277",
        "UTM Koord. Rechts": "571078.45",
        "UTM Koord. Hoch": "5932190.62",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Borsigstraße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H47278",
        "UTM Koord. Rechts": "571175.38",
        "UTM Koord. Hoch": "5932143.5",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Borsigstraße",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H47279",
        "UTM Koord. Rechts": "571283.91",
        "UTM Koord. Hoch": "5932093.21",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Borsigstraße",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H47280",
        "UTM Koord. Rechts": "571365.63",
        "UTM Koord. Hoch": "5932055.42",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Borsigstraße",
        "HsNr.": "11"
    },
    {
        "Hydranten Nr": "H47848",
        "UTM Koord. Rechts": "572450.48",
        "UTM Koord. Hoch": "5933579.51",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Washingtonallee",
        "HsNr.": "89"
    },
    {
        "Hydranten Nr": "H47863",
        "UTM Koord. Rechts": "573427.5572",
        "UTM Koord. Hoch": "5929726.224",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billwerder",
        "Straßenname": "Dweerlandweg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H47922",
        "UTM Koord. Rechts": "573100.61",
        "UTM Koord. Hoch": "5933203.72",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "32"
    },
    {
        "Hydranten Nr": "H47926",
        "UTM Koord. Rechts": "575825.86",
        "UTM Koord. Hoch": "5931572.37",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Max-Klinger-Straße",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H47927",
        "UTM Koord. Rechts": "576239.42",
        "UTM Koord. Hoch": "5931460.77",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kandinskyallee",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H48004",
        "UTM Koord. Rechts": "575992.48",
        "UTM Koord. Hoch": "5932008.897",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Max-Pechstein-Straße",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H46422",
        "UTM Koord. Rechts": "570862.59",
        "UTM Koord. Hoch": "5934305.6",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H46746",
        "UTM Koord. Rechts": "570960.68",
        "UTM Koord. Hoch": "5934262.85",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H46420",
        "UTM Koord. Rechts": "571034.87",
        "UTM Koord. Hoch": "5934230.08",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H46413",
        "UTM Koord. Rechts": "571122.95",
        "UTM Koord. Hoch": "5934187.15",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "46"
    },
    {
        "Hydranten Nr": "H47525",
        "UTM Koord. Rechts": "571198.55",
        "UTM Koord. Hoch": "5934145.18",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "110"
    },
    {
        "Hydranten Nr": "H46418",
        "UTM Koord. Rechts": "571281.82",
        "UTM Koord. Hoch": "5934096.18",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "128"
    },
    {
        "Hydranten Nr": "H46414",
        "UTM Koord. Rechts": "571123.55",
        "UTM Koord. Hoch": "5934092.51",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Culinstraße",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H46415",
        "UTM Koord. Rechts": "571196.62",
        "UTM Koord. Hoch": "5934036.79",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Culinstraße",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H46416",
        "UTM Koord. Rechts": "571287.72",
        "UTM Koord. Hoch": "5933974.3",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Culinstraße",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H46417",
        "UTM Koord. Rechts": "571360.58",
        "UTM Koord. Hoch": "5934053.32",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "140"
    },
    {
        "Hydranten Nr": "H46421",
        "UTM Koord. Rechts": "570933.05",
        "UTM Koord. Hoch": "5934299.58",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H46419",
        "UTM Koord. Rechts": "571045",
        "UTM Koord. Hoch": "5934249.17",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "69"
    },
    {
        "Hydranten Nr": "H46423",
        "UTM Koord. Rechts": "570775.9",
        "UTM Koord. Hoch": "5934342.3",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Landstraße",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H46489",
        "UTM Koord. Rechts": "575444.7407",
        "UTM Koord. Hoch": "5933366.234",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schlangenkoppel",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H46490",
        "UTM Koord. Rechts": "575505.7433",
        "UTM Koord. Hoch": "5933298.478",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schlangenkoppel",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H47857",
        "UTM Koord. Rechts": "576402.3757",
        "UTM Koord. Hoch": "5934589.218",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hegenredder",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H47889",
        "UTM Koord. Rechts": "576312.5747",
        "UTM Koord. Hoch": "5934352.495",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hegenredder",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H47858",
        "UTM Koord. Rechts": "576458.6771",
        "UTM Koord. Hoch": "5934744.566",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hegenredder",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H48117",
        "UTM Koord. Rechts": "571520.8941",
        "UTM Koord. Hoch": "5928568.82",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Kneidenweg",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H48136",
        "UTM Koord. Rechts": "571021.2286",
        "UTM Koord. Hoch": "5929577.954",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Holzhafenufer",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "",
        "UTM Koord. Rechts": "573313.2594",
        "UTM Koord. Hoch": "5933293.703",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Maukestieg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H48198",
        "UTM Koord. Rechts": "575445.32",
        "UTM Koord. Hoch": "5931375.39",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Hauptstraße",
        "HsNr.": "143"
    },
    {
        "Hydranten Nr": "H48199",
        "UTM Koord. Rechts": "575994.59",
        "UTM Koord. Hoch": "5931888.97",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Max-Pechstein-Straße",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H48200",
        "UTM Koord. Rechts": "574811.881",
        "UTM Koord. Hoch": "5935558.807",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hans-Rubbert-Straße",
        "HsNr.": "57"
    },
    {
        "Hydranten Nr": "H48201",
        "UTM Koord. Rechts": "572116.92",
        "UTM Koord. Hoch": "5933181.4",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kolumbusstraße",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H48202",
        "UTM Koord. Rechts": "575108.54",
        "UTM Koord. Hoch": "5932908.48",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "184"
    },
    {
        "Hydranten Nr": "H48203",
        "UTM Koord. Rechts": "575335.63",
        "UTM Koord. Hoch": "5932530.84",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Sonnenland",
        "HsNr.": "55"
    },
    {
        "Hydranten Nr": "H48204",
        "UTM Koord. Rechts": "572485.92",
        "UTM Koord. Hoch": "5931546.8",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Moorfleeter Straße",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H48205",
        "UTM Koord. Rechts": "572267.28",
        "UTM Koord. Hoch": "5931044.9",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Moorfleeter Straße",
        "HsNr.": "51"
    },
    {
        "Hydranten Nr": "H48207",
        "UTM Koord. Rechts": "574672.64",
        "UTM Koord. Hoch": "5932218.95",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Brockhausweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H47925",
        "UTM Koord. Rechts": "571596.929",
        "UTM Koord. Hoch": "5931988.299",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Borsigstraße",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H47923",
        "UTM Koord. Rechts": "571423.2985",
        "UTM Koord. Hoch": "5932024.006",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Borsigstraße",
        "HsNr.": "16"
    },
    {
        "Hydranten Nr": "H48262",
        "UTM Koord. Rechts": "572082.1674",
        "UTM Koord. Hoch": "5929868.878",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "51"
    },
    {
        "Hydranten Nr": "H48291",
        "UTM Koord. Rechts": "572762.0036",
        "UTM Koord. Hoch": "5934159.384",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kroogblöcke",
        "HsNr.": "67"
    },
    {
        "Hydranten Nr": "H48292",
        "UTM Koord. Rechts": "572691.316",
        "UTM Koord. Hoch": "5934249.834",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kroogblöcke",
        "HsNr.": "55"
    },
    {
        "Hydranten Nr": "H48293",
        "UTM Koord. Rechts": "572749.052",
        "UTM Koord. Hoch": "5934201.807",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kroogblöcke",
        "HsNr.": "62"
    },
    {
        "Hydranten Nr": "H48297",
        "UTM Koord. Rechts": "573134.4071",
        "UTM Koord. Hoch": "5933099.996",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hauskoppelstieg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H48309",
        "UTM Koord. Rechts": "574162.5635",
        "UTM Koord. Hoch": "5933292.449",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Archenholzstraße",
        "HsNr.": "51"
    },
    {
        "Hydranten Nr": "H48338",
        "UTM Koord. Rechts": "571560.5886",
        "UTM Koord. Hoch": "5934949.155",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rhiemsweg",
        "HsNr.": "89"
    },
    {
        "Hydranten Nr": "H48346",
        "UTM Koord. Rechts": "572676.447",
        "UTM Koord. Hoch": "5935148.033",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Grüningweg",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H48347",
        "UTM Koord. Rechts": "573051.8404",
        "UTM Koord. Hoch": "5933898.194",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kattensteert",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H48349",
        "UTM Koord. Rechts": "572258.7033",
        "UTM Koord. Hoch": "5934105.789",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Sandkampweg",
        "HsNr.": "15"
    },
    {
        "Hydranten Nr": "H48384",
        "UTM Koord. Rechts": "576847.3",
        "UTM Koord. Hoch": "5931064.904",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Lohbrügge",
        "Straßenname": "Steinbeker Grenzdamm",
        "HsNr.": "62"
    },
    {
        "Hydranten Nr": "H48392",
        "UTM Koord. Rechts": "571535.959",
        "UTM Koord. Hoch": "5931438.947",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H48393",
        "UTM Koord. Rechts": "572246.162",
        "UTM Koord. Hoch": "5930898.638",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H48394",
        "UTM Koord. Rechts": "572667.279",
        "UTM Koord. Hoch": "5930623.964",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Bredowstraße",
        "HsNr.": "33"
    },
    {
        "Hydranten Nr": "H48465",
        "UTM Koord. Rechts": "576290.669",
        "UTM Koord. Hoch": "5933177.025",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Oststeinbeker Weg",
        "HsNr.": "126"
    },
    {
        "Hydranten Nr": "H46405",
        "UTM Koord. Rechts": "571474.26",
        "UTM Koord. Hoch": "5934525.12",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Weg",
        "HsNr.": "228"
    },
    {
        "Hydranten Nr": "H46404",
        "UTM Koord. Rechts": "571381.72",
        "UTM Koord. Hoch": "5934526.37",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Weg",
        "HsNr.": "218"
    },
    {
        "Hydranten Nr": "H46403",
        "UTM Koord. Rechts": "571291.46",
        "UTM Koord. Hoch": "5934527.47",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Weg",
        "HsNr.": "208"
    },
    {
        "Hydranten Nr": "H46410",
        "UTM Koord. Rechts": "571560.92",
        "UTM Koord. Hoch": "5934525.5",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Weg",
        "HsNr.": "260"
    },
    {
        "Hydranten Nr": "H46409",
        "UTM Koord. Rechts": "571656.48",
        "UTM Koord. Hoch": "5934525.84",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Weg",
        "HsNr.": "270"
    },
    {
        "Hydranten Nr": "H46408",
        "UTM Koord. Rechts": "571638.0298",
        "UTM Koord. Hoch": "5934462.337",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Am Gojenboom",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H46407",
        "UTM Koord. Rechts": "571559.14",
        "UTM Koord. Hoch": "5934461.61",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Am Gojenboom",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H47559",
        "UTM Koord. Rechts": "571475.25",
        "UTM Koord. Hoch": "5934465.05",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "O'Swaldstraße",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H46406",
        "UTM Koord. Rechts": "571387.2086",
        "UTM Koord. Hoch": "5934429.441",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Theodor-Weber-Reihe",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H46412",
        "UTM Koord. Rechts": "571584.02",
        "UTM Koord. Hoch": "5934609.22",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bei den Zelten",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H46411",
        "UTM Koord. Rechts": "571503.49",
        "UTM Koord. Hoch": "5934603.27",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "O'Swaldstraße",
        "HsNr.": "35"
    },
    {
        "Hydranten Nr": "H48510",
        "UTM Koord. Rechts": "572539.0376",
        "UTM Koord. Hoch": "5934153.371",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "103"
    },
    {
        "Hydranten Nr": "H48546",
        "UTM Koord. Rechts": "574848.687",
        "UTM Koord. Hoch": "5932527.984",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Marktstraße",
        "HsNr.": "61"
    },
    {
        "Hydranten Nr": "H48574",
        "UTM Koord. Rechts": "574427.391",
        "UTM Koord. Hoch": "5932959.718",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Honsbergenstraße",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H48452",
        "UTM Koord. Rechts": "571630.325",
        "UTM Koord. Hoch": "5932005.823",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H48454",
        "UTM Koord. Rechts": "571662.156",
        "UTM Koord. Hoch": "5932012.179",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H48455",
        "UTM Koord. Rechts": "571672.8665",
        "UTM Koord. Hoch": "5932017.253",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H48881",
        "UTM Koord. Rechts": "574761.691",
        "UTM Koord. Hoch": "5933043.744",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Tabulatorweg",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H48918",
        "UTM Koord. Rechts": "572644.105",
        "UTM Koord. Hoch": "5934217.316",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Querkamp",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H48946",
        "UTM Koord. Rechts": "576218.2627",
        "UTM Koord. Hoch": "5932519.19",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "KLGV.148 Steinfurth",
        "HsNr.": "170"
    },
    {
        "Hydranten Nr": "H48954",
        "UTM Koord. Rechts": "575472.949",
        "UTM Koord. Hoch": "5933555.886",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schlangenkoppel",
        "HsNr.": "23"
    },
    {
        "Hydranten Nr": "H48959",
        "UTM Koord. Rechts": "575946.719",
        "UTM Koord. Hoch": "5931587.502",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Ittenstraße",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H48917",
        "UTM Koord. Rechts": "576209.201",
        "UTM Koord. Hoch": "5931974.302",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kandinskyallee",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H48695",
        "UTM Koord. Rechts": "573126.965",
        "UTM Koord. Hoch": "5933258.914",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "32"
    },
    {
        "Hydranten Nr": "H48984",
        "UTM Koord. Rechts": "572718.5235",
        "UTM Koord. Hoch": "5933551.663",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Legienstraße",
        "HsNr.": "65"
    },
    {
        "Hydranten Nr": "H49055",
        "UTM Koord. Rechts": "570880.175",
        "UTM Koord. Hoch": "5931318.97",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Andreas-Meyer-Straße",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H49109",
        "UTM Koord. Rechts": "571795.956",
        "UTM Koord. Hoch": "5933071.195",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Rampe",
        "HsNr.": "46"
    },
    {
        "Hydranten Nr": "H49136",
        "UTM Koord. Rechts": "571627.9473",
        "UTM Koord. Hoch": "5928392.136",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Schleusenweg",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H49147",
        "UTM Koord. Rechts": "571909.045",
        "UTM Koord. Hoch": "5931510.001",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H49165",
        "UTM Koord. Rechts": "574991.51",
        "UTM Koord. Hoch": "5933633.286",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Reinskamp",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "",
        "UTM Koord. Rechts": "573762.8087",
        "UTM Koord. Hoch": "5932558.252",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schleemer Weg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H49226",
        "UTM Koord. Rechts": "576481.0187",
        "UTM Koord. Hoch": "5932250.599",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Am Mühlenbach",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H49228",
        "UTM Koord. Rechts": "573392.6323",
        "UTM Koord. Hoch": "5934583.782",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Zwergfalkenweg",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H49229",
        "UTM Koord. Rechts": "573519.2962",
        "UTM Koord. Hoch": "5934733.961",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Prinzenweg",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H49230",
        "UTM Koord. Rechts": "575851.3699",
        "UTM Koord. Hoch": "5933303.666",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kaltenbergen",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H49231",
        "UTM Koord. Rechts": "573492.6535",
        "UTM Koord. Hoch": "5934682.077",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Goldelsestieg",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H49232",
        "UTM Koord. Rechts": "573458.4459",
        "UTM Koord. Hoch": "5934618.012",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Froschkönigweg",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H49233",
        "UTM Koord. Rechts": "573322.07",
        "UTM Koord. Hoch": "5934506.913",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Fischadlerstieg",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H49234",
        "UTM Koord. Rechts": "574937.9454",
        "UTM Koord. Hoch": "5931718.445",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "An der Steinbek",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H49277",
        "UTM Koord. Rechts": "573809.5931",
        "UTM Koord. Hoch": "5932939.679",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schleemer Weg",
        "HsNr.": "51"
    },
    {
        "Hydranten Nr": "H49312",
        "UTM Koord. Rechts": "574549.339",
        "UTM Koord. Hoch": "5932573.214",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Letternkamp",
        "HsNr.": "47"
    },
    {
        "Hydranten Nr": "H49363",
        "UTM Koord. Rechts": "573148.883",
        "UTM Koord. Hoch": "5934825.153",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Dahrendorfweg",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H49381",
        "UTM Koord. Rechts": "574938.065",
        "UTM Koord. Hoch": "5933586.552",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Reinskamp",
        "HsNr.": "13"
    },
    {
        "Hydranten Nr": "H49390",
        "UTM Koord. Rechts": "574984.5449",
        "UTM Koord. Hoch": "5933634.214",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Reinskamp",
        "HsNr.": "21"
    },
    {
        "Hydranten Nr": "H49517",
        "UTM Koord. Rechts": "571766.234",
        "UTM Koord. Hoch": "5934467.362",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "29"
    },
    {
        "Hydranten Nr": "H49471",
        "UTM Koord. Rechts": "572839.318",
        "UTM Koord. Hoch": "5932000.923",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "72"
    },
    {
        "Hydranten Nr": "H49625",
        "UTM Koord. Rechts": "574304.505",
        "UTM Koord. Hoch": "5934763.837",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H49626",
        "UTM Koord. Rechts": "574268.531",
        "UTM Koord. Hoch": "5934655.04",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Manshardtstraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H49470",
        "UTM Koord. Rechts": "572983.087",
        "UTM Koord. Hoch": "5931960.599",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "82"
    },
    {
        "Hydranten Nr": "H49469",
        "UTM Koord. Rechts": "573133.271",
        "UTM Koord. Hoch": "5931917.921",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "86"
    },
    {
        "Hydranten Nr": "H",
        "UTM Koord. Rechts": "573756.4619",
        "UTM Koord. Hoch": "5932172.288",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Niederschleems",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H49624",
        "UTM Koord. Rechts": "573816.6481",
        "UTM Koord. Hoch": "5933011.093",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schleemer Weg",
        "HsNr.": "59"
    },
    {
        "Hydranten Nr": "H49662",
        "UTM Koord. Rechts": "574999.6914",
        "UTM Koord. Hoch": "5935769.306",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Haferblöcken",
        "HsNr.": "50"
    },
    {
        "Hydranten Nr": "H49714",
        "UTM Koord. Rechts": "572765.5769",
        "UTM Koord. Hoch": "5933652.327",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Legienstraße",
        "HsNr.": "87"
    },
    {
        "Hydranten Nr": "H49717",
        "UTM Koord. Rechts": "572013.524",
        "UTM Koord. Hoch": "5932253.324",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "37"
    },
    {
        "Hydranten Nr": "H49709",
        "UTM Koord. Rechts": "575930.6",
        "UTM Koord. Hoch": "5932988.869",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Knivsbergweg",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H49664",
        "UTM Koord. Rechts": "576341.7793",
        "UTM Koord. Hoch": "5933328.598",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Grenzwinkel",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H49845",
        "UTM Koord. Rechts": "572301.703",
        "UTM Koord. Hoch": "5932158.277",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "52"
    },
    {
        "Hydranten Nr": "H49847",
        "UTM Koord. Rechts": "572493.877",
        "UTM Koord. Hoch": "5932104.803",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "60"
    },
    {
        "Hydranten Nr": "H49874",
        "UTM Koord. Rechts": "576416.662",
        "UTM Koord. Hoch": "5930819.146",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Asbrookdamm",
        "HsNr.": "25"
    },
    {
        "Hydranten Nr": "H49875",
        "UTM Koord. Rechts": "576508.314",
        "UTM Koord. Hoch": "5930756.24",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Asbrookdamm",
        "HsNr.": "24"
    },
    {
        "Hydranten Nr": "H49876",
        "UTM Koord. Rechts": "576281.2809",
        "UTM Koord. Hoch": "5930953.631",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Asbrookdamm",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H49877",
        "UTM Koord. Rechts": "574635.229",
        "UTM Koord. Hoch": "5932519.266",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Brockhausweg",
        "HsNr.": "66"
    },
    {
        "Hydranten Nr": "H49878",
        "UTM Koord. Rechts": "576311.8857",
        "UTM Koord. Hoch": "5931061.504",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Asbrookdamm",
        "HsNr.": "44"
    },
    {
        "Hydranten Nr": "H49879",
        "UTM Koord. Rechts": "576194.239",
        "UTM Koord. Hoch": "5931260.407",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kandinskyallee",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H49889",
        "UTM Koord. Rechts": "572859.868",
        "UTM Koord. Hoch": "5934876.401",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Heinrich-Kaufmann-Ring",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H49890",
        "UTM Koord. Rechts": "572679.709",
        "UTM Koord. Hoch": "5932052.99",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Liebigstraße",
        "HsNr.": "67"
    },
    {
        "Hydranten Nr": "H49903",
        "UTM Koord. Rechts": "573083.725",
        "UTM Koord. Hoch": "5933523.049",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinfeldtstraße",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H49965",
        "UTM Koord. Rechts": "572508.494",
        "UTM Koord. Hoch": "5935160.161",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Stoltenstraße",
        "HsNr.": "56"
    },
    {
        "Hydranten Nr": "H49981",
        "UTM Koord. Rechts": "574995.021",
        "UTM Koord. Hoch": "5931839.289",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mümmelmannsberg",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H49997",
        "UTM Koord. Rechts": "574307.454",
        "UTM Koord. Hoch": "5932126.681",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Mehrenskamp",
        "HsNr.": "17"
    },
    {
        "Hydranten Nr": "H50005",
        "UTM Koord. Rechts": "572162.238",
        "UTM Koord. Hoch": "5931440.688",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "10"
    },
    {
        "Hydranten Nr": "H50006",
        "UTM Koord. Rechts": "571640.849",
        "UTM Koord. Hoch": "5930912.276",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Halskestraße",
        "HsNr.": "40"
    },
    {
        "Hydranten Nr": "H50008",
        "UTM Koord. Rechts": "573007.5971",
        "UTM Koord. Hoch": "5932181.349",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Berzeliusstraße",
        "HsNr.": "72"
    },
    {
        "Hydranten Nr": "H50009",
        "UTM Koord. Rechts": "574882.193",
        "UTM Koord. Hoch": "5932349.023",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Steinbeker Marktstraße",
        "HsNr.": "44"
    },
    {
        "Hydranten Nr": "H50021",
        "UTM Koord. Rechts": "574712.3662",
        "UTM Koord. Hoch": "5932820.374",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Merkenstraße",
        "HsNr.": "1"
    },
    {
        "Hydranten Nr": "H49729",
        "UTM Koord. Rechts": "575110.6367",
        "UTM Koord. Hoch": "5932822.416",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Horst-Böttjer-Weg",
        "HsNr.": "39"
    },
    {
        "Hydranten Nr": "H50143",
        "UTM Koord. Rechts": "573744.662",
        "UTM Koord. Hoch": "5934082.24",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Meriandamm",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H49727",
        "UTM Koord. Rechts": "575154.924",
        "UTM Koord. Hoch": "5932855.614",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Horst-Böttjer-Weg",
        "HsNr.": "5"
    },
    {
        "Hydranten Nr": "H49733",
        "UTM Koord. Rechts": "575098.019",
        "UTM Koord. Hoch": "5932757.863",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Horst-Böttjer-Weg",
        "HsNr.": "30"
    },
    {
        "Hydranten Nr": "H49730",
        "UTM Koord. Rechts": "575184.816",
        "UTM Koord. Hoch": "5932798.602",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Horst-Böttjer-Weg",
        "HsNr.": "14"
    },
    {
        "Hydranten Nr": "H50165",
        "UTM Koord. Rechts": "571964.821",
        "UTM Koord. Hoch": "5931499.115",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Pinkertweg",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "H50177",
        "UTM Koord. Rechts": "572801.3284",
        "UTM Koord. Hoch": "5933289.213",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Hauskoppel",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H49732",
        "UTM Koord. Rechts": "575176.203",
        "UTM Koord. Hoch": "5932709.826",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Marianne-Timm-Weg",
        "HsNr.": "31"
    },
    {
        "Hydranten Nr": "H50227",
        "UTM Koord. Rechts": "575104.1729",
        "UTM Koord. Hoch": "5932682.152",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Landjägerstieg",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H50211",
        "UTM Koord. Rechts": "572938.108",
        "UTM Koord. Hoch": "5934752.169",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bergmannring",
        "HsNr.": "7"
    },
    {
        "Hydranten Nr": "H49731",
        "UTM Koord. Rechts": "575216.487",
        "UTM Koord. Hoch": "5932748.434",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Marianne-Timm-Weg",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H50231",
        "UTM Koord. Rechts": "571012.5459",
        "UTM Koord. Hoch": "5934545.889",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Horner Weg",
        "HsNr.": "170"
    },
    {
        "Hydranten Nr": "H50279",
        "UTM Koord. Rechts": "572797.504",
        "UTM Koord. Hoch": "5934028.399",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Hermannstal",
        "HsNr.": "121"
    },
    {
        "Hydranten Nr": "",
        "UTM Koord. Rechts": "574220.057",
        "UTM Koord. Hoch": "5933655.254",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Jenkelweg",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H49728",
        "UTM Koord. Rechts": "575044.7288",
        "UTM Koord. Hoch": "5932804.447",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Wittdüner Weg",
        "HsNr.": "9"
    },
    {
        "Hydranten Nr": "H50345",
        "UTM Koord. Rechts": "573259.815",
        "UTM Koord. Hoch": "5935096.846",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Graßmannweg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H50369",
        "UTM Koord. Rechts": "575731.6466",
        "UTM Koord. Hoch": "5931901.093",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kollwitzring",
        "HsNr.": "72"
    },
    {
        "Hydranten Nr": "H50371",
        "UTM Koord. Rechts": "573062.581",
        "UTM Koord. Hoch": "5933132.334",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schiffbeker Weg",
        "HsNr.": "22"
    },
    {
        "Hydranten Nr": "H50388",
        "UTM Koord. Rechts": "573282.3588",
        "UTM Koord. Hoch": "5935467.256",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Georg-Blume-Straße",
        "HsNr.": "56"
    },
    {
        "Hydranten Nr": "H50247",
        "UTM Koord. Rechts": "575850.541",
        "UTM Koord. Hoch": "5933367.526",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H50395",
        "UTM Koord. Rechts": "575296.5716",
        "UTM Koord. Hoch": "5933000.581",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Möllner Landstraße",
        "HsNr.": "206"
    },
    {
        "Hydranten Nr": "H50287",
        "UTM Koord. Rechts": "573184.254",
        "UTM Koord. Hoch": "5935617.938",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Zwischen den Hecken",
        "HsNr.": "28"
    },
    {
        "Hydranten Nr": "H50459",
        "UTM Koord. Rechts": "573767.751",
        "UTM Koord. Hoch": "5935277.982",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Rosenrotweg",
        "HsNr.": "37"
    },
    {
        "Hydranten Nr": "H50285",
        "UTM Koord. Rechts": "575063.996",
        "UTM Koord. Hoch": "5935338.964",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kalmusweg",
        "HsNr.": "18"
    },
    {
        "Hydranten Nr": "H50283",
        "UTM Koord. Rechts": "575132.341",
        "UTM Koord. Hoch": "5935465.282",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schachtelhalmweg",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H50095",
        "UTM Koord. Rechts": "574587.518",
        "UTM Koord. Hoch": "5932060.957",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Brockhausweg",
        "HsNr.": "4"
    },
    {
        "Hydranten Nr": "H50282",
        "UTM Koord. Rechts": "575197.041",
        "UTM Koord. Hoch": "5935465.161",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schachtelhalmweg",
        "HsNr.": "20"
    },
    {
        "Hydranten Nr": "H50288",
        "UTM Koord. Rechts": "573012.355",
        "UTM Koord. Hoch": "5935603.287",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Zwischen den Hecken",
        "HsNr.": "6"
    },
    {
        "Hydranten Nr": "H50525",
        "UTM Koord. Rechts": "571907.619",
        "UTM Koord. Hoch": "5933308.105",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kolumbusstraße",
        "HsNr.": "8"
    },
    {
        "Hydranten Nr": "",
        "UTM Koord. Rechts": "573813.858",
        "UTM Koord. Hoch": "5932985.121",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schleemer Weg",
        "HsNr.": "34"
    },
    {
        "Hydranten Nr": "H50630",
        "UTM Koord. Rechts": "572149.8819",
        "UTM Koord. Hoch": "5935185.247",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Bei den Tennisplätzen",
        "HsNr.": "65"
    },
    {
        "Hydranten Nr": "H50464",
        "UTM Koord. Rechts": "573149.548",
        "UTM Koord. Hoch": "5932347.93",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "210"
    },
    {
        "Hydranten Nr": "H50463",
        "UTM Koord. Rechts": "573150.291",
        "UTM Koord. Hoch": "5932347.716",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "210"
    },
    {
        "Hydranten Nr": "H50466",
        "UTM Koord. Rechts": "572829.479",
        "UTM Koord. Hoch": "5932462.971",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "165"
    },
    {
        "Hydranten Nr": "H50676",
        "UTM Koord. Rechts": "575435.342",
        "UTM Koord. Hoch": "5931538.909",
        "PLZ": "22115",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Godenwind",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H50703",
        "UTM Koord. Rechts": "571813.1483",
        "UTM Koord. Hoch": "5932830.8",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "55"
    },
    {
        "Hydranten Nr": "H50704",
        "UTM Koord. Rechts": "575706.272",
        "UTM Koord. Hoch": "5933252.872",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Kaltenbergen",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H50710",
        "UTM Koord. Rechts": "571405.7869",
        "UTM Koord. Hoch": "5931634.669",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Grusonstraße",
        "HsNr.": "53"
    },
    {
        "Hydranten Nr": "H50732",
        "UTM Koord. Rechts": "574757.976",
        "UTM Koord. Hoch": "5933520.128",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Reinskamp",
        "HsNr.": "3"
    },
    {
        "Hydranten Nr": "H50465",
        "UTM Koord. Rechts": "572967.19",
        "UTM Koord. Hoch": "5932416.532",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billbrook",
        "Straßenname": "Billbrookdeich",
        "HsNr.": "171"
    },
    {
        "Hydranten Nr": "H59472",
        "UTM Koord. Rechts": "575243.829",
        "UTM Koord. Hoch": "5935622.691",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Simsenweg",
        "HsNr.": "26"
    },
    {
        "Hydranten Nr": "Provisorium",
        "UTM Koord. Rechts": "575149.736",
        "UTM Koord. Hoch": "5935668.742",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Am Öjendorfer See",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H50281",
        "UTM Koord. Rechts": "575150.15",
        "UTM Koord. Hoch": "5935597.728",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Am Öjendorfer See",
        "HsNr.": "2"
    },
    {
        "Hydranten Nr": "H50289",
        "UTM Koord. Rechts": "572866.597",
        "UTM Koord. Hoch": "5935591.929",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Derbyweg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H50290",
        "UTM Koord. Rechts": "572655.806",
        "UTM Koord. Hoch": "5935544.901",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Derbyweg",
        "HsNr.": "0"
    },
    {
        "Hydranten Nr": "H50818",
        "UTM Koord. Rechts": "571804.2641",
        "UTM Koord. Hoch": "5934389.163",
        "PLZ": "22111",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Rennbahnstraße",
        "HsNr.": "27"
    },
    {
        "Hydranten Nr": "H50821",
        "UTM Koord. Rechts": "573251.0525",
        "UTM Koord. Hoch": "5934988.936",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Legienstraße",
        "HsNr.": "250"
    },
    {
        "Hydranten Nr": "H50869",
        "UTM Koord. Rechts": "572685.901",
        "UTM Koord. Hoch": "5934295.464",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Horn",
        "Straßenname": "Kroogblöcke",
        "HsNr.": "51"
    },
    {
        "Hydranten Nr": "H50888",
        "UTM Koord. Rechts": "572285.459",
        "UTM Koord. Hoch": "5928180.704",
        "PLZ": "22113",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Moorfleet",
        "Straßenname": "Moorfleeter Deich",
        "HsNr.": "333"
    },
    {
        "Hydranten Nr": "H50900",
        "UTM Koord. Rechts": "575296.24",
        "UTM Koord. Hoch": "5932940.015",
        "PLZ": "22117",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Schümannweg",
        "HsNr.": "12"
    },
    {
        "Hydranten Nr": "H50945",
        "UTM Koord. Rechts": "575139.646",
        "UTM Koord. Hoch": "5935371.649",
        "PLZ": "22119",
        "Ortsname": "Hamburg",
        "Name Ortsteil": "Billstedt",
        "Straßenname": "Pfeifengrasweg",
        "HsNr.": "18"
    }
]
    .map(h => ({
            'name': h['Hydranten Nr'],
            'position': utm.convertUtmToLatLng(h['UTM Koord. Rechts'], h['UTM Koord. Hoch'], 32, 'U'),
            'adresse': h['Straßenname'] + ' ' + h['HsNr.'],
    }))
    .sort((a, b) => new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'}).compare(a.adresse, b.adresse))
    .filter(h => !!h.name)