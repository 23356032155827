<script>
export default {
  name: "HLocator",
  data: () => ({
    loading: false,
  }),
  methods: {
    locate() {
      this.loading = true;

      navigator.geolocation.getCurrentPosition(pos => {
            this.loading = false;
            this.$emit('input', {lat: pos.coords.latitude, lng: pos.coords.longitude});
          },
          () => this.loading);
    },
  },
}
</script>

<template>
  <v-btn
      fab
      fixed
      bottom
      right
      color="primary"
      @click="locate"
      :loading="loading"
  >
    <v-icon>
      mdi-crosshairs-gps
    </v-icon>
  </v-btn>
</template>

<style scoped>

</style>